import React from "react";
import { Skeleton } from "antd";
import "./messages.css";
const MessageSkeleton = ({ mine }) => {
  return (
    <>
      <Skeleton
        style={{ marginLeft: "4%", marginBottom: "2%", width: "60%" }}
        className={`skeleton-message${mine}`}
        avatar
        active
        paragraph={{ rows: 1 }}
      />
    </>
  );
};

const MessagesSkeleton = () => {
  return (
    <>
      {Array.from({ length: 6 }).map((_, index) => (
        <MessageSkeleton key={index} mine={index % 2 ? "-mine" : ""} />
      ))}
    </>
  );
};

export default MessagesSkeleton;
