import React from "react";
import { useSelector } from "react-redux";
import EYE_OFF_ICON_SVG from "assets/icons/svg/eye-off-outline.svg";
import EYE_ICON_SVG from "assets/icons/svg/eye-orange.svg";
import TRASH_ICON_SVG from "assets/icons/svg/trash-orange.svg";

const OptionsHeader = ({ objectId, entryIsVisble, callbackHiddenEntry }) => {
  const userType = useSelector((state) => state.auth.userType);
  const deleteEntry = () => {};

  return (
    userType === "sp" && (
      <div className="header-options">
        <div className="container-header-icons-sp">
          <div>
            <img
              alt=""
              src={TRASH_ICON_SVG}
              className="delete-icon-entry-sp"
              onClick={() => deleteEntry(objectId)}
            />
          </div>
          <div>
            <img
              src={entryIsVisble ? EYE_ICON_SVG : EYE_OFF_ICON_SVG}
              alt=""
              className="delete-icon-entry-sp"
              onClick={callbackHiddenEntry}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default OptionsHeader;
