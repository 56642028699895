import { Appbar } from "../../../components/atoms/appbar/Appbar";
import { CompleteCard } from "../../../components/atoms/card/Card";
import ButtonNavbar from "../../../components/molecules/buttonNavbar/ButtonNavbar";
import UserMenuDesktop from "../../../components/molecules/menu/UserMenu";
import { HOME } from "../../../router/routes";
import "./home.css";

const Home = ({
  callbackSelectedService,
  callbackNewService,
  showButton,
  listServices,
  isSelectedItem,
}) => {
  return (
    <div className="usertype-main-container appbar-no-background">
      <UserMenuDesktop
        showCotizationMenu={true}
        page={HOME}
        showBtn={showButton}
        callback={callbackNewService}
      />

      <div className="home-container">
        <Appbar smallText="Lista de servicios" bigText="Home" />

        <div className="options-menu-container">
          {listServices.map((service) => (
            <div key={service.id} className="options-items-container">
              <CompleteCard
                isSelected={() => isSelectedItem(service.id)}
                icon={service.icon}
                id={service.id}
                callback={callbackSelectedService}
                title={service.title}
                text={service.text}
              />
            </div>
          ))}
        </div>

        <div className="menu-navbar-container">
          <ButtonNavbar
            showCotizationMenu={true}
            page={HOME}
            showBtn={showButton}
            callback={callbackNewService}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
