import { isEqual } from "lodash";
import { memo, useMemo } from "react";
import { convertPhaseKey } from "res/convert.codes";
import { orderEntryForPhases } from "views/services-provider/ps-new-entry/phases";
import PhaseTimeLine from "./PhaseTimeLine";
function PhasesContainer({
  list,
  callback,
  navigateUnexpected,
  user,
  serviceStage,
}) {
  const orderList = useMemo(() => orderEntryForPhases(list), [list]);

  function findNewMessageEntries(orderList, messageType) {
    return Array.from(
      new Set(
        orderList.flatMap((data) =>
          data.entries
            .filter(
              (entry) =>
                entry.statusMessages &&
                entry.statusMessages[messageType] === true
            )
            .map((entry) => {
              return entry.phase.code
                ? convertPhaseKey(entry.phase.code)
                : null;
            })
            .filter((code) => code !== null || code !== undefined)
        )
      )
    );
  }

  const spNewMessageEntries = findNewMessageEntries(orderList, "newSpMessage");
  const clientNewMessageEntries = findNewMessageEntries(
    orderList,
    "newClientMessage"
  );

  return (
    orderList.length > 0 &&
    orderList.map((phase, index) => {
      return (
        <PhaseTimeLine
          index={index}
          entry={phase}
          callback={callback}
          user={user}
          newMessage={
            user === "sp" ? spNewMessageEntries : clientNewMessageEntries
          }
          navigateUnexpected={navigateUnexpected}
          serviceStage={serviceStage}
        />
      );
    })
  );
}

export default memo(PhasesContainer, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
