import React from 'react'
import './psRegister.css'
import { RegisterContactTemplate } from '../../../components/templates/registerTemplate/RegisterTemplate'
import { IconInput } from '../../../components/atoms/input/Input'
import { IconButton } from '../../../components/atoms/button/Button'
import PHONE_ICON_SVG from '../../../assets/icons/svg/phone.svg'
import USER_ICON_SVG from '../../../assets/icons/svg/user.svg'
import USERS_ICON_SVG from '../../../assets/icons/svg/users.svg'
import LocationForm from '../../../components/molecules/locationForm/LocationForm'
import { IconDropdown } from '../../../components/atoms/dropdown/Dropdown'
import { RELATIONSHIP_TYPE } from '../../../res/relationship.types'
import {AppbarLogo} from  '../../../components/atoms/appbar/Appbar'

const ContactInfoServiceProvider = ({
    type,
    callbackSelectedDepartment,
    defaultOptionDepartment,
    callbackSelectedCity,
    defaultOptionCity,
    callbackSelectedAddress,
    defaultOptionAddress,
    callbackAddress,
    callbackCornerAddress,
    callbackNumberAddress,
    defaultValueAddress,
    defaultValueCornerAddress,
    defaultValueNumberAddress,
    callbackChangePhoneAuxiliary,
    defaultValuePhoneAuxiliary,
    callbackChangeNameContactAuxiliary,
    defaultValueNameContactAuxiliary,
    callbackChangePhoneContactAuxiliary,
    defaultValuePhoneContactAuxiliary,
    callbackChangeRelationship,
    defaultValueRelationship,
    callbackPushNavigator,
    isAvailableCallbackNex,
}) => {
    return (
        <div className='register-info-container'>
            <AppbarLogo />
            <RegisterContactTemplate
                type={type}
                bigText='Información de contacto'
                fields={
                    <div className='order-form contact-container'>

                        <div className='options-fields' >

                            <LocationForm
                                callbackSelectedDepartment={callbackSelectedDepartment}
                                defaultOptionDepartment={defaultOptionDepartment}
                                callbackSelectedCity={callbackSelectedCity}
                                defaultOptionCity={defaultOptionCity}
                                callbackSelectedAddress={callbackSelectedAddress}
                                defaultOptionAddress={defaultOptionAddress}
                                callbackAddress={callbackAddress}
                                callbackCornerAddress={callbackCornerAddress}
                                callbackNumberAddress={callbackNumberAddress}
                                defaultValueAddress={defaultValueAddress}
                                defaultValueCornerAddress={defaultValueCornerAddress}
                                defaultValueNumberAddress={defaultValueNumberAddress}
                            />


                            <div className='fields-container'>
                                <IconInput
                                    type='number'
                                    defaultValue={defaultValuePhoneAuxiliary}
                                    callback={callbackChangePhoneAuxiliary}
                                    icon={PHONE_ICON_SVG}
                                    placeholder='Número de la residencia' />
                            </div>

                            <div className='fields-container fc-text'>
                                Contacto de emergencia
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueNameContactAuxiliary}
                                    callback={callbackChangeNameContactAuxiliary}
                                    icon={USER_ICON_SVG}
                                    placeholder='Nombre y apellidos' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValuePhoneContactAuxiliary}
                                    callback={callbackChangePhoneContactAuxiliary}
                                    type='number'
                                    icon={PHONE_ICON_SVG}
                                    placeholder='Número de teléfono' />
                            </div>

                            <div className='fields-container'>
                                <IconDropdown
                                    callback={callbackChangeRelationship}
                                    icon={USERS_ICON_SVG}
                                    defaultSelected={defaultValueRelationship}
                                    items={
                                        RELATIONSHIP_TYPE.map((state, index) => (
                                            <option
                                                value={state}
                                                key={index}
                                                className='rol-option'>{state}
                                            </option>)
                                        )
                                    } />
                            </div>

                            {
                                isAvailableCallbackNex ?
                                    <div className='fields-container register-button'>
                                        <IconButton
                                            text='Registrarse'
                                            callback={callbackPushNavigator} />
                                    </div> :
                                    null
                            }

                        </div>

                    </div>
                } />

        </div>
    )
}

export default ContactInfoServiceProvider
