const MARITAL_STATUS_DEFAULT_VALUE = "Estado civil"

const MARITAL_STATUS = [
    'Soltero/a',
    'Comprometido/a',
    'En Relación',
    'Casado/a',
    'Unión libre',
    'Separado/a',
    'Divorciado/a',
    'Viudo/a',
    'Noviazgo'
]

export {
    MARITAL_STATUS,
    MARITAL_STATUS_DEFAULT_VALUE
}