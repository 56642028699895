const NEW_MESSAGE_ACTION = "set_new_message_action_message";
const UPDATE_MESSAGE = "set_update_message_action_message";
const EDITING_MESSAGE_ACTION = "set_editing_message_action_message";
const CLEAR_MESSAGE_ACTION = "set_clear_message_action_message";

const initialStateMessages = {
  messageList: [],
};

const reducerMessage = (state = initialStateMessages, action) => {

  const { type, payload } = action

  switch (type) {

    case UPDATE_MESSAGE:
      return {
        ...state,
        messageList: payload
      }

    case NEW_MESSAGE_ACTION:
      return {
        ...state,
        messageList: [...state.messageList, payload]
      }
    case EDITING_MESSAGE_ACTION:
      let newState = {
        ...state
      }
      newState.messageList[payload.position] = payload
      return newState

    case CLEAR_MESSAGE_ACTION:
      return {
        ...state,
        messageList: []
      }


    default:
      return state

  }

}

const updateMessages = (value) => {
  return { type: UPDATE_MESSAGE, payload: value }
}


const newMessage = (value) => {
  return { type: NEW_MESSAGE_ACTION, payload: value };
};

const editingMessage = (value) => {
  return { type: EDITING_MESSAGE_ACTION, payload: value };
};

const clearMessages = () => {
  return { type: CLEAR_MESSAGE_ACTION };
};

const actions = {
  updateMessages,
  newMessage,
  editingMessage,
  clearMessages,
}

export {
  initialStateMessages,
  reducerMessage,
  updateMessages,
  newMessage,
  editingMessage,
  clearMessages,
  actions
};
