import { Button } from "antd";
import { ReactComponent as ArrowBackIcon } from "assets/icons/svg/arrow-back.svg";
import { useHistory } from "react-router-dom";
import "./GoBackBtn.css";

export default function GoBackBtn({ absolute, type, onClick, className, ...props }) {
  const history = useHistory();

  const customOnClick = onClick
    ? onClick
    : () => {
      history.goBack();
    }

  return (
    <Button
      style={
        absolute
          ? { position: "absolute", top: "1rem", left: "1rem", zIndex: 4 }
          : {}
      }
      className="go-back-btn"
      type={type ? type : "primary"}
      shape="circle"
      {...props}
      {...{ onClick: customOnClick }}
      {...{ className: `go-back-btn${className ? ` ${className}` : ""}` }}
    >
      <ArrowBackIcon />
    </Button>
  );
}
