const MAP_COLOMBIA = [
    {
        department_name: 'Departamento',
        department_code: 'Departamento',
        cities: [
            {
                city_name: 'Ciudad',
                city_code: 'Ciudad'
            }
        ]
    },
    {
        department_name: 'Valle',
        department_code: '76',
        cities: [
            {
                city_name: 'Cali',
                city_code: '001'
            },
            {
                city_name: 'Jamundi',
                city_code: '364'
            },
            {
                city_name: 'Palmira',
                city_code: '520'
            }
        ]
    },
    {
        department_name: 'Cundinamarca',
        department_code: '11',
        cities: [
            {
                city_name: 'Bogotá',
                city_code: '001'
            }
        ]
    },
    {
        department_name: 'Antioquia',
        department_code: '05',
        cities: [
            {
                city_name: 'Medellín',
                city_code: '001'
            }
        ]
    },
    {
        department_name: 'Atlantico',
        department_code: '08',
        cities: [
            {
                city_name: 'Barranquilla',
                city_code: '001'
            }
        ]
    },
    {
        department_name: 'Bolivar',
        department_code: '13',
        cities: [
            {
                city_name: 'Cartagena',
                city_code: '001'
            }
        ]
    },
    {
        department_name: 'Santander',
        department_code: '68',
        cities: [
            {
                city_name: 'Bucaramanga',
                city_code: '001'
            }
        ]
    }
]

// CALI         76001
// JAMUNDI      76364
// PALIMIRA     76520   
// BOGOTA       11001
// MEDELLIB     05001
// BARRANQUILLA 08001
// CARTAGENA    13001
// BUCARAMANDA  68001  

export default MAP_COLOMBIA