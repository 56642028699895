import React from "react";
import { camelCase } from "../../../../res/utils";
const JUSTO_PROFILE =
  "https://firebasestorage.googleapis.com/v0/b/justo-pago.appspot.com/o/resources%2F77e1ff0f-cec0-56a1-9eb8-490964c2f9f3.png?alt=media&token=4d814f3a-7dfb-43fa-a9cc-82bf10d34d07";

const PersonInfo = ({ user }) => {
  const userData = user?.register_data;

  const profileImage = userData?.thumbnail || JUSTO_PROFILE;

  const name = userData?.name || "Justo Pago";

  return (
    <div className="person-info">
      <img src={profileImage} alt="person-profile" className="person-image" />
      <span className="person-name">{`${camelCase(name)}`}</span>
    </div>
  );
};

export default PersonInfo;
