import React from 'react'
import {PopupRejectCotizacion} from '../components/atoms/popup/Popup'

const RejectCotizacion = ({object}) =>{
    const {
        callbackCancel, 
        callbackSend, 
        reasonsList, 
        callbackTextarea, 
        callbackSelectReason
    } = object
    return(
        <PopupRejectCotizacion
        callbackCancel={callbackCancel}
        callbackSend={callbackSend}
        reasonsList={reasonsList}
        callbackTextarea={callbackTextarea}
        callbackSelectReason={callbackSelectReason}
        />
    )
}

export {RejectCotizacion}