const NO_REGISTERED_PROPERTY = 'Usted no posee al menos una propiedad registrada'
const PROPERTY_ALREADY_EXIST = 'La propiedad ya existe, por favor verifique la dirección'
const MANAGEMENT_ALREADY_EXISTS = 'Este correo ya está asociado con otra cuenta'
const PROBLEMS_SENDING_MAILCHIMP = 'Ocurrio un problema al enviar el correo, vuelva a  intentarlo desde la seccion de encargados'
const REQUIRED_ALL_PARAMS = (str) => `Es necesario ingresar los campos de ${str}`
const REGISTER_PROPERTY_REQUIRED_PARAMS = 'Nombre del inmueble, dirección y teléfono'
const REGISTER_MANAGEMENT_REQUIRED_PARAMS = 'Nombre, cargo, correo electrónico y mínimo un número de teléfono.'


const REGISTER = 'Registrar'
const CHECK = 'Entiendo'
const EXIT = 'Salir'



export {
    NO_REGISTERED_PROPERTY,
    PROPERTY_ALREADY_EXIST,
    MANAGEMENT_ALREADY_EXISTS,
    REGISTER,
    CHECK,
    EXIT,
    PROBLEMS_SENDING_MAILCHIMP,
    REQUIRED_ALL_PARAMS,
    REGISTER_PROPERTY_REQUIRED_PARAMS,
    REGISTER_MANAGEMENT_REQUIRED_PARAMS
}