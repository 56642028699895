import React from "react";
import ImageProfile from "./ImageProfile";
import HeaderMessage from "./HeaderMessage";
import MessageInfoContainer from "./MessageInfo/MessageInfoContainer";
import StatusMessage from "./StatusMessage";

const Message = ({
  key,
  isMine,
  message,
  visible,
  showDetail,
  setShowDetail,
}) => {
  const name = message?.emisor?.register_data?.name || message?.name;
  const status = {
    admin: message.admin_read,
    client: message.client_read,
    sp: message.sp_read,
  };
  return (
    <div
      key={key}
      className={`comment-container${isMine} ${visible ? "" : "hide-comment"}`}
    >
      <ImageProfile isMine={isMine} thubmnail={message.thubmnail} />

      <div className="comment-info-container">
        <HeaderMessage isMine={isMine} name={name} />
        <MessageInfoContainer
          message={message}
          showDetail={showDetail}
          isMine={isMine}
        />
        <StatusMessage
          message={message}
          date={message.date}
          showDetail={showDetail}
          setShowDetail={setShowDetail}
          isMine={isMine}
          status={status}
        />
      </div>
    </div>
  );
};

export default Message;
