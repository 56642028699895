const ACTIONS = {
  CHANGE_ACTIVITY_STATE: "CHANGE_ACTIVITY_STATE",
  CHANGE_GENERAL_STATE: "CHANGE_GENERAL_STATE",
  SET_SERVICE_OPERATION: "SET_SERVICE_OPERATION",
  SET_SERVICE_DATA: "SET_SERVICE_DATA",
  SET_SERVICE_BUDGET: "SET_SERVICE_DUDGET",
  SET_SELECTED_ACTIVITY: "SET_SELECTED_ACTIVITY",
  CHANGE_NEW_ADVANCE_FORM_OPEN: "CHANGE_NEW_ADVANCE_FORM_OPEN",
}

const initialStateActivity = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  serviceOperation: {},
  serviceData: {},
  serviceBudget: {},
  selectedActivityForm: {},
  newAdvanceFormIsOpen: false,
};

export const changeGeneralState = (status) => {
  return { type: ACTIONS.CHANGE_GENERAL_STATE, payload: status };
};

export const setServiceOperation = (data) => {
  return { type: ACTIONS.SET_SERVICE_OPERATION, payload: data };
};

export const setServiceData = (data) => {
  return { type: ACTIONS.SET_SERVICE_DATA, payload: data };
};

export const setServiceBudget = (data) => {
  return { type: ACTIONS.SET_SERVICE_BUDGET, payload: data };
};

export const setSelectedActivity = (data) => {
  return { type: ACTIONS.SET_SELECTED_ACTIVITY, payload: data };
};

export const changeNewAdvanceFormOpen = (status) => {
  return { type: ACTIONS.CHANGE_NEW_ADVANCE_FORM_OPEN, payload: status };
};

export default function newAdvanceDetail(state = initialStateActivity, action) {
  const { type, payload } = action

  switch (type) {
    case ACTIONS.CHANGE_GENERAL_STATE:
      return { ...state, ...payload }

    case ACTIONS.SET_SERVICE_OPERATION:
      return { ...state, serviceOperation: payload }

    case ACTIONS.SET_SERVICE_DATA:
      return { ...state, serviceData: payload }

    case ACTIONS.SET_SERVICE_BUDGET:
      return { ...state, serviceBudget: payload }

    case ACTIONS.SET_SELECTED_ACTIVITY:
      return { ...state, selectedActivityForm: payload }

    case ACTIONS.CHANGE_NEW_ADVANCE_FORM_OPEN:
      return { ...state, newAdvanceFormIsOpen: payload }

    default:
      return state;
  }
};
