import {v4 as uuidv4} from 'uuid'
const DAMAGE_TYPE_ACTION = 'set_damage_type_action_quote_item'
const DESCRIPTION_ACTION = 'set_description_action_quote_item'
const TASKS_ACTION = 'set_tasks_action_quote_item'
const DAMAGE_LOCATION_ACTION = 'set_damage_location_action_quote_item'
const FLOOR_LEVELS_ACTION = 'set_floor_levels_action_quote_item'
const AREA_ACTION = 'set_area_action_quote_item'
const MEASURE_UNIT_ACTION = 'set_measure_unit_action_quote_item'
const MATERIALS_ACTION = 'set_materials_action_quote_item'
const WORKFORCE_ACTION = 'set_workforce_action_quote_item'
const DURATION_ACTION = 'set_duration_action_quote_item'
const UNFORESEEN_ACTION = 'set_unforeseen_action_quote_item'
const WORKERS_HELPERS_ACTION = 'set_workers_helpers_action_quote_item'
const WORKERS_OFICIALS_ACTION = 'set_workers_oficial_action_quote_item'
const RESOURCES_ACTION = 'set_resources_action_quote_item'
const OBSERVATION_ACTION = 'set_observation_action_quote_item'
const UPDATE_ITEM_ACTION = 'set_update_item_action_quote_item'
const ADD_QUITE_ITEM = 'set_update_quite_item'



const initialMaterialObject = {
    material: '',
    quantity: 0,
    quantity_material:0,
    measure_unit: '',
    price_unit: 0,
}

const initialTaskObject = {
    title: '',
    is_done: true,
}

const initialResource = {
    type: '',
    name: '',
    url: ''
}

const initialStateQuoteItem = {
    quote_items: [],
}

const reducerRequestQuoteItem = (state = initialStateQuoteItem, action) => {

    const { type, payload } = action

    switch (type) {
        case ADD_QUITE_ITEM:
            return {
                ...state,
                quote_items: payload
            }

        case DAMAGE_TYPE_ACTION:
            return {
                ...state,
                damage_type: payload
            }
        case DESCRIPTION_ACTION:
            return {
                ...state,
                description: payload
            }
        case TASKS_ACTION:
            return {
                ...state,
                tasks: payload
            }
        case DAMAGE_LOCATION_ACTION:
            return {
                ...state,
                damage_locations: payload
            }
        case FLOOR_LEVELS_ACTION:
            return {
                ...state,
                floor_levels: payload
            }
        case AREA_ACTION:
            return {
                ...state,
                area: payload
            }
        case MEASURE_UNIT_ACTION:
            return {
                ...state,
                measure_unit: payload
            }
        case MATERIALS_ACTION:
            return {
                ...state,
                materials: payload
            }
        case WORKFORCE_ACTION:
            return {
                ...state,
                workforce: payload
            }
        case DURATION_ACTION:
            return {
                ...state,
                duration: payload
            }
        case UNFORESEEN_ACTION:
            return {
                ...state,
                unforeseen: payload
            }
        case WORKERS_HELPERS_ACTION:
            return {
                ...state,
                workers_helper: payload
            }
        case WORKERS_OFICIALS_ACTION:
            return {
                ...state,
                workers_oficial: payload
            }
        case RESOURCES_ACTION:
            return {
                ...state,
                resources: payload
            }
        case OBSERVATION_ACTION:
            return {
                ...state,
                observation: payload
            }
        case UPDATE_ITEM_ACTION:
            return payload
        default:
            return state
    }
}


const changeDamageType = (state, value) => {
    return { 
        ...state,
        damage_type: value
     }
}

const changeDescription = (state, value) => {
    return {
        ...state,
        description: value
    }
}

const changeTasks = (state, value) => {
    return {
        ...state,
        tasks: value
    }
}

const changeDamageLocation = (state, value) => {
    return {
        ...state,
        damage_locations: value
    }
}

const changeFloorLevels = (state, value) => {
    return {
        ...state,
        floor_levels: value
    }
}

const changeArea = (state, value) => {
    return {
        ...state,
        area: value
    }
}

const changeMeasureUnit = (state, value) => {
    return {
        ...state,
        measure_unit: value
    }
}

const changeMaterials = (state, value) => {
    return {
        ...state,
        materials: value
    }
}

const changeWorkforce = (state, value) => {
    return {
        ...state,
        workforce: value
    }
}

const changeDuration = (state, value) => {
    return { 
        ...state,
        duration: value
        }
}

const changeUnforeseen = (state, value) => {
    return {
        ...state,
        unforeseen: value
    }
}

const changeWorkersHelpers = (state, value) => {
    return {
        ...state,
        workers_helper: value
    }
}

const changeWorkersOfficials = (state, value) => {
    return {
        ...state,
        workers_oficial: value
    }
}

const changeResources = (state, value) => {
    return {
        ...state,
        resources: value
    }
}

const changeObservation = (state, value) => {
    return {
        ...state,
        observation: value
    }
}
const changeId = (state, value) => {
    return {
        ...state,
        id: value
    }
}

    const changeUpdateItem = (state, value) => {
        return {
        ...value
    }
}

const quote_item_initial = {
    id: '',
    damage_type: 'Tipo de daño',
    description: '',
    tasks: [],
    damage_locations: 'Selecciona la ubicación del daño',
    floor_levels: 'Piso o nivel de ubicación',
    area: '',
    measure_unit: 'Medida',
    materials: [],
    materials_percent: 0,
    workforce: '',
    workforce_percent: 0,
    duration: '',
    unforeseen: '',
    workers_helper: '',
    workers_oficial: '',
    resources: [],
    observation: '',
    is_approved: true,
}

const addQuoteItem = (quote_item = quote_item_initial) => {
    return {
        type: ADD_QUITE_ITEM,
        payload: quote_item
    }
}

export {
    reducerRequestQuoteItem,
    initialStateQuoteItem,
    initialMaterialObject,
    quote_item_initial,
    initialTaskObject,
    initialResource,
    addQuoteItem,
    changeId,
    changeDamageType,
    changeDescription,
    changeTasks,
    changeDamageLocation,
    changeFloorLevels,
    changeArea,
    changeMeasureUnit,
    changeMaterials,
    changeWorkforce,
    changeDuration,
    changeUnforeseen,
    changeWorkersHelpers,
    changeWorkersOfficials,
    changeResources,
    changeObservation,
    changeUpdateItem
}