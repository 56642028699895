const ADD_SPACES = 'ADD_SPACES';
const ADD_ADITIONALS = 'ADD_ADITIONALS';
const ADD_TOTAL_SIZE_SPACES = 'ADD_TOTAL_SIZE_SPACES';


const initialState = {
  spaces: [],
  totalSizeSpaces: 0,
  aditionals: [],
};

const propertyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_SPACES:
      return {
        ...state,
        spaces: payload
      };
    case ADD_ADITIONALS:
      return {
        ...state,
        aditionals: payload
      };
    case ADD_TOTAL_SIZE_SPACES:
      return {
        ...state,
        totalSizeSpaces: payload
      };
    default:
      return state;

  }
}


const addSpaces = (spaces) => {
  return { type: ADD_SPACES, payload: spaces };
}

const addAditionals = (aditionals) => {
  return { type: ADD_ADITIONALS, payload: aditionals };
}

const addTotalSizeSpaces = (totalSizeSpaces) => {
  return { type: ADD_TOTAL_SIZE_SPACES, payload: totalSizeSpaces };
}


export {
  initialState,
  propertyReducer,
  addSpaces,
  addAditionals,
  addTotalSizeSpaces
};


