const TITLE_ACTION = 'set_title_action_form_entry'
const DATE_ACTION = 'set_date_action_form_entry'
const DESCRIPTION_ACTION = 'set_description_form_entry'
const IDENTIFIER_ACTION = 'set_identifier_form_entry'
const APPROVAL_ACTION = 'set_approval_form_entry'
const STEPS_ACTION = 'set_steps_form_entry'
const TIME_START_ACTION = 'set_time_start_form_entry'
const TIME_END_ACTION = 'set_time_end_form_entry'
const RESOURCE_LIST_ACTION = 'set_resource_list_form_entry'
const UNEXPECTED_ACTION = 'set_unexpected_from_entry'
const UNEXPECTED_MESSAGE_ACTION = 'set_unexpected_message_from_entry'
const FINISHED_ITEM_ACTION = 'set_finished_item_form_entry'
const RESET_ACTION = 'set_reducer_to_initial_state_form_entry'
const PHASE_ACTION = 'set_phase_form_entry'
const ACTIVITIES_ACTION = 'set_activity_form_entry'
const ROLE_ACTION = 'set_form_role_entry'
const ROLE_NAME_ACTION = 'set_activity_form_entry_role'
const IS_VISIBLE_ACTION = 'set_activity_form_entry_is_visible'

const initialStateFormEntry = {
    date: '',
    description: '',
    id: '',
    is_approved: false,
    steps_selected: [],
    time_end: '',
    time_start: '',
    phase: '',
    title: '',
    activities_selected: [],
    user: '',
    messages: [],
    resources: [],
    unexpected: false,
    message_unexpected: '',
    finished_item: false,
    is_visible: true,
}

const reducerFormEntry = (state = initialStateFormEntry, action) => {

    const { type, payload } = action

    switch (type) {

        case TITLE_ACTION:
            return {
                ...state,
                title: payload
            }
        case DATE_ACTION:
            return {
                ...state,
                date: payload
            }
        case DESCRIPTION_ACTION:
            return {
                ...state,
                description: payload
            }
        case IDENTIFIER_ACTION:
            return {
                ...state,
                id: payload
            }
        case APPROVAL_ACTION:
            return {
                ...state,
                is_approved: payload
            }
        case STEPS_ACTION:
            return {
                ...state,
                steps_selected: payload
            }
        case TIME_START_ACTION:
            return {
                ...state,
                time_start: payload
            }
        case TIME_END_ACTION:
            return {
                ...state,
                time_end: payload
            }

        case RESOURCE_LIST_ACTION:
            return {
                ...state,
                resources: payload

            }

        case UNEXPECTED_ACTION:
            return {
                ...state,
                unexpected: payload

            }

        case UNEXPECTED_MESSAGE_ACTION:
            return {
                ...state,
                message_unexpected: payload

            }

        case FINISHED_ITEM_ACTION:
            return {
                ...state,
                finished_item: payload

            }
        case PHASE_ACTION:
            return {
                ...state,
                phase: payload
            }
        case ACTIVITIES_ACTION:
            return {
                ...state,
                activities_selected: payload
            }
        case ROLE_ACTION:
            return {
                ...state,
                user: payload
            }
        case ROLE_NAME_ACTION:
            return {
                ...state,
                role_name: payload
            }
        case IS_VISIBLE_ACTION:
            return {
                ...state,
                is_visible: payload
            }
        case RESET_ACTION:
            return initialStateFormEntry

        default:
            return state

    }

}

const changeTitle = (value) => {
    return { type: TITLE_ACTION, payload: value }
}

const changeDate = (value) => {
    return { type: DATE_ACTION, payload: value }
}


const changeDescription = (value) => {
    return { type: DESCRIPTION_ACTION, payload: value }
}

const changeIdentifier = (value) => {
    return { type: IDENTIFIER_ACTION, payload: value }
}

const changeApproval = (value) => {
    return { type: APPROVAL_ACTION, payload: value }
}

const changeSteps = (value) => {
    return { type: STEPS_ACTION, payload: value }
}

const changeTimeStart = (value) => {
    return { type: TIME_START_ACTION, payload: value }
}

const changeTimeEnd = (value) => {
    return { type: TIME_END_ACTION, payload: value }
}

const changeResourceList = (value) => {
    return { type: RESOURCE_LIST_ACTION, payload: value }
}

const changeUnexpected = (value) => {
    return { type: UNEXPECTED_ACTION, payload: value }
}

const changeUnexpectedMessage = (value) => {
    return { type: UNEXPECTED_MESSAGE_ACTION, payload: value }
}

const changeFinishedItem = (value) => {
    return { type: FINISHED_ITEM_ACTION, payload: value }
}

const changePhase = (value) => {
    return { type: PHASE_ACTION, payload: value }
}

const changeActivitites = (value) => {
    return { type: ACTIVITIES_ACTION, payload: value }
}

const changeRole = (value) => {
    return { type: ROLE_ACTION, payload: value }
}

const changeRoleName = (value) => {
    return { type: ROLE_NAME_ACTION, payload: value }
}

const changeIsVisble = (value) => {
    return { type: IS_VISIBLE_ACTION, payload: value }
}

const resetToInitialState = () => {
    return { type: RESET_ACTION }
}

export {
    initialStateFormEntry,
    reducerFormEntry,
    changeTitle,
    changeDate,
    changeDescription,
    changeIdentifier,
    changeApproval,
    changeSteps,
    changeTimeStart,
    changeTimeEnd,
    changeResourceList,
    changeUnexpected,
    changeUnexpectedMessage,
    changeFinishedItem,
    changePhase,
    changeRole,
    changeRoleName,
    changeIsVisble,
    changeActivitites,
    resetToInitialState
}