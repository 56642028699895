import { camelCase } from "res/utils";
import { getColorForPhase } from "views/services-provider/ps-new-entry/phases";

const TimeLinePhaseItem = ({
  name,
  callback,
  showEntries,
  isSpMessage,
  isClientMessage,
  user,
  entry,
}) => {
  const [backgroundColor, textColor] = getColorForPhase(name);
  const renderMessagePulse = (user) => {
    switch (user) {
      case "sp":
        if (isSpMessage) {
          return <span className="time-container-pulse phase-message"></span>;
        }
        break;

      case "client":
        if (isClientMessage) {
          return <span className="time-container-pulse phase-message"></span>;
        }
        break;

      default:
        return null;
    }
  };

  return (
    <div
      className={`time-line-phase-container ${
        showEntries ? "show-entries" : ""
      }`}
      style={{ backgroundColor: backgroundColor, color: textColor }}
      onClick={callback}
    >
      <span>{camelCase(name)}</span>
      {renderMessagePulse(user)}
    </div>
  );
};

export default TimeLinePhaseItem;
