const setStatusCheck = (message) => {
  if (message.admin_read && message.client_read && message.sp_read) {
    return "#53bdeb";
  } else if (
    (message.admin_read && message.sp_read) ||
    (message.admin_read && message.client_read) ||
    (message.client_read && message.sp_read)
  ) {
    return "#FD941B";
  } else if (message.admin_read || message.client_read || message.sp_read) {
    return "#652D52";
  } else {
    return "rgb(111, 111, 111)";
  }
};

const setStatusRead = ({ admin, client, sp }) => {
  if (admin && client && sp) {
    return "#53bdeb";
  } else if (
    (admin && sp) ||
    (admin && client) ||
    (client && sp)
  ) {
    return "#FD941B";
  } else if (admin || client || sp) {
    return "#652D52";
  } else {
    return "rgb(111, 111, 111)";
  }
};



const showHowLong = (time) => {
  const now = new Date();
  const dateTime = new Date(time);

  const diffInSeconds = Math.floor((now.getTime() - dateTime.getTime()) / 1000);

  const timeUnits = [
    { unit: "Año", seconds: 31536000 },
    { unit: "Mes", seconds: 2592000 },
    { unit: "Día", seconds: 86400 },
    { unit: "Hora", seconds: 3600 },
    { unit: "Minuto", seconds: 60 },
    { unit: "Segundo", seconds: 1 },
  ];

  for (const unitData of timeUnits) {
    if (diffInSeconds >= unitData.seconds) {
      const count = Math.floor(diffInSeconds / unitData.seconds);
      return `Hace ${count} ${unitData.unit}${count > 1 ? "s" : ""}`;
    }
  }
};

const newMessageCheck = (message, userType) => {
  return userType === 200 ? !message.sp_read : !message.client_read;
};

export { newMessageCheck, setStatusCheck, showHowLong, setStatusRead };
