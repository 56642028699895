import React from "react";
import CALENDAR_ICON_SVG from "assets/icons/svg/calendar.svg";
import { capitalizeWord } from "res/utils";
import moment from "moment";

const ExtraInfo = ({ date }) => {
  const dateMoment = moment(date?.split("-")[5], "HH:mm:ss");
  const dateFormated = dateMoment.format("HH:mm A");
  return (
    <div className="cei-header-extra-info">
      <div className="cei-calendar-icon-container">
        <img className="cei-calendar-icon" src={CALENDAR_ICON_SVG} alt="" />
      </div>
      <div className="cei-date-container">
        <div className="cei-date">
          {capitalizeWord(date?.split("-")[2])} {date?.split("-")[1] ?? "año"}
        </div>

        <div className="cei-date">
          {dateFormated === "Fecha inválida" ? "6:00 AM" : dateFormated}
        </div>
      </div>
    </div>
  );
};

export default ExtraInfo;
