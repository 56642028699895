import React from "react";
import "./appbar.css";
import { AddRoundedButton } from "../button/Button";
import { ImagePicker } from "react-file-picker";
import LOGO_ICON_SVG from "../../../assets/icons/svg/justo_pago_logo_blanco.svg";
import Tabs from "../tab/Tab";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
const Appbar = ({
  smallText,
  bigText,
  isTogle,
  setActiveTab,
  activeTab,
  tabList,
  renderBackBtn = true,
}) => {
  return !isTogle ? (
    <div className="appbar">
      {renderBackBtn && <GoBackBtn type="default" />}
      <div data-div={true.toString()} className="small-text-container">
        {smallText}
      </div>
      <div className="big-text-container">{bigText}</div>
    </div>
  ) : (
    <div className="appbar">
      <div className="headerSection">
        <div className="big-text-container-with-tabs">{bigText}</div>
        <GoBackBtn type="default" />
      </div>
      <div className="header-togle">
        <Tabs
          tabs={tabList}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};

const AppbarLogo = () => {
  return (
    <div className="appbar appbar-logo-container">
      <img alt="" className="appbar-logo" src={LOGO_ICON_SVG} />
    </div>
  );
};

const AppbarProfile = ({
  options,
  thumbnail,
  name,
  userType,
  callbackSelectImage,
  callbackSelectImageError,
  score,
}) => {
  return (
    <div className="appbar-profile">
      <div className="profile-header-container">
        <div className="profile-header">Perfil</div>
        <div className="profile-config-icon"></div>
      </div>

      <div className="profile-user-info">
        <div className="profile-photo">
          <ImagePicker
            extensions={["jpg", "jpeg", "png"]}
            onChange={callbackSelectImage}
            onError={callbackSelectImageError}
            dims={{
              minWidth: 50,
              maxWidth: 6000,
              minHeight: 50,
              maxHeight: 6000,
            }}
            maxSize={100}
          >
            <div className="foto-btn">
              <AddRoundedButton thumbnail={thumbnail} />
            </div>
          </ImagePicker>
        </div>
        <div className="profile-name-container">
          <div className="profile-name">{name}</div>
          <div className="profile-rol">{userType}</div>
        </div>
      </div>

      <div className="profile-user-data">{options}</div>
    </div>
  );
};

export { Appbar, AppbarProfile, AppbarLogo };
