import React from "react";
import MessagesContainer from "./MessageContainer";
import { useEffect } from "react";
import {
  getInitialMessages,
  getNewMessages,
} from "services-controller/database";
import useReduxState from "custom-hooks/useReduxState";
import { actions } from "reducers/messages.reducer";
import { useSelector } from "react-redux";
import MessagesSkeleton from "./MessagesSkeleton";

const Messages = ({ id, entryId }) => {
  const userType = useSelector((state) => state.auth.userType);
  const [loading, setLoading] = React.useState(true);
  const [messageState, messagesActions] = useReduxState(
    actions,
    "messages_list"
  );

  const { updateMessages, newMessage, clearMessages } = messagesActions;

  useEffect(() => {
    setLoading(true);
    let newMessageListener = () => {};
    getInitialMessages(id, entryId).then((messages) => {
      updateMessages(messages);
      setLoading(false);
      newMessageListener = getNewMessages(
        id,
        entryId,
        (snapshot) => {
          newMessage(snapshot.val());
        },
        messages.length
      );
    });

    return () => {
      newMessageListener();
      clearMessages();
    };
  }, [id, entryId]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredMessages = messageState?.messageList?.filter(
    (message) => !message.is_valid || userType === "sp"
  );

  return loading ? (
    <MessagesSkeleton />
  ) : (
    <MessagesContainer messageList={filteredMessages} />
  );
};

export default Messages;
