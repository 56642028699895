import CheckIcon from "components/atoms/icons/CheckIcon";
import { setStatusRead, showHowLong } from "helpers/helpers.comment";
import React from "react";

const StatusMessage = ({ isMine, date, setShowDetail, showDetail, status }) => {
  return (
    <div className={`status-info${isMine}`}>
      <div className="commenr-user-date">{showHowLong(date)}</div>
      <div
        className="check-icon-message"
        onClick={() => setShowDetail(!showDetail)}
      >
        <CheckIcon
          className="check-icon"
          color={`${setStatusRead(status)}`}
          height="15px"
          width="15px"
        />
      </div>
    </div>
  );
};

export default StatusMessage;
