import { NextRoundedButton } from "components/atoms/button/Button";
import useNotifications from "custom-hooks/useNotifications";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { countUnreadNotifications } from "utils/utils.notifications";
import NotifiationIcon from "views/menu/notifications/NotificatioIcon";
import LOGO_ICON_SVG from "../../../assets/icons/svg/justo_pago_logo_blanco.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import { camelCase } from "../../../res/utils";
import {
  BASE_MENU_PATH,
  HOME,
  PROFILE,
  SERVICES,
} from "../../../router/routes";
import "./menu.css";

const UserMenuDesktop = ({ history, page, showCotizationMenu, callback }) => {
  const form_user = useSelector((state) => state.form_user);
  const { notifications } = useNotifications();
  const [selected, setSelect] = useState(page);
  const [showNoti, setShowNoti] = useState(false);

  const handleSelected = (e) => {
    setSelect(e.target.id);
    history.push(`${BASE_MENU_PATH}${e.target.id}`);
  };

  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [hasNewService, setHasNewService] = useState(false);

  const servicesList = useSelector((state) => state.services_list.servicesList);

  const setStatus = () => {
    for (let service of servicesList[0][1]) {
      if (service.statusMessages?.newClientMessage) {
        setHasNewMessage(true);
        break;
      }
      if (service.isNewClientEntry) {
        setHasNewService(true);
        break;
      }
    }
  };

  useEffect(() => {
    setStatus();
  }, [servicesList]);

  const showNotifications = () => {
    setShowNoti(!showNoti);
  };

  const closePopup = () => {
    if (showNoti) {
      setShowNoti(false);
    }
  };

  const {
    register_data: { name, thumbnail },
  } = form_user;

  return (
    <div className="menu-desktop-main-container" onClick={closePopup}>
      <div className="moreContainer">
        {page === HOME ? (
          <NextRoundedButton show={showCotizationMenu} callback={callback} />
        ) : null}
      </div>
      <div className="menu-desktop-container">
        <img className="logo-menu" src={LOGO_ICON_SVG} alt="" />
        <div className="center-options">
          {showCotizationMenu && (
            <div
              className={`menu-option ${
                selected === HOME ? "menu-selected" : ""
              }`}
              onClick={handleSelected}
              id={HOME}
            >
              Home
            </div>
          )}
          <div
            className={`menu-option ${
              selected === SERVICES ? "menu-selected" : ""
            }`}
            onClick={handleSelected}
            id={SERVICES}
          >
            Servicios
            <div
              className={`${hasNewMessage ? "pulse-new-message" : ""} ${
                hasNewService ? "pulse-is-new" : ""
              } `}
            ></div>
          </div>
        </div>
        <div className="right-options">
          <NotifiationIcon
            showNotifications={showNotifications}
            showNoti={showNoti}
            userType={form_user.register_type}
            notificationsUnread={countUnreadNotifications(notifications)}
          />

          <img
            alt=""
            className={`profile-photo-menu ${
              thumbnail !== "" ? "" : "profile-icon-menu"
            }`}
            src={thumbnail !== "" ? thumbnail : USER_ICON_SVG}
            onClick={handleSelected}
            id={PROFILE}
          />
          <div
            className="menu-option menu-user-name"
            onClick={handleSelected}
            id={PROFILE}
          >
            {name !== "" ? camelCase(name) : "Usuario"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(UserMenuDesktop);
