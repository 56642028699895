import { useEffect, useState } from "react";
import {
  despuaseService,
  getPhases,
  getServiceById,
} from "../.././services-controller/http.cleinte.controller";
import TimelineResume from ".././atoms/timelineActivities/TimeLineActivities";
import "./phase-status-resume.css";

import { useSelector } from "react-redux";
import { daysRemaining } from "../.././res/date";
import { pauseService } from "../.././services-controller/http.cleinte.controller";
import {
  canBeSelectedFinishPhase,
  phaseIsAvailableForFinish,
} from "../../views/services-provider/ps-new-entry/phases";
import { LoadingPopup } from ".././atoms/popup/Popup";

const ProgressBar = ({ color, percentage }) => {
  const completedPercentage = percentage;
  const remainingPercentage = 100 - percentage;

  return (
    <div className="progress-bar">
      <div
        className="progress-bar-fill"
        style={{ width: `${completedPercentage}%`, backgroundColor: color }}
      ></div>
      <div
        className="progress-bar-remaining"
        style={{ width: `${remainingPercentage}%` }}
      ></div>
    </div>
  );
};

function PhaseStatusResume(props) {
  const serviceId = props.props.serviceId;
  const [loading, setLoading] = useState(false);
  const [servicePhases, setServicePhases] = useState({});
  const [activites, setActivites] = useState([]);
  const [service, setService] = useState({});
  const [currentPhase, setCurrentPhase] = useState({});
  const [daysLater, setDaysLater] = useState(0);
  const [daysRemain, setDaysRemaining] = useState(0);
  const [isAvailableForFinish, setIsAvailableForFinish] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { user_type } = auth.user;
  const getDataPhases = async (serviceId) => {
    const phase = await getPhases(serviceId);
    const service = await getServiceById(serviceId);
    setService(service);
    setServicePhases(phase);
    let currentPhaseIndex = phase?.globalState?.state?.currentPhaseIndex;

    let currentPhase = phase?.phases.find(
      (ph) => ph.position === currentPhaseIndex
    );
    setActivites(currentPhase);
    setCurrentPhase(currentPhase);
    let [daysRemain, daysLater] = daysRemaining(
      currentPhase?.progress_status?.end_date
    );
    setDaysRemaining(daysRemain);
    setDaysLater(daysLater);
    let isAvailableForFinish = phaseIsAvailableForFinish(
      currentPhase,
      user_type
    );
    let canBeSelected = canBeSelectedFinishPhase(
      currentPhase,
      currentPhaseIndex
    );

    setIsAvailableForFinish(isAvailableForFinish && canBeSelected);
  };
  useEffect(() => {
    let Ismounted = true;
    if (Ismounted) {
      getDataPhases(serviceId);
    }

    return () => {
      Ismounted = false;
      if (Ismounted === false) {
        setServicePhases({});
        setActivites([]);
        setService({});
        setCurrentPhase({});
        getServiceById([]);
        setDaysLater(0);
        setDaysRemaining(0);
      }
    };
  }, [serviceId]);

  const handlePauseService = async () => {
    setLoading(true);
    await pauseService(serviceId);
    await getDataPhases(serviceId);

    setLoading(false);
  };

  const handleDespauseService = async () => {
    setLoading(true);
    await despuaseService(serviceId);
    await getDataPhases(serviceId);
    setLoading(false);
  };
  /*
  const currentPhaseEndDate = currentPhase?.progress_status?.end_date
  const currentPhaseStartDate = currentPhase?.progress_status?.start_da1te
  const cliente_photo = service?.metadata?.user?.register_data?.thumbnail || USER_ICON_SVG

  const serviceEndDate = servicePhases?.globalState?.progress?.end_date_expected
  const serviceStartDate = servicePhases?.globalState?.progress?.start_date
  */
  return (
    <div className="phase-resume-contianer">
      <div className="resume-details-container">
        <div className="resume-actual-phase">
          <TimelineResume activityGroup={currentPhase} />
        </div>
      </div>

      <LoadingPopup state={loading} />
    </div>
  );
}

export default PhaseStatusResume;
