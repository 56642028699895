import React, { useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import BasicInfoServiceProvider from './BasicInfoServiceProvider.jsx'
import { BASE_PS_TYPE_PATH, PS_CONTACT_INFO } from '../../../router/routes'
import uuidv5 from 'uuid/v5'
import { uploadImageThumbnail } from '../../../services-controller/storage'
import StateButtonModalHook from '../../../modal/StateButtonModalHook.jsx'
import {
    changeRegisterDataThumbnail,
    changeRegisterDataName,
    changeRegisterDataContactEmailAssociated,
    changeRegisterDataSecretsPassword,
    changeConveyanceLicensePlate,
    changeRegisterDataSecretsIdentifier,
    changeRegisterDataSecretsTypeIdentifier,
    changeRegisterDataMaritalStatus,
    changeRegisterDataAcademyMaximunAcademicLevel,
    changeRegisterDataAcademyCourses,
    changeConveyanceType,
    changeRegisterDataContactPhone
} from '../../../reducers/form.service.provider'
import { convertRegisterTypeByRouterToNames } from '../../../res/convert.codes'
import { REGEX_EMAIL, REGEX_PASS } from '../../../res/regex'
import { IDENTIFIER_TYPE_DEFAULT_VALUE } from '../../../res/identifier.type'
import { MARITAL_STATUS_DEFAULT_VALUE } from '../../../res/marital.status'
import { MAXIMUN_ACADEMIC_LEVEL_DEFAULT_VALUE } from '../../../res/maximum.academic.level'
import { ErrorRegister } from '../../../popups/SuccessRegister.jsx'
import { EMAIL_PASS_VALIDATION_ERROR_MESSAGE, REQUIRED_ERROR_MESSAGE, PHOTO_VALIDATION_ERROR_MESSAGE, PHONE_VALIDATION_ERROR_MESSAGE } from '../../../res/errors'
import { useSelector, useDispatch } from 'react-redux'

const BasicInfoServiceProviderController = ({ history }) => {

    //state

    let { id } = useParams()

    const [isErrorForm, setIsErrorForm] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')

    const dispatch = useDispatch()
    const form_service_provider = useSelector(state=> state.form_service_provider)

    const {
        register_data: {
            marital_status,
            thumbnail,
            name,
            contact: {
                email_associated,
                phone
            },
            secrets: {
                type_identifier,
                identifier,
                password
            },
            academy: {
                maximum_academic_level,
                courses
            }
        },
        conveyance: {
            type,
            license_plate
        }
    } = form_service_provider

    //general

    const callbackPushNavigator = () => {

        const credential = validatorEmail(email_associated) && validatorPassword(password)

        const required = (
            isDefaultValue(type_identifier, IDENTIFIER_TYPE_DEFAULT_VALUE) &&
            isDefaultValue(marital_status, MARITAL_STATUS_DEFAULT_VALUE) &&
            isDefaultValue(maximum_academic_level, MAXIMUN_ACADEMIC_LEVEL_DEFAULT_VALUE)
        )

        if (required) {

            if (credential) {
                if(validatorPhone(phone)){
                    history.push(`${BASE_PS_TYPE_PATH}${id}${PS_CONTACT_INFO}`)
                }else{
                    showMessageInPopUp(PHONE_VALIDATION_ERROR_MESSAGE)
                }
                
            }else {
                showMessageInPopUp(EMAIL_PASS_VALIDATION_ERROR_MESSAGE)
            }

        } else showMessageInPopUp(REQUIRED_ERROR_MESSAGE)
    }

    const callbackSelectImage = async (file) => {
        const uid = uuidv5(new Date().toLocaleString(), uuidv5.URL)
        const res = await uploadImageThumbnail(uid, file)
        const { code } = res
        if (code === 200) {
            const { url } = res
            dispatch(changeRegisterDataThumbnail(url))
        }
    }

    const callbackSelectImageError = (_) => {
        showMessageInPopUp(PHOTO_VALIDATION_ERROR_MESSAGE)
    }

    const callbackChangeName = (e) => dispatch(changeRegisterDataName(e.target.value))

    const callbackChangeEmail = (e) => dispatch(changeRegisterDataContactEmailAssociated(e.target.value))

    const callbackChangePassword = (e) => dispatch(changeRegisterDataSecretsPassword(e.target.value))

    const callbackChangeIdentifier = (e) => dispatch(changeRegisterDataSecretsIdentifier(e.target.value))

    const callbackChangeTypeIdentifier = (e) => dispatch(changeRegisterDataSecretsTypeIdentifier(e.target.value))

    const callbackChangeMaritalStatus = (e) => dispatch(changeRegisterDataMaritalStatus(e.target.value))

    const callbackChangeMaximunLevelAcademic = (e) => dispatch(changeRegisterDataAcademyMaximunAcademicLevel(e.target.value))

    const callbackChangeStudy = (e) => dispatch(changeRegisterDataAcademyCourses(e.target.value))

    const callbackChangeConveyance = (id) => dispatch(changeConveyanceType(id))

    const callbackChangeLicencePlate = (e) => dispatch(changeConveyanceLicensePlate(e.target.value))

    const callbackChangePhoneNumber = (e) => dispatch(changeRegisterDataContactPhone(e.target.value))

    const isDefaultValue = (str, defaultValue) => str !== defaultValue

    const isEmpty = (str) => str.length !== 0

    const validatorEmail = (str) => {
        const result = REGEX_EMAIL.test(str)
        return result
    }

    const validatorPassword = (str) => {
        const result = REGEX_PASS.test(str)
        return result
    }

    const validatorPhone = (str) => {
        const result = (str.length === 7 || str.length === 10 || str.length === 0)
        return result
    }

    const isAvailableCallbackNex = () => {

        const result = (isEmpty(name) && isEmpty(identifier) && isEmpty(type))

        return result
    }

    const stateErrorFormCallback = () => setIsErrorForm(false)

    const showMessageInPopUp = (message) => {
        setIsErrorForm(true)
        setErrorMessage(message)
    }

    return (
        <>
            <BasicInfoServiceProvider
                callbackSelectImage={callbackSelectImage}
                callbackSelectImageError={callbackSelectImageError}
                thumbnail={thumbnail}
                type={convertRegisterTypeByRouterToNames(id)}
                callbackPushNavigator={callbackPushNavigator}
                callbackChangeName={callbackChangeName}
                defaultValueName={name}
                callbackChangeEmail={callbackChangeEmail}
                defaultValueEmail={email_associated}
                callbackChangePassword={callbackChangePassword}
                defaultValuePassword={password}
                callbackChangeIdentifier={callbackChangeIdentifier}
                defaultValueIdentifier={identifier}
                callbackChangeTypeIdentifier={callbackChangeTypeIdentifier}
                defaultValueTypeIdentifier={type_identifier}
                callbackChangeMaritalStatus={callbackChangeMaritalStatus}
                defaultValueMaritalStatus={marital_status}
                callbackChangeMaximunLevelAcademic={callbackChangeMaximunLevelAcademic}
                defaultValueMaximunLevelAcademic={maximum_academic_level}
                callbackChangeStudy={callbackChangeStudy}
                defaultValueStudy={courses}
                callbackChangeConveyance={callbackChangeConveyance}
                defaultValueConveyance={type}
                callbackChangeLicencePlate={callbackChangeLicencePlate}
                defaultValueLicencePlate={license_plate}
                isAvailableCallbackNex={isAvailableCallbackNex()}
                validEmail={validatorEmail(email_associated)}
                validPass={validatorPassword(password)}
                callbackChangePhoneNumber={callbackChangePhoneNumber}
                defaultValuePhoneNumber={phone}
            />
            {/* error */}
            {
                isErrorForm ?
                    <StateButtonModalHook
                        component={ErrorRegister}
                        hook={[isErrorForm, setIsErrorForm]}
                        object={{
                            message: errorMessage,
                            callback: stateErrorFormCallback
                        }}
                    /> : null
            }
        </>

    )
}

export default withRouter(BasicInfoServiceProviderController)