import moment from "moment";
import { isHoliday } from "colombian-holidays/lib/utils/isHoliday";

const newDate = () => {
  // Obtén la fecha y hora actual en la zona horaria local
  const fechaHoraLocal = moment();

  // Ajusta la fecha y hora a la zona horaria de Colombia (UTC-5)
  const fechaHoraColombia = fechaHoraLocal.utcOffset(-5);

  // Formatea la fecha y hora según tus necesidades
  const fechaHoraFormateada = fechaHoraColombia.format("YYYY-MM-DD");

  // Devuelve la fecha y hora formateada en la zona horaria de Colombia
  return fechaHoraFormateada;
};

const getDate = (dateString) => {
  const date = moment(dateString);
  return date;
};

const daysRemaining = (dateString) => {
  const dateEnd = getDate(dateString);
  const now = newDate();

  // Calcula la diferencia en días entre la fecha final y la fecha actual
  const remainingDays = dateEnd.diff(now, "days");

  if (remainingDays > 0) {
    return [remainingDays, 0];
  } else {
    return [0, Math.abs(remainingDays)];
  }
};

const getDiference = (dateString1, dateString2) => {
  const date1 = getDate(dateString1);
  const date2 = getDate(dateString2);

  const diference = date1.diff(date2, "days");

  return diference;
};

//get remaining days whitout weekend days and colombia holidays
const getRemainingHabilDays = (dateString) => {
  const date = getDate(dateString);
  const now = moment(newDate());

  let remainingDays = date.diff(now, "days");

  let remainingHabilDays = 0;
  let day = now.clone();

  while (remainingDays > 0) {
    if (day.isoWeekday() < 6 && !isHoliday(day.toDate())) {
      remainingHabilDays += 1;
    }
    day.add(1, "days");
    remainingDays -= 1;
  }

  return remainingHabilDays;
};

const convertFormat = (dateStr) => {
    // Split the original string into parts
    const parts = dateStr.split('-');

    // Map the month name to its corresponding number in Spanish
    const months = {
        'enero': '01',
        'febrero': '02',
        'marzo': '03',
        'abril': '04',
        'mayo': '05',
        'junio': '06',
        'julio': '07',
        'agosto': '08',
        'septiembre': '09',
        'octubre': '10',
        'noviembre': '11',
        'diciembre': '12'
    };

    // Get the month number from the 'months' object
    const monthNumber = months[parts[2].toLowerCase()];

    // Build the new date string in the desired format
    const newDate = `${parts[4]}-${monthNumber}-${parts[1]}`;

    return newDate;
}

export { newDate, getDate, daysRemaining, getRemainingHabilDays, getDiference, convertFormat };
