import moment from "moment";
import "moment/locale/es";
import { useState } from "react";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import {
  NotificationsContainer,
  ServicesContainer,
} from "../../molecules/container/Container";
import "./information.css";
import { Empty } from "antd";

const NotificationsInformation = ({
  arr,
  onHandleNotiClick,
  handleMarkAsRead,
  handleRemoveById,
}) => {
  const filteredNotifications = arr.filter(
    (obj) => obj?.title !== "Nueva Entrada"
  );
  return (
    <div className="informationContainer">
      {arr.length === 0 ? (
        <Empty
          description={"No hay notificaciones"}
          style={{ marginTop: "60px" }}
        />
      ) : filteredNotifications.length > 0 ? (
        filteredNotifications.map((obj, key) => (
          <NotificationsContainer
            key={key}
            obj={obj}
            onHandleNotiClick={onHandleNotiClick}
            handleRemoveById={handleRemoveById}
            handleMarkAsRead={handleMarkAsRead}
          />
        ))
      ) : (
        <Empty
          description={"No hay notificaciones"}
          style={{ marginTop: "60px" }}
        />
      )}
    </div>
  );
};

const ServicesInformation = ({ content, navigatorService }) => {
  const [fragmentSelected, setSelect] = useState(content[0][0]);

  const handleChangeFragment = (e) => setSelect(e.target.id);

  return (
    <>
      <div className="servicesInfoContainer">
        <div className="servicesMenu">
          {content.map((fragment, key) => (
            <div
              onClick={handleChangeFragment}
              key={key}
              id={fragment[0]}
              className={`menuOption ${
                fragmentSelected === fragment[0] ? "selectedInfo" : ""
              }`}
            >
              {fragment[0]}
            </div>
          ))}
        </div>

        <div className="containerSlide">
          {content.map((service, key) => (
            <div
              id={service[0]}
              key={key}
              className={`servicesInfo ${
                fragmentSelected === service[0] ? "show" : "hide"
              }`}
            >
              {service[1].length > 0 ? (
                service[1].map((object, key) => (
                  <div
                    className="SpSpaceBetweenContainers si-container-info baseContainer"
                    onClick={() => {}}
                    key={key}
                  >
                    <div
                      className="infoContainer sc-space-style"
                      onClick={() => {}}
                    >
                      <ServicesContainer
                        key={key}
                        object={object}
                        navigatorService={navigatorService}
                      />
                    </div>
                    <div
                      className={`${
                        object.statusMessages?.newSpMessage
                          ? "pulse-new-message"
                          : ""
                      } ${object.isNewSpEntry ? "pulse-is-new" : ""} `}
                    ></div>
                  </div>
                ))
              ) : (
                <div className="no-assistants-container client-services-feedback">
                  <img
                    alt=""
                    src={FROWN_ICON_SVG}
                    className="no-entries-icon"
                  />
                  <div className="no-properties-title">
                    No hay servicios {service[0]}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const RequestsInformation = ({
  arr,
  callbackRechazar,
  callbackAceptar,
  callbackDetails,
}) => {
  moment.locale("es");

  const changeStageToLabel = (key) => {
    switch (key) {
      case 7:
        return "Garantia";
      default:
        return "Cotización";
    }
  };
};
export { NotificationsInformation, RequestsInformation, ServicesInformation };
