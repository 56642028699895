import AudioPlayerComponent from "components/atoms/audio/AudioPlayerComponent";
import useReduxState from "custom-hooks/useReduxState";
import React from "react";
import { useEffect } from "react";
import { ReactMic } from "react-mic";
import { chatRecordingActions } from "reducers/chat.controller.reducer";

const Recording = ({ recording, onStopRecording }) => {
  return (
    <ReactMic
      record={recording}
      className="sound-wave"
      mimeType="audio/mp3"
      onStop={onStopRecording}
      strokeColor="#FD941B"
      backgroundColor="#eae5ea"
    />
  );
};

const AudioPlayer = ({ recordedUrl, deleteAudio }) => {
  const [audioUrl, setAudioUrl] = React.useState(null);

  const loadBlob = async () => {
    try {
      const response = await fetch(recordedUrl.blobURL);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      setAudioUrl(url);
    } catch (error) {
      console.error("Error loading blob:", error);
    }
  };

  useEffect(() => {
    if (recordedUrl) {
      loadBlob();
    }

    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
        setAudioUrl(null);
      }
    };
  }, [recordedUrl]);

  return (
    <AudioPlayerComponent
      recorded={{ url: audioUrl }}
      deleteAudio={deleteAudio}
    />
  );
};

const RecordingComponent = () => {
  const [chatControlState, recordingActions] = useReduxState(
    chatRecordingActions,
    "chatController"
  );

  const { saveRecordedBlob, showAudioSaved, cleanRecordedBlob } =
    recordingActions;

  const { recordedBlob, isRecording, showRecording } = chatControlState;

  const deleteAudio = () => cleanRecordedBlob();

  const onStopRecording = (recordedBlob) => {
    //parse with
    saveRecordedBlob(recordedBlob);
    showAudioSaved();
  };

  return (
    <>
      {showRecording && (
        <Recording recording={isRecording} onStopRecording={onStopRecording} />
      )}
      {recordedBlob && (
        <AudioPlayer recordedUrl={recordedBlob} deleteAudio={deleteAudio} />
      )}
    </>
  );
};

export default RecordingComponent;
