// OPERATION

import { SERVICE_PROVIDER_SUPERVISOR_CODE } from "router/codes.type.properties";

const ADMIN_CODE = 0;
const COO_CODE = 220;
const OPERATION_COORDINATOR_CODE = 221;
const RESIDENT_CODE = 222;
const CARPENTRY_COORDINATOR_CODE = 230;
const CARPENTRY_LEADER_CODE = 231;
const CARPENTRY_ASSISTANT_CODE = 232;

//ADMINISTRATION
const CFO_CODE = 240;
const ADMIN_COORDINATOR_CODE = 241;
const ADMIN_ASISTANT_CODE = 242;
const HUMAN_RESOURCES_CODE = 243;

//PLANEATION
const CSO_CODE = 250;
const PLANEATION_COORDINATOR_CODE = 251;
const PLANEATION_ASISTANT_CODE = 252;
const DOCUMENTATION_ASISTANT_CODE = 253;
const PLANEATION_AUXILIARY_CODE = 254;
const POST_SOLD_AUXILIARY_CODE = 255;

const DESIGN_COORDINATOR_CODE = 260;
const ARCHITECT_DESIGN_CODE = 261;
const DESIGN_AUXILIARY_CODE = 262;

const COMERCIAL_DIRECTOR_CODE = 270;
const COMERCIAL_COORDINATOR_CODE = 271;
const COMERCIAL_ASISTANT_CODE = 272;
const MARKETING_COORDINATOR_CODE = 280;

//NAMES

//OPERATION
const COO_NAME = "Director de Operaciones";
const OPERATION_COORDINATOR_NAME = "Coordinador de Operaciones";
const RESIDENT_NAME = "Residente";
const CARPENTRY_COORDINATOR_NAME = "Coordinador de Carpintería";
const CARPENTRY_LEADER_NAME = "Líder de Carpintería";
const CARPENTRY_ASSISTANT_NAME = "Auxiliar de Carpintería";

//ADMINISTRATION
const CFO_NAME = "Director Financiero";
const ADMIN_COORDINATOR_NAME = "Coordinador de Administración";
const ADMIN_ASISTANT_NAME = "Auxiliar de Administración";
const HUMAN_RESOURCES_NAME = "Recursos Humanos";

//PLANEATION
const CSO_NAME = "Director de Planeación";
const PLANEATION_COORDINATOR_NAME = "Coordinador de Planeación";
const PLANEATION_ASISTANT_NAME = "Auxiliar de Planeación";
const DOCUMENTATION_ASISTANT_NAME = "Auxiliar de Documentación";
const PLANEATION_AUXILIARY_NAME = "Auxiliar de Planeación";
const POST_SOLD_AUXILIARY_NAME = "Auxiliar de Postventa";

const DESIGN_COORDINATOR_NAME = "Coordinador de Diseño";
const ARCHITECT_DESIGN_NAME = "Arquitecto de Diseño";
const DESIGN_AUXILIARY_NAME = "Auxiliar de Diseño";

//COMERCIAL
const COMERCIAL_DIRECTOR_NAME = "Director Comercial";
const COMERCIAL_COORDINATOR_NAME = "Coordinador Comercial";
const COMERCIAL_ASISTANT_NAME = "Auxiliar Comercial";
const MARKETING_COORDINATOR_NAME = "Coordinador de Mercadotecnia";

const SERVICE_PROVIDER_SUPERVISOR_NAME = "Supervisor";

const convertRoleCodeToName = (code) => {
  switch (code) {
    case SERVICE_PROVIDER_SUPERVISOR_CODE:
      return SERVICE_PROVIDER_SUPERVISOR_NAME;
    case COO_CODE:
      return COO_NAME;
    case OPERATION_COORDINATOR_CODE:
      return OPERATION_COORDINATOR_NAME;
    case RESIDENT_CODE:
      return RESIDENT_NAME;
    case CARPENTRY_COORDINATOR_CODE:
      return CARPENTRY_COORDINATOR_NAME;
    case CARPENTRY_LEADER_CODE:
      return CARPENTRY_LEADER_NAME;
    case CARPENTRY_ASSISTANT_CODE:
      return CARPENTRY_ASSISTANT_NAME;
    case CFO_CODE:
      return CFO_NAME;
    case ADMIN_COORDINATOR_CODE:
      return ADMIN_COORDINATOR_NAME;
    case ADMIN_ASISTANT_CODE:
      return ADMIN_ASISTANT_NAME;
    case HUMAN_RESOURCES_CODE:
      return HUMAN_RESOURCES_NAME;
    case CSO_CODE:
      return CSO_NAME;
    case PLANEATION_COORDINATOR_CODE:
      return PLANEATION_COORDINATOR_NAME;
    case PLANEATION_ASISTANT_CODE:
      return PLANEATION_ASISTANT_NAME;
    case DOCUMENTATION_ASISTANT_CODE:
      return DOCUMENTATION_ASISTANT_NAME;
    case PLANEATION_AUXILIARY_CODE:
      return PLANEATION_AUXILIARY_NAME;
    case POST_SOLD_AUXILIARY_CODE:
      return POST_SOLD_AUXILIARY_NAME;
    case DESIGN_COORDINATOR_CODE:
      return DESIGN_COORDINATOR_NAME;
    case ARCHITECT_DESIGN_CODE:
      return ARCHITECT_DESIGN_NAME;
    case DESIGN_AUXILIARY_CODE:
      return DESIGN_AUXILIARY_NAME;
    case COMERCIAL_DIRECTOR_CODE:
      return COMERCIAL_DIRECTOR_NAME;
    case COMERCIAL_COORDINATOR_CODE:
      return COMERCIAL_COORDINATOR_NAME;
    case COMERCIAL_ASISTANT_CODE:
      return COMERCIAL_ASISTANT_NAME;
    case MARKETING_COORDINATOR_CODE:
      return MARKETING_COORDINATOR_NAME;
    case ADMIN_CODE:
      return "Admin";
    default:
      return "Sin rol";
  }
};

const isRoleForOperation = (code) => {
  switch (code) {
    case COO_CODE:
      return true;
    case OPERATION_COORDINATOR_CODE:
      return true;
    case RESIDENT_CODE:
      return true;
    case CARPENTRY_COORDINATOR_CODE:
      return true;
    case CARPENTRY_LEADER_CODE:
      return true;
    case CARPENTRY_ASSISTANT_CODE:
      return true;
    default:
      return false;
  }
};

const isRoleForPlaneation = (code) => {
  switch (code) {
    case CSO_CODE:
      return true;
    case PLANEATION_COORDINATOR_CODE:
      return true;
    case PLANEATION_ASISTANT_CODE:
      return true;
    case DOCUMENTATION_ASISTANT_CODE:
      return true;
    case PLANEATION_AUXILIARY_CODE:
      return true;

    default:
      return false;
  }
};

const isRoleForDesign = (code) => {
  switch (code) {
    case CSO_CODE:
      return;
    case PLANEATION_COORDINATOR_CODE:
      return;
    case DESIGN_COORDINATOR_CODE:
      return true;
    case ARCHITECT_DESIGN_CODE:
      return true;
    case DESIGN_AUXILIARY_CODE:
      return true;
    default:
      return false;
  }
};

const isRoleOf = (codeRole) => {
  switch (codeRole) {
    case "operation":
      return isRoleForOperation(codeRole);
    case "planeation":
      return isRoleForPlaneation(codeRole);
    case "design":
      return isRoleForDesign(codeRole);
    default:
      return false;
  }
};

const isBoss = (codeRole) => {
  switch (codeRole) {
    case COO_CODE:
      return true;
    case CFO_CODE:
      return true;
    case CSO_CODE:
      return true;
    case COMERCIAL_DIRECTOR_CODE:
      return true;
    default:
      return false;
  }
};

const isBossOf = (codeRole, phase) => {
  if (
    phase.position === 0 &&
    (codeRole === CSO_CODE || codeRole === DESIGN_COORDINATOR_CODE)
  ) {
    return true;
  }
  if (phase.position === 1 && codeRole === CSO_CODE) {
    return true;
  }
  if (phase.position === 2 && codeRole === COO_CODE) {
    return true;
  }
};

export { convertRoleCodeToName, isBoss, isBossOf, isRoleOf };

