import { SeguimientoOptionsButton } from "components/atoms/button/Button";
import useNotifications from "custom-hooks/useNotifications";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { countUnreadNotifications } from "utils/utils.notifications";
import NotificatioIcon from "views/menu/notifications/NotificatioIcon";
import LOGO_ICON_SVG from "../../../assets/icons/svg/justo_pago_logo_blanco.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import { camelCase } from "../../../res/utils";
import {
  PS_BASE_MENU_PATH,
  PS_MY_SERVICES,
  PS_PROFILE,
  PS_SERVICES,
} from "../../../router/routes";
import "./menu.css";

const ServiceProviderMenuDesktop = ({
  history,
  page,
  callback,
  callbackWhatsappButton,
  moreValues,
  showSeguimientoOptionsButton,
}) => {
  const form_service_provider = useSelector(
    (state) => state.form_service_provider
  );

  const servicesList = useSelector((state) => state.services_list.servicesList);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [hasNewService, setHasNewService] = useState(false);
  const { notifications } = useNotifications();
  const setStatus = () => {
    for (let service of servicesList) {
      if (service.statusMessages?.newSpMessage) {
        setHasNewMessage(true);
        break;
      }
      if (service.isNewSpEntry) {
        setHasNewService(true);
        break;
      }
    }
  };

  const [selected, setSelect] = useState(page);
  const [showNoti, setShowNoti] = useState(false);

  const {
    register_data: { name, thumbnail },
  } = form_service_provider;

  const handleSelected = (e) => {
    setSelect(e.target.id);
    history.push(`${PS_BASE_MENU_PATH}${e.target.id}`);
  };

  const showNotifications = () => {
    setShowNoti(!showNoti);
  };

  const closePopup = () => {
    if (showNoti) {
      setShowNoti(false);
    }
  };

  useEffect(() => {
    setStatus();
  }, [servicesList]);

  return (
    <div className="menu-desktop-main-container" onClick={closePopup}>
      <div className="moreContainer">
        {showSeguimientoOptionsButton && (
          <SeguimientoOptionsButton
            callback={callbackWhatsappButton}
            moreValues={moreValues}
          />
        )}
      </div>
      <div className="menu-desktop-container">
        <img alt="" className="logo-menu" src={LOGO_ICON_SVG} />

        <div className="center-options">
          <div
            className={`menu-option ${
              selected === PS_SERVICES ? "menu-selected" : ""
            }`}
            onClick={handleSelected}
            id={PS_MY_SERVICES}
          >
            Servicios
            <div
              className={`${hasNewMessage ? "pulse-new-message" : ""} ${
                hasNewService ? "pulse-is-new" : ""
              } `}
            ></div>
          </div>
        </div>

        <div className="right-options">
          <NotificatioIcon
            showNotifications={showNotifications}
            showNoti={showNoti}
            userType={form_service_provider.register_type}
            notificationsUnread={countUnreadNotifications(notifications)}
          />

          <img
            alt=""
            className={`profile-photo-menu ${
              thumbnail !== "" ? "" : "profile-icon-menu"
            }`}
            src={thumbnail !== "" ? thumbnail : USER_ICON_SVG}
            onClick={handleSelected}
            id={PS_PROFILE}
          />
          <div
            className="menu-option menu-user-name"
            onClick={handleSelected}
            id={PS_PROFILE}
          >
            {name !== "" ? camelCase(name) : "Usuario"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ServiceProviderMenuDesktop);
