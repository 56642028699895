import React, { useState, useRef } from "react";
import START_ROUTE from "assets/icons/svg/start-journey.svg";
import "../route.css";

const SwipeableButton = ({ onSwipeSuccess }) => {
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [initialMouse, setInitialMouse] = useState(0);
  const [slideMovementTotal, setSlideMovementTotal] = useState(0);
  const [started, setStarted] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const sliderRef = useRef(null);
  const textColorRef = useRef(null);

  const handleMouseDown = (event) => {
    setMouseIsDown(true);
    setSlideMovementTotal(
      sliderRef.current.parentNode.offsetWidth -
        sliderRef.current.offsetWidth +
        10
    );
    setInitialMouse(event.clientX || event.touches[0].pageX);
    sliderRef.current.classList.remove("slider-hint");
  };

  const handleMouseUp = (event) => {
    if (!mouseIsDown) return;
    setMouseIsDown(false);
    const currentMouse = event.clientX || event.changedTouches[0].pageX;
    const relativeMouse = currentMouse - initialMouse;

    if (relativeMouse < slideMovementTotal) {
      sliderRef.current.style.left = "-10px";
      setOpacity(1);
      return;
    }
    setStarted(true);
    onSwipeSuccess();
  };

  const handleMouseMove = (event) => {
    if (!mouseIsDown) return;
    const currentMouse = event.clientX || event.touches[0].pageX;
    const relativeMouse = currentMouse - initialMouse;

    if (relativeMouse <= 0) {
      sliderRef.current.style.left = "-10px";
      setOpacity(1);
      return;
    }
    if (relativeMouse >= slideMovementTotal + 10) {
      sliderRef.current.style.left = `${slideMovementTotal}px`;
      setOpacity(0);
      return;
    }
    sliderRef.current.style.left = `${relativeMouse - 10}px`;
    setOpacity(1 - relativeMouse / slideMovementTotal);
  };

  return (
    <div
      className="button-background"
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
    >
      <div ref={textColorRef} className="slide-text" style={{ opacity }}>
        Desliza para iniciar
      </div>

      <div
        ref={sliderRef}
        className={`slider ${started ? "in-route" : ""}`}
        onTouchStart={!started ? handleMouseDown : null}
        onMouseDown={!started ? handleMouseDown : null}
      >
        <span className="material-icons">
          <img src={START_ROUTE} alt="" />
          {started ? "Iniciando..." : ""}
        </span>
      </div>
    </div>
  );
};

export default SwipeableButton;
