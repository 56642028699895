import React from "react";
import "./dropdown.css";
import { BaseInput } from "../input/Input";
import { convertPhaseNameToSpanishName } from "../../../res/convert.codes";
const BaseUnexpectedDropdown = ({
  items,
  callback,
  defaultSelected,
  shouldReset,
}) => {
  const name = convertPhaseNameToSpanishName(defaultSelected.name);
  if (name !== "Not Found") {
    defaultSelected.title = name;
  }

  const handleDropdownChange = (event) => {
    const selectedItem = items[event.target.selectedIndex];
    if (selectedItem) callback(selectedItem);
    else callback(defaultSelected);
  };

  return (
    <select className="baseDropdown" onChange={handleDropdownChange}>
      {items.map((item, index) => (
        <option key={index} value={index} className="rol-option">
          {item.title}
        </option>
      ))}
      <option className="rol-option" selected>
        {defaultSelected.title}
      </option>
    </select>
  );
};

const BaseDropdown2 = ({ items, callback, defaultSelected }) => {
  const handleDropdownChange = (event) => {
    const selectedItem = items[event.target.selectedIndex];
    callback(selectedItem);
  };

  return (
    <select className="baseDropdown" onChange={handleDropdownChange}>
      {items.map((item, index) => (
        <option key={index} value={index} className="rol-option">
          {item.title}
        </option>
      ))}
      <option className="rol-option" selected>
        {defaultSelected}
      </option>
    </select>
  );
};

const IconDropdown2 = ({
  icon,
  callback,
  items,
  defaultSelected,
  valueSelected,
  colorStyle,
  disable,
}) => {
  return (
    <div className={`containerIconDropdown ${colorStyle} `}>
      <div className="containerDroppdown">
        <img src={icon} className="icon" alt={"IconDropdown"} />
      </div>
      <BaseDropdown2
        disable={disable}
        items={items}
        className="iconDropdown"
        callback={callback}
        defaultSelected={defaultSelected}
        value={valueSelected}
      />
    </div>
  );
};

const LocationDropdown = ({ items, callback, defaultOption }) => {
  return (
    <select
      defaultValue={defaultOption}
      className="baseDropdown locationDropdown"
      onChange={callback}
    >
      {items}
    </select>
  );
};

const DropdownWithInput = ({
  items,
  callback,
  defaultSelected,
  placeholder,
  style,
  defaultValue,
  callbackInput,
  typeInput,
}) => {
  return (
    <div className="dropdownWithInputContainer">
      <div className="containerDropdownInput">
        <BaseDropdown
          items={items}
          style="iconDropdownInput"
          callback={callback}
          defaultSelected={defaultSelected}
        />
      </div>
      <div className="dwi-input">
        <BaseInput
          type={typeInput}
          callback={callbackInput}
          defaultValue={defaultValue}
          placeholder={placeholder}
          style={style}
        />
      </div>
    </div>
  );
};

const BaseDropdown = ({
  items,
  callback,
  defaultSelected,
  value,
  style,
  disable,
}) => {
  return (
    <select
      className={`baseDropdown ${style}`}
      onChange={callback}
      disabled={disable}
    >
      <option value={value}>{defaultSelected}</option>
      {items}
    </select>
  );
};

const IconDropdown = ({
  icon,
  callback,
  items,
  defaultSelected,
  valueSelected,
  colorStyle,
  disable,
}) => {
  return (
    <div className={`containerIconDropdown ${colorStyle} `}>
      <div className="containerDroppdown">
        <img src={icon} className="icon" alt={"IconDropdown"} />
      </div>
      <BaseDropdown
        disable={disable}
        items={items}
        className="iconDropdown"
        callback={callback}
        defaultSelected={defaultSelected}
        value={valueSelected}
      />
    </div>
  );
};

export {
  BaseDropdown,
  BaseDropdown2,
  IconDropdown2,
  IconDropdown,
  LocationDropdown,
  DropdownWithInput,
  BaseUnexpectedDropdown,
};
