import NavBar from "components/Navbar/NavBar";
import SERVICES_ICON_SVG from "../../../assets/icons/svg/servicios.svg";
import { Appbar } from "../../../components/atoms/appbar/Appbar";
import PsBigCard from "../../../components/atoms/card/psCard/PsBigCard";
import ServiceProviderMenuDesktop from "../../../components/molecules/menu/ServiceProviderMenu";
import { PS_MY_SERVICES, PS_SERVICES } from "../../../router/routes";
import "./psServices.css";

const ServiceProviderServicesMainController = () => {
  return (
    <div className="usertype-main-container appbar-no-background">
      <ServiceProviderMenuDesktop page={PS_SERVICES} />

      <div className="ps-services-list-container">
        <div className="new-service-appbar-container">
          <Appbar renderBackBtn={false} smallText="Tus " bigText="Servicios" />
        </div>

        <div className="ps-services-card">
          <div className="container-my-services services-type">
            <PsBigCard
              icon={SERVICES_ICON_SVG}
              text="Mis servicios"
              id={PS_MY_SERVICES}
            />
          </div>
        </div>
      </div>

      <NavBar />
    </div>
  );
};

export default ServiceProviderServicesMainController;
