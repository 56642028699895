import { withRouter } from "react-router-dom";
import { Appbar } from "../../../components/atoms/appbar/Appbar";
import ButtonNavbar from "../../../components/molecules/buttonNavbar/ButtonNavbar";
import UserMenuDesktop from "../../../components/molecules/menu/UserMenu";
import { ServicesInformation } from "../../../components/organisms/information/Information";
import { SERVICES } from "../../../router/routes";
import "./services.css";

const Services = ({ navigatorService, servicesList, forAprove }) => {
  return (
    <div className="services-container appbar-no-background">
      <UserMenuDesktop
        page={SERVICES}
        showCotizationMenu={forAprove}
        showBtn={true}
        popupContent={["Servicios", "Garantias"]}
      />

      <div className="services-appbar-container services-user">
        <Appbar smallText="Servicios" bigText="Servicios" />
      </div>

      <div className="options-services-container">
        <ServicesInformation
          content={servicesList}
          navigatorService={navigatorService}
        />
      </div>

      <div className="menu-navbar-container">
        <ButtonNavbar
          showCotizationMenu={forAprove}
          page={SERVICES}
          showBtn={true}
          popupContent={["Servicios", "Garantias"]}
        />
      </div>
    </div>
  );
};

export default withRouter(Services);
