import React from 'react'
import {PopupSelectItems} from '../components/atoms/popup/Popup'


const SelectItems = ({object}) => {

    const {
        callbackSelectItem,
        callbackAccept,
        itemList,
        enableApproval,
        callbackSelectDate,
        selectedDate,
        callbackScheduleStart,
        callbackScheduleEnd
    } = object
    

    return (
        <PopupSelectItems
        callbackSelectItem={callbackSelectItem}
        callbackAccept={callbackAccept}
        itemList={itemList}
        enableApproval={enableApproval}
        callbackSelectDate={callbackSelectDate}
        selectedDate={selectedDate}
        callbackScheduleStart={callbackScheduleStart}
        callbackScheduleEnd={callbackScheduleEnd}

        />
    )
}

export {
    SelectItems
}