import React, { useState } from 'react'
import './calendarSlide.css'
import { CalendarDayButton } from '../../atoms/button/Button'
import moment from 'moment';
const CalendarSlide = ({ daysList, onHandleChangeDate, defaultValueDate }) => {


    const isSelected = date => {
        if (defaultValueDate) {
            return moment(defaultValueDate).isSame(date, 'day');
        } else {
            return false;
        }
    };

    return (
        <div className='ps-sl-days-items'>
            {daysList.map((obj, index) =>
                <CalendarDayButton key={index} numberCalendar={obj.number} selected={isSelected(obj.date)} monthCalendar={obj.month} id={obj.month + ' ' + obj.number} callback={() => onHandleChangeDate(obj.date)} />
            )}
        </div>
    )
}

export default CalendarSlide