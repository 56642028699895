import React, { useState} from 'react'
import { withRouter} from 'react-router-dom'
import RegisterNewPersonal from '../../register/propertyTypes/RegisterNewPersonal.jsx'
import {
    PROPERTY_ALREADY_EXIST, CHECK, EXIT,
    REQUIRED_ALL_PARAMS, REGISTER_MANAGEMENT_REQUIRED_PARAMS
} from '../../../res/message'

import StateButtonModalHook from '../../../modal/StateButtonModalHook.jsx'
import {
    changeNameManagement, changePositionManagement, changePrimaryPhone, changeAuxiliaryPhone,
    changeEmailManagement
} from '../../../reducers/form.management.reducer'
import { REGEX_EMAIL } from '../../../res/regex'
import { LoadingPopup } from '../../../components/atoms/popup/Popup'
import { PopupSimpleTextTwoOptions } from '../../../popups/PopupNoProperty.jsx'
import { PopupSuccessEvent } from '../../../popups/psPopups/PopupSuccessEvent'
import {editManagement} from '../../../services-controller/http.cleinte.controller'
import { useDispatch, useSelector } from 'react-redux'

const EditManagementController = ({ history}) => {

    const dispatch = useDispatch
    const state = useSelector(state => state.form_management)


    const form_management  = state

    const {
        employ: {
            position
        },
        register_data: {
            name,
            contact: {
                primary_phone,
                auxiliary_phone,
                email_associated
            }
        },
        type
    } = form_management


    //state view   

    const [messageHandler, setMissageHandler] = useState(PROPERTY_ALREADY_EXIST)

    const [showPopUp, setShowPopUp] = useState(false)

    const [isLoader, setIsLoader] = useState(false)

    const [personalType, setPersonalType] = useState(type)

    const [showSuccessEvent, setShowSuccessEvent] = useState(false)

    //start general

    const stringValidator = (str) => str !== ''

    const validatorPhone = (str) => {
        const result = (str.length === 7 || str.length === 10 || str.length === 0)
        return result
    }

    const validatorEmail = (str) => {
        const result = REGEX_EMAIL.test(str)
        return result
    }

    const callbackCreatePersonal = async () => {

        setIsLoader(true)
        const nameEvaluation = stringValidator(name)
        const positionEvaluation = stringValidator(position)
        const emailEvaluation = validatorEmail(email_associated)
        const phoneEvaluation = validatorPhone(primary_phone)

        const resultValidation = (
            nameEvaluation &&
            emailEvaluation && phoneEvaluation
        )

        if (resultValidation) {

            const object = {
                ...form_management,
                type: personalType
            }

            const res = await editManagement(object.unique_key, object)
            setIsLoader(false)
            setShowSuccessEvent(true)

        } else {
            setIsLoader(false)
            setMissageHandler(REQUIRED_ALL_PARAMS(REGISTER_MANAGEMENT_REQUIRED_PARAMS))
            setShowPopUp(true)
        }
    }

    const callbackNameManagement = (value) => dispatch(changeNameManagement(value.target.value))

    const callbackPositionManagement = (value) => dispatch(changePositionManagement(value.target.value))

    const callbackPrimaryPhone = (value) => dispatch(changePrimaryPhone(value.target.value))

    const callbackAuxiliaryPhone = (value) => dispatch(changeAuxiliaryPhone(value.target.value))

    const callbackEmailManagement = (value) => dispatch(changeEmailManagement(value.target.value))

    const callbackPersonalType = (value) => {
        setPersonalType(value.target.value)
    }

    const callbackRegisterPositive = () => setShowPopUp(false)

    const callbackRegisterNegative = () => setShowPopUp(false)

    const callbackCloseSuccessEdit = () => {
        setShowSuccessEvent(false)
        history.goBack()
    }


    //__________

    return (
        <>

            <RegisterNewPersonal
                callbackCreatePersonal={callbackCreatePersonal}
                callbackNameManagement={callbackNameManagement}
                defaultValueNameManagement={name}
                callbackPositionManagement={callbackPositionManagement}
                defaultValuePositionManagement={position}
                callbackPrimaryPhone={callbackPrimaryPhone}
                defaultValuePrimaryPhone={primary_phone}
                callbackAuxiliaryPhone={callbackAuxiliaryPhone}
                defaultValueAuxiliaryPhone={auxiliary_phone}
                callbackEmailManagement={callbackEmailManagement}
                defaultValueEmailManagement={email_associated}
                callbackPersonalType={callbackPersonalType}
                edit={true}
            />

            {
                showPopUp ? <StateButtonModalHook
                    component={PopupSimpleTextTwoOptions}
                    hook={[showPopUp, setShowPopUp]}
                    object={
                        {
                            callbackRegisterPositive: callbackRegisterPositive,
                            callbackRegisterNegative: callbackRegisterNegative,
                            message: messageHandler,
                            textPositive: CHECK,
                            textNegative: EXIT
                        }
                    }


                /> : null
            }

            {
                showSuccessEvent ? <StateButtonModalHook
                    component={PopupSuccessEvent}
                    hook={[showSuccessEvent, setShowSuccessEvent]}
                    object={
                        {
                            message: 'Propiedad editada exitosamente',
                            btnText: 'OK',
                            callback: callbackCloseSuccessEdit
                        }
                    }
                /> : null
            }


            <LoadingPopup state={isLoader} />
        </>
    )
}

export default withRouter(EditManagementController)