import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import { styles } from "../routeStyles";
import { useState } from "react";
import { updateCamera, updateRoute } from "./functions";
import mapboxgl from "mapbox-gl";

export default function useRouteDirections(
  mapRef,
  directionsRef,
  token,
  route,
  setRouteGeneralInfo,
  setOrientation,
  setStepState
) {
  const [mapInstance, setMapInstance] = useState(null);

  const handleSetRouteGeneralInfo = (info) => {
    setRouteGeneralInfo(info);
  };

  const handleSetOrientation = (orientation) => {
    setOrientation(orientation);
  };

  const handleSetStepState = (stepState) => {
    setStepState(stepState);
  };

  const showAsignedRoute = (userLocation) => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (!directionsRef.current) {
        const directionsControl = new MapboxDirections({
          accessToken: token,
          unit: "metric",
          profile: "mapbox/driving-traffic",
          language: "es",
          alternatives: true,
          controls: {
            inputs: false,
            instructions: false,
            profileSwitcher: false,
          },
          interactive: false,
          congestion: true,
          styles: styles,
        });

        directionsRef.current = directionsControl;
        map.addControl(directionsControl, "top-left");
        setMapInstance(map);
      }

      const directionsControl = directionsRef.current;
      const waypoints = route.map((point) => [
        point.coords.lng,
        point.coords.lat,
      ]);

      updateRoute(
        directionsControl,
        userLocation,
        waypoints,
        handleSetRouteGeneralInfo,
        updateCamera,
        map,
        handleSetOrientation,
        handleSetStepState
      );

      const bounds = waypoints.reduce((bounds, coord) => {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(waypoints[0], waypoints[0]));

      map.fitBounds(bounds, {
        padding: 50,
      });
    }
  };

  return { showAsignedRoute, mapInstance };
}
