import React from "react";
import HeaderChatController from "./Components/ChatHeader/HeaderChatController";
import Messages from "./Components/Messages/MessagesController";
import ChatController from "./Components/ChatController/ChatController";

const ChatView = ({ entryId, id, userType, history }) => {
  return (
    <div className="new-service-container">
      <div className="desktop-container">
        {
          <div className="entry-info-container background-image-class">
            <HeaderChatController
              userType={userType}
              entryId={entryId}
              serviceId={id}
              history={history}
            />
            <Messages id={id} entryId={entryId} />
            <ChatController />
          </div>
        }
      </div>
    </div>
  );
};

export default ChatView;
