import React from 'react'
import './psPopups/psPopups.css'
import EDIT_ICON_SVG from '../assets/icons/svg/edit.svg'
import TRASH_ICON_SVG from '../assets/icons/svg/trash.svg'
import FILE_ICON_SVG from '../assets/icons/svg/file-text.svg'

const PopupOptions = ({object}) =>{
    const {
        callbackEdit,
        callbackDelete,
        textFirstOption='Editar',
        textSecondOption='Eliminar',
        showReportOption=false,
        textThridOption="Ver historial",
        callbackShowRepport
    } = object

    return(
        <div className='popup-options-container'>
            <div className='option-container' onClick={callbackEdit}>
                <img src={EDIT_ICON_SVG} className='option-icon'/>
                <div className='option-text'>{textFirstOption}</div>
            </div>
            <div className='option-container' onClick={callbackDelete}>
                <img src={TRASH_ICON_SVG} className='option-icon'/>
                <div className='option-text'>{textSecondOption}</div>
            </div>
            {
        !showReportOption ? 
        null :
        <div className='option-container' onClick={callbackShowRepport}>
            <img src={FILE_ICON_SVG} className='option-icon'/>
             <div className='option-text'>{textThridOption}</div>
        </div>
    }
        </div>
    )
}

export default PopupOptions