const SET_MANAGEMENT_TYPE_ACTION = 'set_management_type_action_form_management'
const SET_ACTIVE_ACTION = 'set_active_action_form_management'
const SET_QUERY_ACTION = 'set_query_action_form_management'
const SET_ID_ACTION = 'set_id_action_form_management'
const SET_REGISTER_DATA_NAME_ACTION = 'set_register_data_name_action_form_management'
const SET_REGISTER_DATA_CONTACT_PRIMARY_PHONE_ACTION = 'set_register_data_contact_primary_phone_action_form_management'
const SET_REGISTER_DATA_CONTACT_AUXILIARY_PHONE_ACTION = 'set_register_data_contact_auxiliary_phone_action_form_management'
const SET_REGISTER_DATA_CONTACT_EMAIL_ASSOCIATED_ACTION = 'set_register_data_contact_email_associated_action_form_management'
const SET_REGISTER_DATA_SECRETS_IDENTIFIER_ACTION = 'set_register_data_secrets_identifier_action_form_management'
const SET_EMPLOY_POSITION_ACTION = 'set_employ_position_action_form_management'
const SET_MANAGEMENT_ACTION = 'set_management_action_form_management'
const SET_MANAGEMENT = 'update_management_action_form_management'

const initialStateFormManagement = {
    management_type: '',
    is_active: false,
    query: '',
    id: '',
    type: '',
    employ: {
        position: ''
    },
    register_data: {
        name: '',
        contact: {
            primary_phone: '',
            auxiliary_phone: '',
            email_associated: ''
        },
        secrets: {
            identifier: '',
        }
    }
}

const reducerFormManagement = (state = initialStateFormManagement, action) => {

    const { type, payload } = action

    switch (type) {
        case SET_MANAGEMENT:
            return{       
                ...payload
            }
        case SET_MANAGEMENT_TYPE_ACTION:
            return {
                ...state,
                management_type: payload
            }
        case SET_ACTIVE_ACTION:
            return {
                ...state,
                is_active: payload
            }
        case SET_QUERY_ACTION:
            return {
                ...state,
                query: payload
            }
        case SET_ID_ACTION:
            return {
                ...state,
                id: payload
            }
        case SET_EMPLOY_POSITION_ACTION: {
            return {
                ...state,
                employ: {
                    ...state.employ,
                    position: payload
                }
            }
        }
        case SET_REGISTER_DATA_NAME_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    name: payload
                }
            }
        }
        case SET_REGISTER_DATA_CONTACT_PRIMARY_PHONE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                        primary_phone: payload
                    }
                },
            }
        }
        case SET_REGISTER_DATA_CONTACT_AUXILIARY_PHONE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                        auxiliary_phone: payload
                    }
                },
            }
        }
        case SET_REGISTER_DATA_CONTACT_EMAIL_ASSOCIATED_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                        email_associated: payload
                    }
                },
            }
        }
        case SET_REGISTER_DATA_SECRETS_IDENTIFIER_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                    },
                    secrets: {
                        ...state.register_data.secrets,
                        identifier: payload
                    }
                },
            }
        }
        case SET_MANAGEMENT_ACTION:
            return payload
        default:
            return state
    }
}

const changeManagementType = (value) => {
    return { type: SET_MANAGEMENT_TYPE_ACTION, payload: value }
}

const changeActive = (value) => {
    return { type: SET_ACTIVE_ACTION, payload: value }
}

const changeQuery = (value) => {
    return { type: SET_QUERY_ACTION, payload: value }
}

const changeId = (value) => {
    return { type: SET_ID_ACTION, payload: value }
}

const changeNameManagement = (value) => {
    return { type: SET_REGISTER_DATA_NAME_ACTION, payload: value }
}

const changePrimaryPhone = (value) => {
    return { type: SET_REGISTER_DATA_CONTACT_PRIMARY_PHONE_ACTION, payload: value }
}

const changeAuxiliaryPhone = (value) => {
    return { type: SET_REGISTER_DATA_CONTACT_AUXILIARY_PHONE_ACTION, payload: value }
}

const changeEmailManagement = (value) => {
    return { type: SET_REGISTER_DATA_CONTACT_EMAIL_ASSOCIATED_ACTION, payload: value }
}

const changeIdentifierManagement = (value) => {
    return { type: SET_REGISTER_DATA_SECRETS_IDENTIFIER_ACTION, payload: value }
}

const changePositionManagement = (value) => {
    return { type: SET_EMPLOY_POSITION_ACTION, payload: value }
}

const changeManagement = (value) => {
    return { type: SET_MANAGEMENT_ACTION, payload: value }
}

const updateManagement = (value) => {
    return { type: SET_MANAGEMENT, payload: value }
}

export {
    initialStateFormManagement,
    reducerFormManagement,
    changeManagementType,
    changeActive,
    changeQuery,
    changeId,
    changeNameManagement,
    changePrimaryPhone,
    changeAuxiliaryPhone,
    changeEmailManagement,
    changeIdentifierManagement,
    changePositionManagement,
    changeManagement,
    updateManagement
}