import { produce } from "immer";
import moment from "moment";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingPopup } from "../../components/atoms/popup/Popup";
import StateButtonModalHook from "../../modal/StateButtonModalHook.jsx";
import { UID_AUTH } from "../../persistent-managment/key.persistent";
import { getValueByKey } from "../../persistent-managment/managment.persistent";
import { PopupSelectProperty } from "../../popups/PopupSelectProperty.jsx";
import { PopupTwoButtons } from "../../popups/PopupTwoButtons";
import { ShowMediaSource } from "../../popups/PopupsNewServices.jsx";
import { changeCurrentProperty } from "../../reducers/app.reducer";
import { initialStateFormProperty } from "../../reducers/form.property.reducer";
import {
  changeRequestService,
  changeResourceList,
  initialStateRequestService,
} from "../../reducers/request.service.reducer";
import { convertCodeToTitleByServices } from "../../res/codes.convert.services";
import {
  convertTypePriority,
  convertTypePropertyByCodeToName,
} from "../../res/convert.codes";
import {
  BASE_MENU_PATH,
  PRINCIPAL_PATH,
  PROPERTY_TYPE_PATH,
  REQUEST_SENT,
} from "../../router/routes";
import { createNewService } from "../../services-controller/http.cleinte.controller";
import { uploadMultipleEvidenceResources } from "../../services-controller/storage";
import NewServicesDetails from "./NewServicesDetails.jsx";

const NewServicesDetailsController = ({ history }) => {
  moment.locale("es");

  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return {
      request_service: state.request_service,
      app: state.app,
      form_user: state.form_user,
    };
  });

  const { request_service, app, form_user } = state;

  const buildCurrentProperty = (property) => {
    if (property === null) return initialStateFormProperty;
    else return property;
  };

  const {
    request: {
      selected_services,
      general_description,
      resources,
      priority,
      schedule: { date_start, time_start },
    },
  } = request_service;

  const { properties, current_property } = app;

  const {
    property_type,
    location: {
      space,
      address: { street_type, street, corner, number },
    },
    optional: { shopping_center, locale },
  } = buildCurrentProperty(current_property);

  //state

  const [removeMedia, setRemoveMedia] = useState(false);

  const [showMediaSource, setShowMediaSource] = useState(false);

  const [mediaSourceFile, setMediaSourceFile] = useState("");

  const [indexResource, setIndexResorunce] = useState(-1);

  const [selectProperty, setSelectProperty] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (current_property === null) callbackPushLoader();
  }, []);

  //general

  const buildAddress = () => {
    return `${street_type} ${street} # ${corner} - ${number} · ${space} ${locale} ${shopping_center}`;
  };

  const callbackPushLoader = () => history.push(PRINCIPAL_PATH);

  const callbackUpdateResourceList = (list) =>
    dispatch(changeResourceList(list));

  const buildSRCMedia = (type, url) => {
    return {
      type,
      url,
    };
  };

  const callbackAddFile = (e) => {
    const { type } = e;
    const typeFile = type.split("/")[0];

    const newResource = buildSRCMedia(typeFile, URL.createObjectURL(e));
    const updateResources = produce(resources, (draft) => {
      draft.push(newResource);
    });

    callbackUpdateResourceList(updateResources);
  };

  const callbackAddFileError = (e) => {};

  const callbackViewSource = (mediaFile) => {
    setMediaSourceFile(mediaFile);
    setShowMediaSource(true);
  };

  const callbackCloseMediaSource = () => {
    setShowMediaSource(false);
  };

  const callbackKeepMediaSource = () => {
    setRemoveMedia(false);
  };

  const callbackRemoveMediaSource = () => {
    if (indexResource !== -1) {
      const updatedSources = produce(resources, (draft) => {
        draft.splice(indexResource, 1);
      });

      callbackUpdateResourceList(updatedSources);
      setRemoveMedia(false);
    }

    setIndexResorunce(-1);
  };

  const deleteMediaSource = (index) => {
    setIndexResorunce(index);
    setRemoveMedia(true);
  };

  const callbackPreviusService = async () => {
    setShowLoader(true);
    try {
      const resourcesLink = await uploadMultipleEvidenceResources(resources);
      const service = {
        ...request_service,
        id: getValueByKey(UID_AUTH),
        query: current_property.query,
        request: {
          ...request_service.request,
          resources: resourcesLink,
        },
        metadata: {
          user: {
            ...form_user,
          },
          property: {
            ...current_property,
          },
        },
        service_providers: {
          aBDbhqzuRnbhdI4iElmgM7sYVpc2: true,
        },
        state: {
          ...request_service.state,
          service_creation_date: Date.now(),
        },
        messagesOnPhases: [],
      };

      await createNewService(service);

      let newObject = {
        ...initialStateRequestService,
      };

      delete newObject.selected_services;
      delete newObject.resources;

      dispatch(
        changeRequestService({
          ...newObject,
          request: {
            ...newObject.request,
            selected_services: [],
            resources: [],
          },
        })
      );
      history.push(`${BASE_MENU_PATH}${REQUEST_SENT}`);
    } catch (error) {
      console.log("error ", error);
    }

    setShowLoader(false);
  };

  const callbackRegisterPositive = () => history.push(`${PROPERTY_TYPE_PATH}`);

  const callbackPropertySelected = (property) => {
    dispatch(changeCurrentProperty(property));
    setSelectProperty(false);
  };

  const callbackChangePropertyOption = () => setSelectProperty(true);

  const buildListServicesToString = (list) => {
    let aux = "";

    for (let service of list) {
      aux = aux + ` ${convertCodeToTitleByServices(service)} -`;
    }
    return aux.slice(0, aux.length - 1);
  };

  const currentDate = (date) => moment(date).format("dddd, D MMMM");

  return (
    <>
      <NewServicesDetails
        defaultValueGeneralDescription={general_description}
        listResources={resources}
        callbackAddFile={callbackAddFile}
        callbackAddFileError={callbackAddFileError}
        callbackViewSource={callbackViewSource}
        deleteMediaSource={deleteMediaSource}
        callbackPreviusService={callbackPreviusService}
        callbackChangePropertyOption={callbackChangePropertyOption}
        propertyTye={convertTypePropertyByCodeToName(property_type)}
        listServicesSelected={buildListServicesToString(selected_services)}
        servicePriority={convertTypePriority(priority)}
        addressProperty={buildAddress()}
        dateStart={currentDate(date_start)}
        timeStart={time_start}
      />
      {/* remove media source */}
      {removeMedia ? (
        <StateButtonModalHook
          component={PopupTwoButtons}
          hook={[removeMedia, setRemoveMedia]}
          object={{
            callbackBack: callbackKeepMediaSource,
            callbackRemove: callbackRemoveMediaSource,
            popupText: "¿Desea eliminar el archivo de forma permanente?",
          }}
        />
      ) : null}
      {/* show media source */}
      {showMediaSource ? (
        <StateButtonModalHook
          component={ShowMediaSource}
          hook={[showMediaSource, setShowMediaSource]}
          object={{
            callbackCloseMediaSource: callbackCloseMediaSource,
            mediaSourceFile: mediaSourceFile,
          }}
        />
      ) : null}
      {/* select property */}
      {selectProperty ? (
        <StateButtonModalHook
          component={PopupSelectProperty}
          hook={[selectProperty, setSelectProperty]}
          object={{
            propertyArr: properties,
            callbackNewProperty: callbackRegisterPositive,
            callbackPropertySelected: callbackPropertySelected,
          }}
        />
      ) : null}
      <LoadingPopup state={showLoader} />
    </>
  );
};

export default withRouter(NewServicesDetailsController);
