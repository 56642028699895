import React, { useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "./loadingImg.css";
import PDF_SVG from "../../assets/icons/svg/pdf-svg-white.svg";
import EXCEL_SVG from "../../assets/icons/png/excelSVG.png";
import { arePropsEqual } from "../../res/utils";
const LazyLoad = ({
  resource,
  Component,
  openModal,
  scrollPosition,
  type,
  openMediaFilePopup,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const renderIcon = () => {
    if (type === "pdf") {
      return (
        <div className="imgAnimatePdf-Container">
          <div className="imgAnimatePdf">
            <img
              src={PDF_SVG}
              alt="PDF"
              className="pdf-image"
              onLoad={handleImageLoad()}
            />
          </div>
        </div>
      );
    } else if (type === "excel") {
      return (
        <div className="imgAnimatePdf-Container">
          <div className="imgAnimatePdf">
            <img
              src={EXCEL_SVG}
              alt="Excel"
              onLoad={handleImageLoad()}
              className="pdf-image"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="spinner-container">
          <div className="spinner" onLoad={handleImageLoad()}></div>
        </div>
      );
    }
  };

  return (
    <>
      {imageLoaded ? (
        <LazyLoadComponent
          openModal={openModal}
          scrollPosition={scrollPosition}
          type={type}
          openMediaFilePopup={openMediaFilePopup}
        >
          <Component url={resource.url} name={resource.name} />
        </LazyLoadComponent>
      ) : (
        renderIcon()
      )}
    </>
  );
};

export default React.memo(LazyLoad, arePropsEqual);
