const firebaseConfig = {
  apiKey: "AIzaSyBggE-GRVTXSSIO2VXBjznh6fAQS-dPF2o",
  authDomain: "justo-pago.firebaseapp.com",
  databaseURL: "https://justo-pago.firebaseio.com",
  projectId: "justo-pago",
  storageBucket: "justo-pago.appspot.com",
  messagingSenderId: "955257645816",
  appId: "1:955257645816:web:319e21b9e28ea9e9f1e90d",
  measurementId: "G-E1F68PX40Q"
};

export default firebaseConfig
