import { getFormatedDate } from "utils/date";
const ReadDetail = ({ message }) => {
  return (
    <>
      {message.admin_read && (
        <span>
          {message.admin_lector ? message.admin_lector : "JustoPago"}:{" "}
          {getFormatedDate(message.admin_read_date)}
        </span>
      )}
      {message.sp_read && (
        <span>Residente: {getFormatedDate(message.sp_read_date)}</span>
      )}
      {message.client_read && (
        <span>Cliente: {getFormatedDate(message.client_read_date)}</span>
      )}
    </>
  );
};

export { ReadDetail };
