import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input, Progress, Skeleton, Space, Tag } from "antd";
import IconHome from "assets/icons/svg/home.svg"
//import NavBar from "components/Navbar/NavBar";
import {
  configClass,
  configTitleIcon,
} from "components/molecules/container/Container";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { capitalizeWord } from "res/utils";
import debounce from "utils/debounce";
import "./AllServicesAdded.css";
import ImageProfileUser from "./ImageProfileUser";
// import GoBackBtn from "components/GoBackBtn/GoBackBtn";

export default function AllServicesAdded() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const serviceList = useSelector(state => state.services_list)

/*   const servicesV2 = useMemo(() => {
    return serviceList.servicesList.filter(item => item.version === 2)
  }, [serviceList.servicesList]) */

  const onSearch = (value) => {
    let deb = debounce((value) => {
      setName(value)

    }, 1000);
    deb(value)
    setLoading(true)
  }

  const filteredServices = useMemo(
    () => {

      let filter = serviceList.servicesList.filter((item) => {
        const nameUser = item.metadata?.user?.register_data?.name;
        if (nameUser?.toLowerCase()?.includes(name.toLowerCase())) {
          return item;
        }
        return null;
      });
      setLoading(false)
      return filter;
    }, [serviceList, name]
  )

  return (
    <main className="new-advance">
      <section className="new-advance-body">
        <header className="filter-wrapper">
          {/* <GoBackBtn /> */}

          <Input
            placeholder="Buscar Servicio"
            onChange={(event) => {
              onSearch(event.target.value);
            }}
            prefix={<SearchOutlined />}
            className="new-advance-filter"
            size="large"
            type="search"
            allowClear
          />
        </header>

        <section className="new-advance-list">
          {(loading) && (
            <section
              className="none-list"
              style={{ display: "grid", gap: "24px", gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", overflowY: "auto" }}
            >
              {Array.from({ length: 6 }).map((_, i) => (
                <Skeleton.Node
                  active
                  style={{ width: "100%", height: "12rem" }}
                  key={i}
                />
              ))}
            </section>
          )}

          {!filteredServices.length && (
            <section className="none-list">
              <Empty
                description="No hay servicios para mostrar"
                style={{ marginBlock: "auto", width: "100%" }}
              />
            </section>
          )}

          {Boolean(filteredServices.length && !loading) &&
            filteredServices
              // .filter((item) => item.version === 2)
              .map((item) => {

                const { metadata, unique_key, state, id } = item;

                return (
                  <article
                    key={unique_key}
                    className="list-item"
                    onClick={() => {
                      item.version === 2
                        ? history.push(`/new-advance-detail/${unique_key}`)
                        : history.push(`/services/bitacle/${unique_key}`);
                    }}
                  >
                    <ImageProfileUser userId={id} />

                    <span className="item-title">
                      <h3>
                        {capitalizeWord(
                          metadata?.user?.register_data?.name.toLowerCase()
                        )}
                      </h3>
                    </span>

                    <Space>
                      {item.version === 2 && (
                        <span className="item-location">
                          <img src={IconHome} alt="home" height={30} />
                          {`${metadata?.property?.location?.apt_number}-${metadata?.property?.location?.tower}`}
                        </span>
                      )}
                    </Space>

                    <span
                      className={`${configClass(state?.stage)} item-status`}
                    >
                      {configTitleIcon(state?.stage)}
                    </span>

                    <Progress
                      percent={Math.round(
                        state?.globalState?.progress?.progress * 100
                      )}
                      size="small"
                      className="item-progress-bar"
                    />
                    <Tag color="purple" className="id-tag">
                      {unique_key}
                    </Tag>
                  </article>
                );
              })}
        </section>
      </section>

    </main >
  );
}
