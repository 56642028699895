import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { convertSnapshotToList } from "../../../callbacks/list";
import { updateServicesList } from "../../../reducers/servicesList.reducer.js";
import { DETAILS } from "../../../router/routes";
import {
  getAllServicesInProgress,
  getAllServicesInit,
} from "../../../services-controller/database";
import Services from "./Services.jsx";

const LABEL_IN_PROGRESS = "En curso";
const LABEL_DONE = "Pasados";

const ServicesController = ({ history }) => {
  const [servicesListInit, setServicesListInit] = useState([]);
  const [servicesListInProgress, setServicesListInProgress] = useState([]);
  const [forAprove, setForAprove] = useState(false);
  const { servicesList } = useSelector((state) => state.services_list);

  const { messages_list, form_user, form_service_provider } = useSelector(
    (state) => {
      return {
        messages_list: state.messages_list,
        form_user: state.form_user,
        form_service_provider: state.form_service_provider,
      };
    }
  );
  const dispatch = useDispatch();
  // state

  useEffect(() => {
    const listenerServicesInit = getAllServicesInit((snapshot) =>
      updateList(snapshot, setServicesListInit)
    );

    const listenerServicesProgress = getAllServicesInProgress((snapshot) =>
      updateList(snapshot, setServicesListInProgress)
    );

    return () => {
      listenerServicesInit();

      listenerServicesProgress();
    };
  }, []);

  const constructorList = (servicesListInit, servicesListInProgress) => {
    const servicesListUpdated = [
      [
        LABEL_IN_PROGRESS,
        [
          ...servicesListInit,
          ...servicesListInProgress.filter(
            (service) =>
              service.state.stage === 0 ||
              service.state.stage === 1 ||
              service.state.stage === 2 ||
              service.state.stage === 4 ||
              service.state.stage === 5 ||
              service.state.stage === 7 ||
              service.state.stage === 8 ||
              service.state.stage === 9 ||
              service.state.stage === 10 ||
              service.state.stage === 11 ||
              service.state.stage === 12
          ),
        ],
      ],
      [
        LABEL_DONE,
        [
          ...servicesListInProgress.filter(
            (service) =>
              service.state.stage === 3 ||
              service.state.stage === 6 ||
              service.state.stage === 13
          ),
        ],
      ],
    ];
    return servicesListUpdated;
  };

  // general
  useEffect(() => {
    const servicesListUpdated = constructorList(
      servicesListInit,
      servicesListInProgress
    );
    dispatch(updateServicesList(servicesListUpdated));
  }, [servicesListInit, servicesListInProgress]);

  useEffect(() => {
    if (servicesList[0][1].length > 0) {
      setForAprove(
        servicesList[0][1].find((service) => service.state.stage === 0)
      );
    } else if (servicesList[0][1].length === 0) {
      setForAprove(true);
    }
  }, [servicesList]);
  const updateList = (snapshot, dispatch) => {
    if (typeof snapshot === "object") {
      const auxList = convertSnapshotToList(snapshot.val());
      dispatch(auxList);
    }
  };

  const navigatorService = (id, stage) => {
    history.push(`${DETAILS}/${id}/${stage}`);
  };

  return (
    <Services
      navigatorService={navigatorService}
      servicesList={servicesList}
      forAprove={forAprove}
    />
  );
};

export default withRouter(ServicesController);
