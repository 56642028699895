export const countUnreadNotifications = (notifications) => {
  return notifications.filter((notification) => !notification.read).length;
};

const parseDate = (dateString) => {
  var parts = dateString.split(/[\s\/:]/);
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1;
  var year = parseInt(parts[2], 10);
  var hour = parseInt(parts[3], 10);
  var minutes = parseInt(parts[4], 10);
  var am_pm = parts[5].toUpperCase();

  if (am_pm === "PM") {
    hour += 12;
  }

  return new Date(year, month, day, hour, minutes);
};

export const pasedTime = (date) => {
  var dateProportionate = parseDate(date);
  var actualDate = new Date();
  var difference = actualDate - dateProportionate;
  var seconds = Math.floor(difference / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  if (days > 0) {
    return days + " d";
  } else if (hours > 0) {
    return hours + (hours === 1 ? " h" : " hrs");
  } else if (minutes > 0) {
    return minutes + " m";
  } else {
    return "ahora";
  }
};
