import React, { useEffect } from "react";
import { withRouter, useParams, Redirect } from "react-router-dom";
import {
  MENU_PATH,
  HOME,
  SERVICES,
  NOTIFICATIONS,
  PROFILE,
  NEW_SERVICE,
  SERVICES_DETAILS,
  REQUEST_SENT,
  SCHEDULE_SERVICE,
  PRINCIPAL_PATH,
} from "../../router/routes";
import Notifications from "../menu/notifications/Notifications.jsx";
import HomeController from "../menu/home/HomeController.jsx";
import ProfileController from "../menu/profile/ProfileController.jsx";
import ServicesController from "../menu/services/ServicesController.jsx";
import NewServicesController from "../newServices/NewServicesController.jsx";
import NewServicesDetailsController from "../newServices/NewServicesDetailsController.jsx";
import ServiceSent from "../newServices/ServiceSent.jsx";
import NewServicesScheduleController from "../newServices/NewServicesScheduleController.jsx";
import { existUser } from "../../services-controller/auth";

const BuildView = (type) => {
  switch (type) {
    //tabs
    case HOME:
      return <HomeController />;
    case SERVICES:
      return <ServicesController />;
    case NOTIFICATIONS:
      return <Notifications />;
    case PROFILE:
      return <ProfileController />;
    //new service
    case NEW_SERVICE:
      return <NewServicesController />;
    case SCHEDULE_SERVICE:
      return <NewServicesScheduleController />;
    case SERVICES_DETAILS:
      return <NewServicesDetailsController />;
    //required refactor not is view is pop-up
    case REQUEST_SENT:
      return <ServiceSent />;
    default:
      return <Redirect to={MENU_PATH} />;
  }
};

const MenuController = ({ history }) => {
  let { id } = useParams();

  useEffect(() => {
    if (!existUser()) history.push(PRINCIPAL_PATH);
  }, [history]);

  return BuildView(id);
};

export default withRouter(MenuController);
