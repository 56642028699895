import React from 'react'

const DateFormat = ({unexpected, dateFormated}) => {
  return (
    <div className={`item-content ${unexpected ? 'item-content-unexpected' : ''} `}>
      {dateFormated === 'Fecha inválida' ? '6:00 AM' : dateFormated}
      <div className=''></div>
    </div>
  )
}

export default DateFormat