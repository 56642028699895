import {
  ADMIN_ASISTANT,
  ADMIN_COORDINATOR,
  ARCHITECT_DESIGN,
  ASSISTANT_TYPE_PATH,
  CARPENTRY_ASSISTANT,
  CARPENTRY_COORDINATOR,
  CARPENTRY_LEADER,
  CFO,
  COMERCIAL_ASISTANT,
  COMERCIAL_COORDINATOR,
  COMERCIAL_DIRECTOR,
  COO,
  CSO,
  DESIGN_AUXILIARY,
  DESIGN_COORDINATOR,
  DOCUMENTATION_ASISTANT,
  HUMAN_RESOURCES,
  OFFICIAL_TYPE_PATH,
  OPERATION_COORDINATOR,
  PLANEATION_ASISTANT,
  PLANEATION_AUXILIARY,
  PLANEATION_COORDINATOR,
  POST_SOLD_AUXILIARY,
  RESIDENT,
  SUPERVISOR_TYPE_PATH,
} from "../router/routes";

const OperationEmployes = [
  COO,
  OPERATION_COORDINATOR,
  CARPENTRY_COORDINATOR,
  RESIDENT,
  SUPERVISOR_TYPE_PATH,
  OFFICIAL_TYPE_PATH,
  ASSISTANT_TYPE_PATH,
  CARPENTRY_LEADER,
  CARPENTRY_ASSISTANT,
];

const planeationEmployes = [
  CSO,
  PLANEATION_COORDINATOR,
  PLANEATION_ASISTANT,
  DOCUMENTATION_ASISTANT,
  PLANEATION_AUXILIARY,
  POST_SOLD_AUXILIARY,
];

const designeEmployes = [
  DESIGN_COORDINATOR,
  ARCHITECT_DESIGN,
  DESIGN_AUXILIARY,
];

const comercialEmployes = [
  COMERCIAL_DIRECTOR,
  COMERCIAL_COORDINATOR,
  COMERCIAL_ASISTANT,
];

const adminEmployes = [CFO, ADMIN_COORDINATOR, ADMIN_ASISTANT, HUMAN_RESOURCES];

const areaList = [
  "Selecciona un área",
  "Operaciones",
  "Planeación",
  "Diseño",
  "Comercial",
  "Administración",
];

export {
  OperationEmployes,
  adminEmployes,
  areaList,
  comercialEmployes,
  designeEmployes,
  planeationEmployes,
};
