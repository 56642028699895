import React from 'react'
import PropertyType from './PropertyType.jsx'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changePropertyType } from '../../reducers/app.reducer'
import { changePropertyType as changePropertyTypeForm } from '../../reducers/form.property.reducer'
import { BASE_PROPERTY_TYPE_PATH } from '../../router/routes'

const PropertyTypeController = ({ history }) => {

    const dispatch = useDispatch()
    const state = useSelector(state => state.app)
    const app = state

    const {
        property_type
    } = app

    const callbackChangePropertyType = (e) => {
        dispatch(changePropertyType(e.target.id))
        dispatch(changePropertyTypeForm(e.target.id))
    }

    const callbackPushNavigator = () => {
        history.push(`${BASE_PROPERTY_TYPE_PATH}${property_type}`)
        // RegisterPropHouse
        // RegisterApartment
        // RegisterLocal
        // RegisterWarehouse
        // RegisterCondominium
        // RegisterOffice
        // RegisterNewPersonal
        // RegisterOtherProperty
    }
    return <PropertyType
        callbackChangePropertyType={callbackChangePropertyType}
        PropertyType={property_type}
        callbackPushNavigator={callbackPushNavigator}
    />
}

export default withRouter(PropertyTypeController)
