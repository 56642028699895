import React from "react";
import JP_SUB_PICTURE from "assets/icons/png/jp-sub-picture.png";

import { Image } from "antd";

const ImageProfile = ({ isMine, thubmnail }) => {
  return (
    <div className={`comment-photo-container${isMine}`}>
      <Image
        className={`comment-photo ${thubmnail !== "" ? "" : ""}`}
        src={thubmnail !== "" ? thubmnail : JP_SUB_PICTURE}
        alt="perfil-profile"
        style={{ width: "44px", height: "44px" }}
      />
    </div>
  );
};

export default ImageProfile;
