import React from "react";
import MessageController from "./Message/MessageController";

const MessagesContainer = ({ messageList }) => {
  const lasMessageRef = React.useRef(null);
  return (
    <div className="message-container">
      {messageList.map((message, key) => {
        return <MessageController key={key} message={message} />;
      })}
      <div className="last-space-message" ref={lasMessageRef}></div>
    </div>
  );
};

export default MessagesContainer;
