import { newMessageCheck } from "helpers/helpers.comment";
import React from "react";
import { useSelector } from "react-redux";

const NewMessageAlert = ({ message }) => {
  const userType = useSelector((state) => state.auth.userType);
  const userId = useSelector((state) => state.auth.user.id);
  const messageEmisor = message?.emisor?.id;
  const isInmediatlySendedMessage = messageEmisor === userId;

  return (
    <span
      className={
        isInmediatlySendedMessage
          ? ""
          : newMessageCheck(message, userType)
          ? "time-container-pulse"
          : ""
      }
    ></span>
  );
};

export default NewMessageAlert;
