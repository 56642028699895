import React from 'react'
import { withRouter } from 'react-router-dom'
import { AppbarProfile } from '../../atoms/appbar/Appbar'
import { MenuRoundedButton } from '../../atoms/button/Button'
import './profileTemplate.css'
import { ProfileButton } from '../../atoms/button/Button'


const Profile = ({
    thumbnail,
    name,
    userType,
    callbackSelectImage,
    callbackSelectImageError,
    appbarButtonsContent,
    menuButtonsContent,
    navbar,
    score

}) => {

    return (
        <div className='profile-container'>

            <div className='appbar-profile-container'>
                <AppbarProfile
                    callbackSelectImage={callbackSelectImage}
                    callbackSelectImageError={callbackSelectImageError}
                    options={
                        <>
                            {
                                appbarButtonsContent.map((obj, index) =>
                                    <ProfileButton
                                        key={index}
                                        type={obj.type}
                                        info={obj.info} />
                                )
                            }
                        </>
                    }
                    thumbnail={thumbnail}
                    name={name}
                    userType={userType}
                    score={score}
                />
            </div>


            <div className='options-profile-container'>
                {
                    menuButtonsContent.map((obj, index) => {
                        const dontShowObjects = ['Encargados e inquilinos', 'Garantias', 'Invitación']

                        if (!dontShowObjects.includes(obj.txt)) {
                            return (
                                <MenuRoundedButton
                                    key={index}
                                    icon={obj.icon}
                                    txt={obj.txt}
                                    callback={obj.callback}
                                />
                            )
                        }
                    }
                    )
                
                }

            </div>

            {navbar}

        </div>
    )
}

export default withRouter(Profile)