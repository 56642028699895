import put from "../services-firebase/storage";
import uuidv5 from "uuid/v5";
import { sendPdfResource } from "./http.cleinte.controller";

const BUCKET_THUMBNAIL = "thumbnails";
const BUCKET_EVIDENCE_RESOURCES = "evidence_resources";
const BUCKET_AUDIO_RESOURCES = "audio_resources";
const BUCKET_EXCEL_RESOURCES = "excel_resources";
const IMAGE_EXTENSION = "png";
const VIDE_EXTENSION = "mp4";
const AUDIO_EXTENSION = "mp3";
const PDF_EXTENSION = "pdf";
const EXCEL_EXTENSION = "xlsx";

const uploadFile = async (id, bucket, imageExtension, file) => {
  const base64 = file.split("base64,").pop();
  const result = await put(
    bucket,
    id,
    imageExtension,
    base64,
    () => {
      console.log("paused");
    },
    () => {
      console.log("running");
    },
    () => {
      console.log("error");
    }
  );

  return result;
};

const uploadImageThumbnail = async (id, file) =>
  await uploadFile(id, BUCKET_THUMBNAIL, IMAGE_EXTENSION, file);

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const convertTypeResource = (type) => {
  switch (type) {
    case "image":
      return IMAGE_EXTENSION;
    case "video":
      return VIDE_EXTENSION;
    case "audio":
      return AUDIO_EXTENSION;
    case "pdf":
      return PDF_EXTENSION;
    case "excel":
      return EXCEL_EXTENSION;
    default:
      throw new Error("File type no detected");
  }
};
const uploadMultipleEvidenceResources = async (list = []) => {
  const aux = [];

  for (let resource of list) {
    const { type, url } = resource;
    if (url !== undefined) {
      const file = await fetch(url).then((r) => r.blob());

      //const { name } = file
      //const newName = name.split('.')[0]
      const base64File = await toBase64(file);
      const uid = uuidv5(new Date().toLocaleString(), uuidv5.URL);

      const urlFile = await uploadFile(
        uid,
        BUCKET_EVIDENCE_RESOURCES,
        convertTypeResource(type),
        base64File
      );
      URL.revokeObjectURL(url);

      const { code } = urlFile;
      if (code === 200) {
        const { url } = urlFile;
        aux.push({ url, name: uid, type });
      }
    } else {
      aux.push(resource);
    }
  }
  return aux;
};

const uploadAudioResource = async (file) => {
  const base64File = await toBase64(file);
  const uid = uuidv5(new Date().toLocaleString(), uuidv5.URL);
  const urlFile = await uploadFile(
    uid,
    BUCKET_AUDIO_RESOURCES,
    convertTypeResource("audio"),
    base64File
  );
  const { code } = urlFile;
  if (code === 200) {
    const { url } = urlFile;
    return { url, name: uid, type: "audio" };
  }
};

const uploadMultiplePDFs = async (pdfList, serviceId) => {
  const aux = [];

  for (let pdfFile of pdfList) {
    const { name, url } = pdfFile;
    const file = await fetch(url).then((r) => r.blob());
    const arrayBuffer = await toBase64(file);
    const realFile = arrayBuffer.split("base64,").pop();
    const urlFile = await sendPdfResource(serviceId, name, realFile);
    URL.revokeObjectURL(url);

    const { code } = urlFile;
    if (code === 200) {
      const { url } = urlFile;
      aux.push({ url, name: name, type: "pdf" });
    }
  }
  return aux;
};

const uploadMultipleExcels = async (excelList, serviceId) => {
  const aux = [];

  for (let excelFile of excelList) {
    const { name, url } = excelFile;
    const file = await fetch(url).then((r) => r.blob());
    const arrayBuffer = await toBase64(file);

    const realFile = arrayBuffer.split("data:application/").pop();

    const urlFile = await uploadFile(
      name,
      BUCKET_EXCEL_RESOURCES,
      EXCEL_EXTENSION,
      realFile
    );

    URL.revokeObjectURL(url);

    const { code } = urlFile;
    if (code === 200) {
      const { url } = urlFile;
      aux.push({ url, name: name, type: "excel" });
    }
  }
  return aux;
};

export {
  uploadImageThumbnail,
  uploadMultipleEvidenceResources,
  toBase64,
  uploadFile,
  uploadAudioResource,
  uploadMultiplePDFs,
  uploadMultipleExcels,
};
