import React from 'react'
import MainApp from './MainApp.jsx'
import { isMobile } from "react-device-detect";

const NodeApp = () => {
    const clientHeight = document.documentElement.clientHeight

    document.documentElement.style.setProperty('--vh', `${isMobile ? clientHeight - 190 : clientHeight - 250}px`);
    document.documentElement.style.setProperty('--v-services', `${isMobile ? clientHeight - 160 : clientHeight - 178}px`);
    document.documentElement.style.setProperty('--v-auth', `${isMobile ? clientHeight - 130 : clientHeight - 150}px`);
    document.documentElement.style.setProperty('--v-form-property', `${isMobile ? clientHeight - 180 : clientHeight}px`);
    return <MainApp />
}
export default NodeApp