import React, { useState } from "react";
import "./popup.css";
import { PopupButton } from "../button/Button";
import { WarpSoruce } from "../../atoms/media/MediaSource";
import { PrimaryButton } from "../button/Button";
import { SearchIconInput } from "../input/Input";
import { PropertyInfoCard } from "../card/Card";
import SEARCH_ICON_SVG from "../../../assets/icons/svg/search.svg";
import FINGER_ICON_SVG from "../../../assets/icons/svg/finger-point.svg";
import LOGO_ICON_SVG from "../../../assets/icons/svg/otros.svg";
import CLOCK_ICON_SVG from "../../../assets/icons/svg/clock.svg";
import CALENDAR_ICON_SVG from "../../../assets/icons/svg/calendar.svg";
import FROWN_ICON_SVG from "../../../assets/icons/svg/frown.svg";
import { BaseDropdown } from "../dropdown/Dropdown";
import { SCHEDULE_RANGE } from "../../../res/schedule.range";
import BELL_ICON_SVG from "../../../assets/icons/svg/bell.svg";
import PsNotifications from "../../../views/services-provider/ps-notifications/PsNotifications";
import Notifications from "../../../views/menu/notifications/Notifications";

const PopupMenuNavbar = ({ show, values }) => {
  return (
    <div className={`containerValues ${show ? "show" : ""}`}>
      {values.map((value, key) => (
        <div
          key={`item-${key}`}
          className={`popupItems ${key !== 0 ? "borderTopWhite" : ""}`}
        >
          {typeof value === "function" ? value() : value}
        </div>
      ))}

      <div className="bottomTriangle"></div>
    </div>
  );
};

const PopupSeguimientoMenuNavbar = ({ show, values }) => {
  return (
    <div className={`container-values-seguimiento ${show ? "show" : ""}`}>
      {values.map((value, key) => (
        <div
          key={key}
          className={`popupItems ${key !== 0 ? "borderTopWhite" : ""}`}
          onClick={value[1]}
        >
          {value[0]}
        </div>
      ))}
      <div className="seguimiento-bottom-triangle"></div>
    </div>
  );
};

const BasePopup = ({ content }) => {
  return (
    <div className="popup">
      <div className="containerPopup">{content}</div>
    </div>
  );
};

const AlertPopup = ({ ...rest }) => {
  return (
    <BasePopup
      content={
        <div className="alertPopup">
          <div>{rest.object.message}</div>
        </div>
      }
    />
  );
};

//start refactor, is equals to OneButtonsPopup and IconPopup <add icon> <enum: simble - multiple>
const TwoButtonsPopup = ({
  text,
  btnOneText,
  btnTwoText,
  btnOneColor,
  btnTwoColor,
  callbackOne,
  callbackTwo,
}) => {
  return (
    <BasePopup
      content={
        <React.Fragment>
          <div className="textButtonPopup">{text}</div>
          <div className="buttonsContainer">
            <PopupButton
              text={btnOneText}
              color={btnOneColor}
              callback={callbackOne}
            />
            <PopupButton
              text={btnTwoText}
              color={btnTwoColor}
              callback={callbackTwo}
            />
          </div>
        </React.Fragment>
      }
    />
  );
};

const OneButtonsPopup = ({ text, btnOneText, btnOneColor, callbackOne }) => {
  return (
    <BasePopup
      content={
        <React.Fragment>
          <div className="textButtonPopup">{text}</div>
          <div className="oneButtonContainer">
            <PopupButton
              text={btnOneText}
              color={btnOneColor}
              callback={callbackOne}
            />
          </div>
        </React.Fragment>
      }
    />
  );
};

const IconPopup = ({ text, btnText, btnColor, callback, icon }) => {
  return (
    <BasePopup
      content={
        <React.Fragment>
          <img src={icon} className="iconPopup" alt={text} />
          <div className="icon-popup-text">{text}</div>
          <div className="newButtonIconPopup">
            <PopupButton text={btnText} color={btnColor} callback={callback} />
          </div>
        </React.Fragment>
      }
    />
  );
};
//end -refactor

const IconTwoButtonsPopup = ({
  text,
  btnOneText,
  btnOneColor,
  callbackOne,
  btnTwoText,
  btnTwoColor,
  callbackTwo,
  icon,
}) => {
  return (
    <BasePopup
      content={
        <React.Fragment>
          <img src={icon} className="iconPopup" alt={text} />
          <div className="icon-popup-text">{text}</div>
          <div className="buttonsContainer">
            <PopupButton
              text={btnOneText}
              color={btnOneColor}
              callback={callbackOne}
            />
            <PopupButton
              text={btnTwoText}
              color={btnTwoColor}
              callback={callbackTwo}
            />
          </div>
        </React.Fragment>
      }
    />
  );
};

const MediaFilePopup = ({ btnText, btnColor, callback, src }) => {
  const { type, url, file } = src;
  return (
    <BasePopup
      content={
        <React.Fragment>
          <WarpSoruce type={type} file={file} url={url} big={true} />
          <div className="buttonIconPopup">
            <PopupButton text={btnText} color={btnColor} callback={callback} />
          </div>
        </React.Fragment>
      }
    />
  );
};

const LoadingPopup = ({ state = false }) => {
  return state ? (
    <div className="loader-container">
      <div className="loader" style={{ backgroundColor: "black" }} />
    </div>
  ) : null;
};

const SelectPropertyPopup = ({
  callbackSearch,
  query = "",
  arr,
  callbackNewProperty,
  callbackPropertySelected,
}) => {
  const generateList = (list) =>
    list.filter((item) => {
      if (query === "") return true;
      else return item.nickname.toLowerCase().includes(query.toLowerCase());
    });

  return (
    <div className="spp-container">
      <div className="search-bar-container">
        <SearchIconInput
          callback={callbackSearch}
          placeholder="Nombre del Inmueble"
          icon={SEARCH_ICON_SVG}
          defaultValue={query}
        />
      </div>

      <div className="spp-info-container">
        <img src={FINGER_ICON_SVG} className="spp-info-icon" alt="" />
        {generateList(arr).length === 0 ? (
          <div className="spp-info-text">Registre un inmueble</div>
        ) : (
          <div className="spp-info-text">Seleccione un inmueble</div>
        )}
      </div>

      <div className="properties-container">
        {generateList(arr).map((obj, index) => (
          <div className="info-prop-container" key={index}>
            {/*
                            <div className='prop-type'>
                                {obj.nickname}
                            </div>
                            */}
            <div className="ssp-card-container">
              <PropertyInfoCard
                callback={callbackPropertySelected}
                object={obj}
                id={index}
                name={obj.nickname}
                icon={obj.property_type}
                direccion={`${obj.location.address.street_type} ${obj.location.address.street} # ${obj.location.address.corner} - ${obj.location.address.number} `}
              />
            </div>
          </div>
        ))}

        {generateList(arr).length === 0 && query !== "" ? (
          <div className="no-assistants-container">
            <img src={FROWN_ICON_SVG} className="no-entries-icon" alt="" />
            <div className="no-properties-title">{`Actualmente no tienes inmuebles registrados.`}</div>
          </div>
        ) : null}
      </div>

      <div className="new-property-button">
        <PrimaryButton
          txt="Registrar nuevo inmueble"
          callback={callbackNewProperty}
        />
      </div>
    </div>
  );
};

const ServiceItem = ({ callbackSelectItem, item, index }) => {
  const [selected, setSelected] = useState(false);
  const selectItem = () => {
    setSelected(!selected);
    callbackSelectItem(index);
  };

  return (
    <div
      className={`psi-item ${selected ? "psi-item-selected" : ""}`}
      onClick={selectItem}
    >
      Item {index + 1}: {item.damage_type}
    </div>
  );
};

const PopupSelectItems = ({
  callbackSelectItem,
  callbackAccept,
  itemList,
  enableApproval,
  callbackScheduleStart,
  callbackScheduleEnd,
  callbackSelectDate,
  selectedDate,
}) => {
  return (
    <BasePopup
      content={
        <div className="popup-select-items-container">
          <div className="psi-icon-container">
            <img src={LOGO_ICON_SVG} className="psi-icon" alt="" />
          </div>

          <div className="psi-text">
            Seleccione los items que desea aprobar, la fecha y la hora para
            comenzar con la reparación:
          </div>

          <div className="psi-items-container">
            {itemList
              .slice()
              .sort((a, b) => b?.position - a?.position)
              .reverse()
              .map((item, index) => (
                <ServiceItem
                  key={index}
                  item={item}
                  index={index}
                  callbackSelectItem={callbackSelectItem}
                />
              ))}

            <div className="categoria-container">
              <div className="valid-time-text">
                <div>
                  <img
                    src={CALENDAR_ICON_SVG}
                    id="new-service-icon"
                    alt={"POINTING_ICON_SVG"}
                  />
                </div>
                <div> Seleccionar fecha: </div>
              </div>
            </div>

            <div className="psi-item" onClick={callbackSelectDate}>
              {selectedDate}
            </div>

            <div className="categoria-container">
              <div className="valid-time-text">
                <div>
                  <img
                    src={CLOCK_ICON_SVG}
                    id="new-service-icon"
                    alt={"POINTING_ICON_SVG"}
                  />
                </div>
                <div> Seleccionar horario: </div>
              </div>
            </div>

            <div className="time-entry-container-client">
              <div className="new-time-entry">
                <div className="time-entry-text">Desde:</div>

                <div className="new-entry-dropdown-container">
                  <BaseDropdown
                    defaultSelected={"Seleccionar hora"}
                    callback={callbackScheduleStart}
                    items={SCHEDULE_RANGE.map((state, index) => (
                      <option value={state} key={index} className="rol-option">
                        {state}
                      </option>
                    ))}
                    style="new-entry-dropdown"
                  />
                </div>
              </div>

              <div className="new-time-entry">
                <div className="time-entry-text">Hasta:</div>

                <div className="new-entry-dropdown-container">
                  <BaseDropdown
                    defaultSelected={"Seleccionar hora"}
                    callback={callbackScheduleEnd}
                    items={SCHEDULE_RANGE.map((state, index) => (
                      <option value={state} key={index} className="rol-option">
                        {state}
                      </option>
                    ))}
                    style="new-entry-dropdown"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={`psi-button ${
              enableApproval ? "psi-button-active" : ""
            }`}
            onClick={callbackAccept}
          >
            Generar aprobación
          </div>
        </div>
      }
    />
  );
};

const CheckboxReason = ({ reason, index, callbackSelectReason }) => {
  const [selectedBox, setSelected] = useState(false);
  const selectItem = () => {
    setSelected(!selectedBox);
    callbackSelectReason(reason);
  };

  return (
    <div className={`checkbox-item ${selectedBox ? "selectBoxContainer" : ""}`}>
      <input
        className={`checkbox-box ${selectedBox ? "selectBoxActive" : ""}`}
        type="checkbox"
        onClick={selectItem}
      />
      <div
        className={`admin-filter-input ${
          selectedBox ? "selectFilterInput" : ""
        }`}
      >
        {reason}
      </div>
    </div>
  );
};

const PopupRejectCotizacion = ({
  callbackCancel,
  callbackSend,
  reasonsList,
  callbackTextarea,
  callbackSelectReason,
}) => {
  return (
    <BasePopup
      content={
        <div className="popup-select-items-container">
          <div className="psi-icon-container">
            <img src={LOGO_ICON_SVG} className="psi-icon" alt="" />
          </div>

          <div className="psi-text">
            Es una lástima que no cumplamos con tus expectativas. Por favor,
            comentanos qué nos faltó para poder buscar otra opción que se adapte
            a tu solicitud.
          </div>

          <div className="prc-reasons-container">
            <div className="reasons-title">Qué razones tuviste?</div>
            {reasonsList.map((reason, index) => (
              <CheckboxReason
                key={index}
                reason={reason}
                index={index}
                callbackSelectReason={callbackSelectReason}
              />
            ))}
            <textarea
              placeholder="Expresa tu opinión frente al trabajo realizado, es importante para mejorar."
              className="textarea-reject-cotizacion"
              onChange={callbackTextarea}
            />
          </div>

          <div className="prc-buttons-container">
            <div
              className="prc-button prc-button-cancel"
              onClick={callbackCancel}
            >
              Cancelar
            </div>
            <div className="prc-button prc-button-send" onClick={callbackSend}>
              Enviar
            </div>
          </div>
        </div>
      }
    />
  );
};

const AdminPopup = ({
  show,
  title,
  userType,
  moreButton,
  icon,
  type,
  newItem,
}) => {
  const [currentValue, setCurrentValue] = useState(0);

  const handleKeepOpen = (e) => {
    setCurrentValue(1);
    e.stopPropagation();
  };

  const closePopup = () => {
    setCurrentValue(0);
  };

  return (
    <div
      className={currentValue !== 0 || show ? "portal" : ""}
      onClick={closePopup}
    >
      <div
        className={` ${type} ${
          (!show && currentValue !== 0) || show ? "showNoti" : "hide"
        }`}
        onClick={(e) => {
          handleKeepOpen(e);
        }}
      >
        <div className="ap-header-container">
          <div className="ap-icon-container">
            <img src={BELL_ICON_SVG} className="ap-icon" alt="" />
          </div>
          <div className="ap-title">Notificaciones</div>
        </div>
        <div className="ap-body-container">
          {userType === 200 ? <PsNotifications /> : <Notifications />}
        </div>
      </div>
    </div>
  );
};

export {
  BasePopup,
  PopupMenuNavbar,
  OneButtonsPopup,
  TwoButtonsPopup,
  IconPopup,
  MediaFilePopup,
  LoadingPopup,
  SelectPropertyPopup,
  IconTwoButtonsPopup,
  PopupSelectItems,
  PopupRejectCotizacion,
  PopupSeguimientoMenuNavbar,
  AdminPopup,
  AlertPopup,
};
