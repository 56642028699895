import useReduxState from "custom-hooks/useReduxState";
import React from "react";
import { updateTextMessage } from "reducers/chat.controller.reducer";

const MessageTextTarea = () => {
  const [chatControlState, dispatchControl] = useReduxState(
    { updateTextMessage },
    "chatController"
  );

  const { showAudio, isRecording } = chatControlState;
  const { updateTextMessage: updateMessage } = dispatchControl;

  const updateText = (event) => {
    const text = event.target.value;
    updateMessage(text);
  };

  const handleResize = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = `${Math.min(event.target.scrollHeight, 200)}px`;
  };

  return (
    !showAudio &&
    !isRecording && (
      <textarea
        id="messageInput"
        placeholder="Escribe un nuevo mensaje"
        value={chatControlState.messageText}
        className="cei-textarea"
        onChange={updateText}
        onInput={handleResize}
      />
    )
  );
};

export default MessageTextTarea;
