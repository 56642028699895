import { useEffect, useState } from "react";
import useFile from "../../../custom-hooks/useFile.js";
import PsNewEntry from "./PsNewEntry.jsx";

import _ from "lodash";
import moment from "moment";
import "moment/locale/es";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import TRASH_ICON_SVG from "../../../assets/icons/svg/trash.svg";
import {
  AlertPopup,
  LoadingPopup,
} from "../../../components/atoms/popup/Popup";
import useResourceUploader from "../../../custom-hooks/useResourceUploader.js";
import StateButtonModalHook, {
  StateMomentButtonModalHook,
} from "../../../modal/StateButtonModalHook.jsx";
import { PopupTwoOptionWithIcon } from "../../../popups/PopupTwoButtons";
import { ShowMediaSource } from "../../../popups/PopupsNewServices.jsx";
import { PopupSuccessEvent } from "../../../popups/psPopups/PopupSuccessEvent.jsx";
import { addSpaces } from "../../../reducers/area.property.reducer.js";
import {
  changeActivitites,
  changeDate,
  changeDescription,
  changeFinishedItem,
  changeIdentifier,
  changeIsVisble,
  changePhase,
  changeRole,
  changeSteps,
  changeTitle,
  changeUnexpected,
  changeUnexpectedMessage,
  resetToInitialState,
} from "../../../reducers/form.entry.reducer";
import {
  completePhase,
  createNewEntry,
  getPhases,
  updateActivity,
  updateSpaceActivity,
} from "../../../services-controller/http.cleinte.controller";
import {
  canBeSelectedFinishPhase,
  hiddenEntry,
  phaseIsAvailableForFinish,
  processPhases,
} from "./phases.js";

moment.locale("es");

const PsNewEntryController = ({ history }) => {
  const { idService } = useParams();
  const dispatch = useDispatch();
  const form_entry = useSelector((state) => state.form_entry);
  const userAuth = useSelector((state) => state.auth.user);

  const { phase, resources, finished_item, is_visible } = form_entry;

  const [showImprevisto, setShowImprevisto] = useState(false);
  const [phases, setPhases] = useState([]);
  const [phaseService, setPhaseService] = useState(null);
  const [phaseSelectedIsAvailable, setPhaseSelectedIsAvailable] = useState("");
  const [canBeFinish, setCanBeFinish] = useState(false);
  const [itemTasks, setItemTasks] = useState([]);
  const [showFinishItem, setShowFinishItem] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [spacesSelected, setSpacesSelected] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState([]);
  const [entryDescription, setEntryDescription] = useState("");
  const [resourcesFiles, setResourcesFiles] = useState([]);
  const file = useFile(resourcesFiles, setResourcesFiles, setIsLoader);
  const [newEntry, setNewEntry] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const [updateAviable, setUpdateAviable] = useState(false);
  const [resourcersLink, processResource] = useResourceUploader();

  const updatePhaseAvailable =
    entryDescription !== "" && finished_item === true;

  const fetchServiceById = async () => {
    setIsLoader(true);
    const statusService = await getPhases(idService);

    setPhaseService(statusService);

    if (statusService === null || statusService === undefined) {
      // Si statusService es nulo o no definido, puedes registrar información aquí si es necesario.
    } else {
      const processedPhases = await processPhases(
        statusService,
        userAuth.user_type
      );

      setPhases(processedPhases);
      const formattedDate = moment().format("dd-DD-MMMM-MM-YYYY-HH:mm:ss");
      const serializedDate = JSON.stringify(formattedDate);
      const jsonWithoutQuotes = serializedDate.replace(/^"(.*)"$/, "$1");

      dispatch(changeDate(jsonWithoutQuotes));
      dispatch(changeRole(userAuth));
    }

    setIsLoader(false);
  };

  useEffect(() => {
    const operationPhase = phases.find((phase) => phase.position === 2);
    if (operationPhase) {
      callbackItemsDropdow({
        name: operationPhase.title,
        code: operationPhase.position,
        id: operationPhase.unique_key,
        index: operationPhase.position,
      });
    }
  }, [phases]);

  useEffect(() => {
    fetchServiceById();

    return () => {
      dispatch(resetToInitialState());
    };
  }, [idService, newEntry]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldReset) {
      // Establecer shouldReset en false después de 1 segundo
      const timer = setTimeout(() => {
        setShouldReset(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [shouldReset]);

  const handleImprevisto = () => {
    setShowImprevisto(!showImprevisto);
  };

  const resetAll = () => {
    dispatch(resetToInitialState());
    setShouldReset(true);
    setActivitiesSelected([]);
    setSpacesSelected([]);
    setResourcesFiles([]);
    setEntryDescription("");
    setUpdateAviable(false);
    setPhaseSelectedIsAvailable("");
    setCanBeFinish(false);
    setItemTasks([]);
    setPhases([]);
    setPhaseService(null);
    file.utils.clear();
  };

  const updateBitacora = async () => {
    if (updateAviable) {
      setIsLoader(true);
      if (activitiesSelected.length > 0) {
        for (let activity of activitiesSelected) {
          const promises = [];
          if (activity.spaces && activity.spaces.length > 0) {
            const activityGroup = itemTasks.find((activityGroup) =>
              activityGroup.activities.find(
                (activity_) => activity_.id === activity.id
              )
            );
            for (let space of activity.spaces) {
              if (
                space.selected &&
                space.temporalValue > 0 &&
                !space.isCompleted
              ) {
                await updateSpaceActivity(
                  idService,
                  phase.id,
                  activityGroup,
                  activity,
                  space,
                  space.temporalValue,
                );
              } else if (space.selected && !space.isCompleted) {
                console.log("updateSpaceActivity", phases);
                promises.push(
                  updateSpaceActivity(
                    idService,
                    phase.id,
                    activityGroup,
                    activity,
                    space,
                  ).catch((error) => {
                    console.error(
                      `Error en updateSpaceActivity: ${error.message}`
                    );
                  })
                );
              }
            }
          } else {
            promises.push(
              updateActivity(idService, phase.id, activity).catch((error) => {
                console.error(`Error en updateActivity: ${error.message}`);
              })
            );
          }
          await Promise.allSettled(promises);
        }

        let resourcesLink = await processResource(resourcesFiles);
        let object = { ...form_entry, resources: resourcesLink };
        const userInfo = {
          name: userAuth.register_data.name,
          user_type: "sp",
          id: userAuth.id,
        };
        await createNewEntry(
          idService,
          "sp",
          {
            ...object,
            unexpected_id: "",
          },
          userInfo
        );
      }

      dispatch(resetToInitialState());
      dispatch(addSpaces([]));
      resetAll();

      setNewEntry(true);
      setShouldReset(true);
      setIsLoader(false);
    }
  };

  const updatePhase = async () => {
    if (updatePhaseAvailable) {
      dispatch(changeActivitites([]));
      dispatch(changeSteps([{ name: "Se termina Fase", code: "", id: "" }]));
      setIsLoader(true);
      const res = await completePhase(idService, phase.code);
      if (res) {
        let resourcesLink = await processResource(resources);
        let object = { ...form_entry, resources: resourcesLink };
        const userInfo = {
          name: userAuth.register_data.name,
          user_type: "sp",
          id: userAuth.id,
        };
        await createNewEntry(
          idService,
          "sp",
          {
            ...object,
            unexpected_id: "",
          },
          userInfo
        );
        await createNewEntry(
          idService,
          "sp",
          {
            ...object,
            unexpected_id: "",
          },
          userInfo
        );
      }

      setIsLoader(false);
      dispatch(resetToInitialState());
      setShouldReset(true);
    }
  };

  const callbackItemsDropdow = (e) => {
    if (e !== undefined) {
      setShouldReset(true);
      dispatch(changeTitle(e.title));
      dispatch(changePhase({ name: e.name, code: e.code, id: e.id }));
      dispatch(changeSteps([]));
      dispatch(changeActivitites([]));

      const index = e.index;
      const phase = phases.find((phase) => phase.position === index);

      if (phase) {
        setPhaseSelectedIsAvailable(
          phaseIsAvailableForFinish(phase, userAuth.user_type)
        );
        setCanBeFinish(
          canBeSelectedFinishPhase(
            phase,
            phaseService.globalState.state.currentPhaseIndex
          )
        );
        setItemTasks([...phase.activities]);
        setActivitiesSelected([]);
        dispatch(changeIdentifier(phase.unique_key));
      }
      if (phase.position === 1) {
        let operationPhase = phaseService.phases.find(
          (phase) => phase.position === 2
        );
        if (operationPhase.spaces && operationPhase.spaces.length > 0) {
          dispatch(addSpaces(operationPhase.spaces));
        }
      }
    } else {
      setItemTasks([]);
      dispatch(changeIdentifier(""));
    }
  };

  const callbackSelectedSpace = (
    activityGroupIndex,
    activityIndex,
    spaceIndex
  ) => {
    let selectedActivities = [...activitiesSelected];
    let activityGroup = itemTasks[activityGroupIndex];
    let activity = activityGroup.activities[activityIndex];
    let space = activity.spaces[spaceIndex];

    space.selected = space.selected ? !space.selected : true;

    let indexActivity = selectedActivities.indexOf(activity);
    if (indexActivity === -1) {
      selectedActivities.push(activity);
    } else if (activity.spaces.every((space) => space.selected === false)) {
      selectedActivities.splice(indexActivity, 1);
    }
    updateActivities(selectedActivities);
  };

  const callbackSelectActivity = (indexActivity, indexSubActivity) => {
    let aux = [...activitiesSelected];
    let currentIndex = aux.indexOf(itemTasks[indexActivity]);
    if (currentIndex === -1) aux.push(itemTasks[indexActivity]);
    else aux.splice(currentIndex, 1);
    updateActivities(aux);
  };

  const callbackSelectedSpaceNumber = (
    activityGroupIndex,
    activityIndex,
    spaceIndex,
    valueComplete
  ) => {
    let selectedActivities = [...activitiesSelected];
    let activityGroup = itemTasks[activityGroupIndex];
    let activity = activityGroup.activities[activityIndex];
    let space = activity.spaces[spaceIndex];

    const lastValueCompleted = space.progress.valueComplete
      ? parseFloat(space.progress.valueComplete)
      : 0;

    const selected =
      valueComplete > 0 &&
      !space.isCompleted &&
      valueComplete + lastValueCompleted <= parseFloat(space.progress.value);
    if (selected) {
      space.temporalValue = valueComplete;
    }
    space.selected = selected;

    let indexActivity = selectedActivities.indexOf(activity);
    if (indexActivity === -1) {
      selectedActivities.push(activity);
    } else if (activity.spaces.every((space) => space.selected === false)) {
      selectedActivities.splice(indexActivity, 1);
    }
    updateActivities(selectedActivities);
  };

  const updateActivities = async (activitiesList) => {
    const deepClone = _.cloneDeep(activitiesList);

    dispatch(changeSteps([...deepClone]));
    dispatch(changeActivitites([...deepClone]));
    setActivitiesSelected([...activitiesList]);
    hiddenEntry([...activitiesList], (isHidden) => {
      dispatch(changeIsVisble(isHidden));
    });
  };

  const callbacSelectSubActivity = (indexActivity, indexSubActivity) => {
    let aux = [...activitiesSelected];
    let activitiesGroup = itemTasks[indexActivity].activities;
    let activity = activitiesGroup[indexSubActivity];
    let currentIndex = aux.indexOf(activity);
    if (currentIndex === -1) aux.push(activity);
    else aux.splice(currentIndex, 1);
    updateActivities(aux);
  };

  const callbackSelect = {
    callbackActivity: callbackSelectActivity,
    callbackSubActivity: callbacSelectSubActivity,
    callbackSpace: callbackSelectedSpace,
    callbackSpaceNumber: callbackSelectedSpaceNumber,
  };

  useEffect(() => {
    if (
      activitiesSelected.length > 0 &&
      entryDescription !== "" &&
      typeof phase === "object" &&
      phase !== null
    ) {
      setUpdateAviable(true);
    }
  }, [entryDescription, phase, activitiesSelected]);

  const callbackDescripcionTextarea = (e) => {
    dispatch(changeDescription(e.target.value));
    setEntryDescription(e.target.value);
  };

  const callbackImprevisto = (e) => {
    if (e.target.value === "No") {
      dispatch(changeUnexpected(false));
    } else {
      dispatch(changeUnexpected(true));
    }
  };

  const handleUnexpectedMessage = (e) => {
    dispatch(changeUnexpectedMessage(e.target.value));
  };

  const callbackFinishItem = () => {
    dispatch(changeFinishedItem(!finished_item));
  };

  const callbackHiddenEntry = () => {
    dispatch(changeIsVisble(!is_visible));
  };

  return (
    <>
      <PsNewEntry
        idService={idService}
        showImprevisto={showImprevisto}
        handleImprevisto={handleImprevisto}
        updateBitacora={updateBitacora}
        showFinishItem={showFinishItem}
        phases={phases}
        activitiesSelected={activitiesSelected}
        phaseSelected={phaseSelectedIsAvailable}
        canBeFinish={canBeFinish}
        callbackItemsDropdow={callbackItemsDropdow}
        callbackSelectedSpace={callbackSelectedSpace}
        itemTasks={itemTasks}
        callbackSelectTask={callbackSelect}
        callbackHiddenEntry={callbackHiddenEntry}
        shouldReset={shouldReset}
        callbackDescripcionTextarea={callbackDescripcionTextarea}
        updateAviable={updateAviable}
        callbackInprevisto={callbackImprevisto}
        listResources={resourcesFiles}
        callbackViewSource={file.callbackViewSource}
        deleteMediaSource={file.deleteMediaSource}
        callbackAddFile={file.callbackAddFile}
        callbackAddFileError={file.callbackAddFileError}
        openFileSelector={file.openFileSelector}
        handleUnexpectedMessage={handleUnexpectedMessage}
        callbackFinishItem={callbackFinishItem}
        setShowAlertMessage={setShowAlertMessage}
        setMessageAlert={setAlertMessage}
        completePhase={updatePhaseAvailable}
        updatePhase={updatePhase}
        finished_item={finished_item}
        entryDescription={entryDescription}
      />

      {/* bitacoraUpdated ? (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[bitacoraUpdated, setBitacoraUpdated]}
          object={{
            btnText: "ok",
            message: "Bitácora actualizada",
            callback: callbackCloseBitacoraPopup,
          }}
        />
        ) : null */}

      {/* remove media source */}
      {file.removeMedia && (
        <StateButtonModalHook
          component={PopupTwoOptionWithIcon}
          hook={[file.removeMedia, file.setRemoveMedia]}
          object={{
            callbackNegative: file.callbackKeepMediaSource,
            callbackPositive: file.callbackRemoveMediaSource,
            textPositive: "Si",
            textNegative: "No",
            popupText: "¿Desea eliminar el archivo de forma permanente?",
            popupIcon: TRASH_ICON_SVG,
          }}
        />
      )}
      {/* show media source */}
      {file.showMediaSource && (
        <StateButtonModalHook
          component={ShowMediaSource}
          hook={[file.showMediaSource, file.setShowMediaSource]}
          object={{
            callbackCloseMediaSource: file.callbackCloseMediaSource,
            mediaSourceFile: file.mediaSourceFile,
          }}
        />
      )}
      {newEntry && (
        <StateButtonModalHook
          component={PopupSuccessEvent}
          hook={[newEntry, setNewEntry]}
          object={{
            btnText: "ok",
            message: "Entrada creada satisfactoriamente",
            callback: () => {
              setNewEntry(false);
            },
          }}
        />
      )}
      {showAlertMessage && (
        <StateMomentButtonModalHook
          component={AlertPopup}
          hook={[showAlertMessage, setShowAlertMessage]}
          object={{
            message: alertMessage,
          }}
        />
      )}

      <LoadingPopup state={isLoader} />
    </>
  );
};
export default withRouter(PsNewEntryController);
