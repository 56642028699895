import moment from "moment";
import "moment/locale/es";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compareDates, getStringMonthInit } from "res/utils";
import CALENDAR_SVG_ICON from "../../../../assets/icons/svg/calendar.svg";
import LIST_SVG_ICON from "../../../../assets/icons/svg/list.svg";
import LOGO_ICON_SVG from "../../../../assets/icons/svg/otros.svg";
import StyledCalendar from "../../../../components/calendar/StyledCalendar.jsx";
import CalendarSlide from "../../../../components/calendar/calendarSlide/CalendarSlide";
import useReduxState from "../../../../custom-hooks/useReduxState.js";
import StateButtonModalHook from "../../../../modal/StateButtonModalHook.jsx";
import { PopupServiceNotAviable } from "../../../../popups/psPopups/PopupServiceNotAviable";
import { updateServicesList } from "../../../../reducers/servicesList.reducer.js";
import isAllowed from "../../../../res/config.roles.permission.js";
import { PRINCIPAL_PATH, PS_BASE_CASE } from "../../../../router/routes";
import { existUser } from "../../../../services-controller/auth";
import {
  allServices,
  allServicesAcceptByServiceProvider,
} from "../../../../services-controller/http.cleinte.controller";
import ServiceProviderMyServicesSearch from "./ServiceProviderMyServicesSearch.jsx";

moment.locale("es");
const ServiceProviderMyServicesSearchController = ({ history }) => {
  // state

  const [modalMessage, setModalMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");

  const [servicesRequests, setServicesRequest] = useState([]);
  const [currentCalendar, setCurrentCalendar] = useState(true);
  const [calendarIcon, setCalendarIcon] = useState(true);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [servicesCalendar, setServicesCalendar] = useState([]);
  const [auth] = useReduxState({}, "auth");

  // general

  const getServices = async () => {
    const allowed = isAllowed(auth?.user?.user_type);
    const res = allowed
      ? await allServices()
      : await allServicesAcceptByServiceProvider();

    const aux = [];
    for (let object of res) {
      const date = Date.parse(object.date_sort);
      const date_sort = moment(date).format("YYYY-MM-DD HH:mm:ss");
      const copy = { ...object, date_sort: date_sort };
      aux.push(copy);
    }
    setServicesRequest(aux);
    //  const order = aux.sort((a, b) => compareDates(a.date_sort, b.date_sort));
    //    setServicesCalendar(getServicesDate(order));
  };

  useEffect(() => {
    if (!existUser()) history.push(PRINCIPAL_PATH);
    else getServices();

    return () => {
      setServicesRequest([]);
      setServicesCalendar([]);
    };
  }, [history]);

  const dispatch = useDispatch();

  const callbackRegisterPositive = () => setShowModal(false);

  const callbackNavigator = (object) => {
    const { unique_key } = object;
    setId(unique_key);
    if (object.state.stage === 0) {
      history.push({
        pathname: `${PS_BASE_CASE}${unique_key}`,
        state: { service: object },
      });
    } else if (object.state.stage === 1) {
      setShowModal(true);
      setModalMessage("El servicio aún no ha sido aprobado por el cliente");
    } else if (object.state.stage === 3) {
      setShowModal(true);
      setModalMessage("El servicio ha sido rechazado por el cliente");
    } else if (object.state.stage === 8) {
      setShowModal(true);
      setModalMessage("El servicio esta siendo evaluado por Bitácora");
    } else if (object.state.stage === 13) {
      setShowModal(true);
      setModalMessage("El servicio ha sido cancelado");
    } else {
      //cases to stage code:
      history.push({
        pathname: `/services/bitacle/${unique_key}`,
      });
    }
  };

  const changeCurrentCalendar = () => {
    setCurrentCalendar(!currentCalendar);
    setCalendarIcon(!calendarIcon);
  };

  const onHandleSelectDate = (date) => {
    setSelectedDay(null);
    if (selectedDate) {
      const currentDate = moment(selectedDate);
      if (currentDate.isSame(date, "day")) {
        setSelectedDate(null);
      } else {
        setSelectedDate(date);
      }
    } else {
      setSelectedDate(date);
    }
  };

  const onHandleSelectDay = (date) => {
    setSelectedDate(null);
    if (selectedDay) {
      const currentDate = moment(selectedDay);
      if (currentDate.isSame(date, "day")) {
        setSelectedDay(null);
      } else {
        setSelectedDay(date);
      }
    } else {
      setSelectedDay(date);
    }
  };

  const getDaysList = () => {
    const date1 = moment().subtract(2, "days");
    const date2 = moment().subtract(1, "days");
    const date3 = moment();
    const date4 = moment().add(1, "days");
    const date5 = moment().add(2, "days");
    const days = [
      {
        number: date1.format("DD"),
        month: getStringMonthInit(date1.month()),
        date: date1.toDate(),
      },
      {
        number: date2.format("DD"),
        month: getStringMonthInit(date2.month()),
        date: date2.toDate(),
      },
      {
        number: date3.format("DD"),
        month: getStringMonthInit(date3.month()),
        date: date3.toDate(),
      },
      {
        number: date4.format("DD"),
        month: getStringMonthInit(date4.month()),
        date: date4.toDate(),
      },
      {
        number: date5.format("DD"),
        month: getStringMonthInit(date5.month()),
        date: date5.toDate(),
      },
    ];
    return days;
  };

  useEffect(() => {
    const newServices = servicesRequests.map((el) => el);
    dispatch(updateServicesList(newServices));
  }, [servicesRequests, selectedDate, selectedDay, dispatch]);

  const getServicesDate = (list = []) => {
    const listAux = [];

    for (let service of list) {
      const date = service.request?.schedule.date_start;
      listAux.push(new Date(date.split("T")[0].replace("-", "/")).toString());
    }

    return listAux;
  };
  return (
    <>
      <ServiceProviderMyServicesSearch
        callbackNavigator={callbackNavigator}
        servicesRequests={servicesRequests}
        currentCalendar={
          currentCalendar ? (
            <CalendarSlide
              daysList={getDaysList()}
              defaultValueDate={selectedDay}
              onHandleChangeDate={onHandleSelectDay}
            />
          ) : (
            <div className="schedule-calendar">
              <StyledCalendar
                servicesCalendar={servicesCalendar}
                defalutValueDate={selectedDate}
                callbackSelectedDate={onHandleSelectDate}
                minDate={true}
              />
            </div>
          )
        }
        changeCurrentCalendar={changeCurrentCalendar}
        calendarIcon={calendarIcon ? CALENDAR_SVG_ICON : LIST_SVG_ICON}
        changeView={!currentCalendar}
      />
      {showModal ? (
        <StateButtonModalHook
          component={PopupServiceNotAviable}
          hook={[showModal, setShowModal]}
          object={{
            callback: callbackRegisterPositive,
            message: modalMessage,
            btnText: "Ok",
            icon: LOGO_ICON_SVG,
          }}
        />
      ) : null}
    </>
  );
};

export default withRouter(ServiceProviderMyServicesSearchController);
