import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { createServiceProviderById } from '../../../services-controller/http.cleinte.controller.js'
import { createAccountWhitEmailAndPassword } from '../../../services-controller/auth.js'
import ContactInfoServiceProvider from './ContactInfoServiceProvider.jsx'
import { BASE_PS_TYPE_PATH, PS_SPECIALITY_INFO, PRINCIPAL_PATH } from '../../../router/routes'
import { convertRegisterTypeByRouterToNames, convertRegisterTypeByRouterToCode, convertUserTypeToRegisterTypeCode } from '../../../res/convert.codes'
import { getValueByKey } from '../../../persistent-managment/managment.persistent.js'
import { UID_AUTH } from '../../../persistent-managment/key.persistent.js'
import {
    changeLocationAddressStreetType,
    changeLocationAddressStreet,
    changeLocationAddressCorner,
    changeLocationAddressNumber,
    changeContactAuxiliaryContactPhoneAuxiliary,
    changeContactAuxiliaryDataName,
    changeContactAuxiliaryContactPhone,
    changeContactAuxiliaryDataRelationship,
    changeLocationCityCode,
    changeDepartmentCityCode
} from '../../../reducers/form.service.provider'
import { changeServiceProviderCoverageServices } from '../../../reducers/app.reducer.js'
import { PHONE_VALIDATION_ERROR_MESSAGE } from '../../../res/errors'
import StateButtonModalHook from '../../../modal/StateButtonModalHook.jsx'
import { ErrorRegister } from '../../../popups/SuccessRegister.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { REGEX_EMAIL, REGEX_PASS } from '../../../res/regex.js'
import { STEPS_PATH } from '../../../router/routes'
import { EMAIL_PASS_VALIDATION_ERROR_MESSAGE, EMAIL_VALIDATION_ERROR_MESSAGE, PASS_VALIDATION_ERROR_MESSAGE } from '../../../res/errors'
import { CREATE_USER_ERROR_MESSAGE } from '../../../res/errors'
import LIST_SERVICES from '../../../res/list.services.js'
import { LoadingPopup } from '../../../components/atoms/popup/Popup.js'


const ContactInfoServiceProviderController = ({ history }) => {
    const [isLoader, setIsLoader] = useState(false)


    const dispatch = useDispatch()
    const { app, form_service_provider } = useSelector(state => {
        return {
            app: state.app,
            form_service_provider: state.form_service_provider
        }
    })

    const [isErrorForm, setIsErrorForm] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')

    const {
        service_provider_type
    } = app

    const {
        register_type,
        location: {
            department: {
                department_code
            },
            city: {
                city_code
            },
            address: {
                street_type,
                street,
                corner,
                number
            }
        },
        contact_auxiliary: {
            data: {
                name,
                relationship
            },
            contact: {
                phone,
                phone_auxiliary
            }
        },
        register_data: {
            contact: {
                email_associated
            },
            secrets: {
                password
            }
        },
    } = form_service_provider



    //state

    const redirectPath = () => {

        if (service_provider_type === '') history.push(PRINCIPAL_PATH)
    }

    useEffect(() => {

        redirectPath()

        return () => {

        }

    }, [])

    //general

    const isEmpty = (str) => str.length !== 0

    const validatorPhone = (str) => {
        const result = (str.length === 7 || str.length === 10 || str.length === 0)
        return result
    }

    const callbackPushNavigator = async() => {
        if (validatorPhone(phone) && validatorPhone(phone_auxiliary)) {
            await callbackCreateServiceProvider()
        } else {
            showMessageInPopUp(PHONE_VALIDATION_ERROR_MESSAGE)
        }

    }
    const showModalError = (message) => {
        setIsLoader(false)
        setErrorMessage(message)
        setIsErrorForm(true)
    }

    const validatorEmail = (str) => {
        const result = REGEX_EMAIL.test(str)
        return result
    }

    const validatorPassword = (str) => {
        const result = REGEX_PASS.test(str)
        return result
    }


    const callbackCreateServiceProvider = async () => {
        setIsLoader(true)
        dispatch(changeServiceProviderCoverageServices(LIST_SERVICES))
        const emailEvaluation = validatorEmail(email_associated)
        const passEvaluation = validatorPassword(password)
        const emailAndPassEvaluation = (emailEvaluation && passEvaluation)
        if (!emailAndPassEvaluation) showModalError(EMAIL_PASS_VALIDATION_ERROR_MESSAGE)
        else {
            if (!emailEvaluation) showModalError(EMAIL_VALIDATION_ERROR_MESSAGE)
            else if (!passEvaluation) showModalError(PASS_VALIDATION_ERROR_MESSAGE)
            else {
                const result = await createAccountWhitEmailAndPassword(email_associated, password)
                const { code } = result
                if (code === 400) {
                    const { message } = result
                    showModalError(message)
                }
                else await savingDatabase()
            }
        }
    }

    const savingDatabase = async () => {
        const uid = getValueByKey(UID_AUTH)
        const result = await createServiceProviderById(
            uid,
            {
                ...form_service_provider,
                register_type: convertUserTypeToRegisterTypeCode(service_provider_type),
                user_type: convertRegisterTypeByRouterToCode(service_provider_type),
            },
            {
                ...app.service_provider_coverage
            }
        )
        const { status } = result
        if (status === 200) history.push({
            pathname: STEPS_PATH,
            //state: { type: register_type }
            state: { type: 200 }
        })
        else
            showModalError(CREATE_USER_ERROR_MESSAGE)
    }

    const callbackChangeStreetType = (e) => dispatch(changeLocationAddressStreetType(e.target.value))

    const callbackChangeStreet = (e) => dispatch(changeLocationAddressStreet(e.target.value))

    const callbackChangeCorner = (e) => dispatch(changeLocationAddressCorner(e.target.value))

    const callbackChangeNumber = (e) => dispatch(changeLocationAddressNumber(e.target.value))

    const callbackChangePhoneAuxiliary = (e) => dispatch(changeContactAuxiliaryContactPhoneAuxiliary(e.target.value))

    const callbackChangeNameContactAuxiliary = (e) => dispatch(changeContactAuxiliaryDataName(e.target.value))

    const callbackChangePhoneContactAuxiliary = (e) => dispatch(changeContactAuxiliaryContactPhone(e.target.value))

    const callbackChangeRelationship = (e) => dispatch(changeContactAuxiliaryDataRelationship(e.target.value))

    const callbackSelectedDepartment = (value) => dispatch(changeDepartmentCityCode(value.target.value))

    const callbackSelectedCity = (value) => dispatch(changeLocationCityCode(value.target.value))

    const isAvailableCallbackNex = () => {

        const result = (isEmpty(street) && isEmpty(corner) && isEmpty(number))

        return result
    }

    const showMessageInPopUp = (message) => {
        setIsErrorForm(true)
        setErrorMessage(message)
    }

    const stateErrorFormCallback = () => setIsErrorForm(false)

    return (
        <>
            <ContactInfoServiceProvider
                type={convertRegisterTypeByRouterToNames(service_provider_type)}
                //location
                callbackSelectedDepartment={callbackSelectedDepartment}
                defaultOptionDepartment={department_code}
                callbackSelectedCity={callbackSelectedCity}
                defaultOptionCity={city_code}
                //address
                callbackSelectedAddress={callbackChangeStreetType}
                defaultOptionAddress={street_type}
                callbackAddress={callbackChangeStreet}
                callbackCornerAddress={callbackChangeCorner}
                callbackNumberAddress={callbackChangeNumber}
                defaultValueAddress={street}
                defaultValueCornerAddress={corner}
                defaultValueNumberAddress={number}
                //rest
                callbackChangePhoneAuxiliary={callbackChangePhoneAuxiliary}
                defaultValuePhoneAuxiliary={phone_auxiliary}
                callbackChangeNameContactAuxiliary={callbackChangeNameContactAuxiliary}
                defaultValueNameContactAuxiliary={name}
                callbackChangePhoneContactAuxiliary={callbackChangePhoneContactAuxiliary}
                defaultValuePhoneContactAuxiliary={phone}
                callbackChangeRelationship={callbackChangeRelationship}
                defaultValueRelationship={relationship}
                callbackPushNavigator={callbackPushNavigator}
                isAvailableCallbackNex={isAvailableCallbackNex()}
            />

            {/* error */}
            {
                isErrorForm ?
                    <StateButtonModalHook
                        component={ErrorRegister}
                        hook={[isErrorForm, setIsErrorForm]}
                        object={{
                            message: errorMessage,
                            callback: stateErrorFormCallback
                        }}
                    /> : null
                
            }

            <LoadingPopup state={isLoader} />
        </>
    )
}

export default withRouter(ContactInfoServiceProviderController)
