import React from 'react'
import './mediaSource.css'

const IMAGE_SRC = 'image'
const WIDTH_VIDEO = '200'

const ImageSRC = ({ file, url, index, callback, big }) => {
    if (!file) {
        file = fetch(url).then(res => res.blob())
    }
    const { name } = file
    return (< img className={`mediaContent ${big ? 'bigMedia' : ''}`} src={url} alt={name} key={index} onClick={callback} />)
}

const VideoSRC = ({ file, url, index, callback, widthVideo, big }) => {
    if (!file) {
        file = fetch(url).then(res => res.blob())
    }

    const { type } = file
    return (

        <video src={url} className={`mediaContent ${big ? 'bigMedia' : ''}`} width={widthVideo} autoPlay key={index} onClick={callback}>
            <source src={url} type={type} />
        </video>
    )
}

const WarpSoruce = ({ type, file, url, index, callback, big }) => {
    if (type === IMAGE_SRC) return <ImageSRC file={file} url={url} callback={callback} big={big} />
    else return <VideoSRC file={file} url={url} index={index} callback={callback} widthVideo={WIDTH_VIDEO} big={big} />

}

const WarpSoruceURI = ({ type, file, url, index, callback }) => {
    if (type === IMAGE_SRC) return <ImageSRC file={file} url={url} callback={callback} />
    else return <VideoSRC file={file} url={url} index={index} callback={callback} widthVideo={WIDTH_VIDEO} />

}

export {
    WarpSoruce,
    WarpSoruceURI
}