import { firebaseAppAuth, providers } from "./handler";

const { googleProvider, facebookProvider } = providers;

const signInWithPopup = async (provider) => {
  const res = await firebaseAppAuth.signInWithPopup(provider);
  return res;
};

const signInWithGoogleProvider = async (_) => {
  const res = await signInWithPopup(googleProvider);
  return res;
};

const signInWithFacebookProvider = async (_) => {
  const res = await signInWithPopup(facebookProvider);
  return res;
};

const signInWhitEmailAndPasswordProvider = async (email, pass) => {
  const res = await firebaseAppAuth.signInWithEmailAndPassword(email, pass);
  return res;
};

const signInTokenGenerated = async (token) => {
  const res = await firebaseAppAuth.signInWithCustomToken(token);

  return res;
};

const createAccountWhitEmailAndPasswordProvider = async (email, pass) => {
  const res = await firebaseAppAuth.createUserWithEmailAndPassword(email, pass);
  return res;
};

const getToken = () => firebaseAppAuth?.currentUser?.getIdToken();

const currentUser = () => firebaseAppAuth.currentUser;

const onAuthStateChanged = (callback) =>
  firebaseAppAuth.onAuthStateChanged(callback);

const signOut = () => firebaseAppAuth.signOut();

const resetPasswordByEmail = async (email) =>
  await firebaseAppAuth.sendPasswordResetEmail(email);

export {
  createAccountWhitEmailAndPasswordProvider,
  currentUser,
  getToken,
  onAuthStateChanged,
  resetPasswordByEmail,
  signInTokenGenerated,
  signInWhitEmailAndPasswordProvider,
  signInWithFacebookProvider,
  signInWithGoogleProvider,
  signOut,
};
