import React from "react";
import { ReadDetail } from "./ReadDetail";

const MessageReadStatus = ({ message, showDetail, isMine }) => {
  return (
    <div
      className={`message-info-detail${isMine} ${showDetail ? "active" : ""} `}
    >
      <div className="separate-line"></div>
      <div className="read-detail">
        <ReadDetail message={message} />
      </div>
    </div>
  );
};

export default MessageReadStatus;
