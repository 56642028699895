import React, { useState, useEffect } from 'react'
import StateButtonModalHook from '../../../../modal/StateButtonModalHook.jsx'
import ServiceProviderMyRequests from './ServiceProviderMyRequests.jsx'
import { withRouter } from 'react-router-dom'
import { PS_BASE_MENU_PATH, PS_REQUEST_DETAILS, PRINCIPAL_PATH } from '../../../../router/routes'
import { PopupSuccessEvent } from '../../../../popups/psPopups/PopupSuccessEvent.jsx'
import { getAllServicesInitServiceProvider } from '../../../../services-controller/database'
import { convertSnapshotToList } from '../../../../callbacks/list'
import { changeRequestService } from '../../../../reducers/request.service.reducer'
import { acceptService } from '../../../../services-controller/http.cleinte.controller'
import { LoadingPopup } from '../../../../components/atoms/popup/Popup'
import { existUser } from '../../../../services-controller/auth'
import { useDispatch } from 'react-redux'

const ServiceProviderMyRequestsController = ({ history }) => {

    const dispatch = useDispatch()

    //state

    const [isLoader, setIsLoader] = useState(false)

    const [takeService, setTakeService] = useState(false)

    const [messageResponse, setMessageResponse] = useState('')

    const [servicesRequests, setServicesRequest] = useState([])

    useEffect(() => {

        if (!existUser()) history.push(PRINCIPAL_PATH)

        const listenerServicesInit = getAllServicesInitServiceProvider((snapshot) => updateList(snapshot, setServicesRequest))

        return () => {

            listenerServicesInit()

        }

    }, [])



    //genereal

    const updateList = (snapshot, hook) => {
        if (typeof snapshot === 'object') {

            const auxList = convertSnapshotToList(snapshot.val())
            hook(auxList)
        }
    }

    const navigatorDetails = (object) => {
        dispatch(changeRequestService(object))
        history.push(`${PS_BASE_MENU_PATH}${PS_REQUEST_DETAILS}`)
    }

    const handleTakeService = async (service) => {

        setIsLoader(true)

        const {
            unique_key
        } = service

        try {
            const result = await acceptService(unique_key)

            const {
                accepted
            } = result.data

            if (accepted) setMessageResponse('Servicio agendado con éxito.')
            else setMessageResponse('El servicio ya ha sido aceptado.')
            setTakeService(true)

        } catch (error) {
            setMessageResponse('Ocurrio un problema')
            setTakeService(true)
        }

        setIsLoader(false)
    }

    const callbackOk = () => {
        setTakeService(false)
    }

    return (
        <>
            <ServiceProviderMyRequests
                navigatorDetails={navigatorDetails}
                servicesRequests={servicesRequests}
                handleTakeService={handleTakeService}
                handleRejectService={() => { }}

            />
            {/* resolve service */}
            {
                takeService ? <StateButtonModalHook
                    component={PopupSuccessEvent}
                    hook={[takeService, setTakeService]}
                    object={{
                        message: messageResponse,
                        callback: callbackOk,
                        btnText: 'Ok '
                    }}
                /> : null

            }
            {/* loading */}
            <LoadingPopup state={isLoader} />
        </>
    )
}

export default withRouter(ServiceProviderMyRequestsController)