import React from 'react'
import { PrimaryButton } from '../../components/atoms/button/Button'
import { BaseUsageTemplate } from '../../components/templates/usageTemplate/UsageTemplate'
import './steps.css'
import {AppbarLogo} from '../../components/atoms/appbar/Appbar'

const StepGuide = ({
    listSteps,
    callbackChange,
    positionSelected,
    callbackSkeep
}) => {

    return (
        <div className='login-main-container'>

            <AppbarLogo />

            <div id='login-container'>

                <div className='stepguide-container'>

                    <div className='slider-container'>
                        {
                            listSteps.map((step, index) =>
                                <div id={index} key={index} className={`stepGuideInfo ${positionSelected === index ? 'show' : 'hide'}`}>
                                    <BaseUsageTemplate
                                        image={step.image}
                                        title={step.title}
                                        text={step.message}
                                        btn={
                                            (index === listSteps.length - 1) ?
                                                <PrimaryButton callback={callbackSkeep} txt='Iniciar' /> :

                                                <div id='container-continue-skip'>
                                                    <div className='btn-omitir' id={index + 1} onClick={callbackSkeep} >Omitir</div>
                                                    <div className='btn-continuar' id={index + 1} onClick={callbackChange}>Continuar</div>
                                                </div>
                                        }

                                        step={
                                            <>
                                                {
                                                    listSteps.map((_, index) =>
                                                        <div key={index} id='container-steps'>
                                                            <div
                                                                id={index}
                                                                className={`${positionSelected === index ? 'active-step' : 'circular-div '}`}>
                                                            </div>
                                                        </div>)
                                                }
                                            </>
                                        }
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default StepGuide