import React from 'react'
import '../register.css'
import PHONE_ICON_SVG from '../../../assets/icons/svg/phone.svg'
import MAIL_ICON_SVG from '../../../assets/icons/svg/mail.svg'
import USER_ICON_SVG from '../../../assets/icons/svg/user.svg'
import { PrimaryButton } from '../../../components/atoms/button/Button'
import { IconInput } from '../../../components/atoms/input/Input'
import {IconDropdown} from '../../../components/atoms/dropdown/Dropdown'
import { RegisterPropertyTemplate } from '../../../components/templates/registerTemplate/RegisterTemplate'
import POINTING_ICON_SVG from '../../../assets/icons/svg/finger-point.svg'
import {AppbarLogo} from  '../../../components/atoms/appbar/Appbar'

const RegisterNewPersonal = ({
    callbackCreatePersonal,
    callbackNameManagement,
    defaultValueNameManagement,
    callbackPositionManagement,
    defaultValuePositionManagement,
    callbackPrimaryPhone,
    defaultValuePrimaryPhone,
    callbackAuxiliaryPhone,
    defaultValueAuxiliaryPhone,
    callbackEmailManagement,
    defaultValueEmailManagement,
    callbackPersonalType,
    edit=false
}
) => {

    return (
        <div className='register-info-container'>
            <AppbarLogo />
            <RegisterPropertyTemplate
                type='Encargado o inquilino'
                smallText= {!edit ? "Registra nuevo" : "Editar"}
                fields={

                    <div className='options-fields' >

                        <div>

                            <div className='fields-container'>
                                <IconDropdown
                                    callback={callbackPersonalType}
                                    icon={POINTING_ICON_SVG}
                                    defaultSelected={'Encargado'}                                  
                                    items={
                                        ['Inquilino'].map((i, index) => (
                                            <option
                                                value={i}
                                                key={index}
                                                className='rol-option'>{i}
                                            </option>)
                                        )
                                    }
                                />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueNameManagement}
                                    callback={callbackNameManagement}
                                    icon={USER_ICON_SVG}
                                    placeholder='Nombre y apellido' />
                            </div>
                            {/*
                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValuePositionManagement}
                                    callback={callbackPositionManagement}
                                    icon={POINTING_ICON_SVG}
                                    placeholder='Cargo' />
                            </div>
                            */}

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueEmailManagement}
                                    callback={callbackEmailManagement}
                                    icon={MAIL_ICON_SVG}
                                    placeholder='Correo electrónico' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValuePrimaryPhone}
                                    callback={callbackPrimaryPhone}
                                    icon={PHONE_ICON_SVG}
                                    placeholder='Teléfono 1'
                                    type='number' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueAuxiliaryPhone}
                                    callback={callbackAuxiliaryPhone}
                                    icon={PHONE_ICON_SVG}
                                    placeholder='Teléfono 2'
                                    type='number' />
                            </div>

                        </div>

                        <div>

                            <div className='fields-container button-new-personal'>
                                <PrimaryButton
                                    txt={ !edit ? 'Registrar' : 'Editar'}
                                    callback={callbackCreatePersonal} />
                            </div>

                        </div>

                    </div>


                } />

        </div>
    )
}

export default RegisterNewPersonal