import React from 'react'
import { RegisterPropertyTemplate } from '../../../components/templates/registerTemplate/RegisterTemplate'
import { IconInput } from '../../../components/atoms/input/Input'
import { PrimaryButton, TwoOptionsButtons } from '../../../components/atoms/button/Button'
import { IconDropdown, BaseDropdown } from '../../../components/atoms/dropdown/Dropdown'
import { IconTextarea } from '../../../components/atoms/textarea/Textarea'
import POINTING_ICON_SVG from '../../../assets/icons/svg/finger-point.svg'
import NUMBERS_ICON_SVG from '../../../assets/icons/svg/123.svg'
import PARRAFO_ICON_SVG from '../../../assets/icons/svg/parrafo.svg'
import WAREHOUSE_ICON_SVG from '../../../assets/icons/svg/bodega.svg'
import PHONE_ICON_SVG from '../../../assets/icons/svg/phone.svg'
import LETTERS_ICON_SVG from '../../../assets/icons/svg/abc.svg'
import LocationForm from '../../../components/molecules/locationForm/LocationForm'
import MAP_ICON_SVG from '../../../assets/icons/svg/map-pin.svg'
import INFO_ICON_SVG from '../../../assets/icons/svg/info.svg'
import CLOCK_ICON_SVG from '../../../assets/icons/svg/clock.svg'
import { SCHEDULE_RANGE } from '../../../res/schedule.range'
import { AppbarLogo } from '../../../components/atoms/appbar/Appbar'

const RegisterPropHouse = ({
    callbackPushNavigatorMenu,
    callbackPushNavigatorNewPersonal,
    listPersonal,
    callbackSelectedManagement,
    defaultValueManagement,
    callbackNickName,
    defaultValueNickName,
    callbackDescription,
    defaultValueDescription,
    callbackPhone,
    defaultValuePhone,
    callbackSelectedDepartment,
    defaultOptionDepartment,
    callbackSelectedCity,
    defaultOptionCity,
    callbackSelectedAddress,
    defaultOptionAddress,
    callbackAddress,
    callbackCornerAddress,
    callbackNumberAddress,
    defaultValueAddress,
    defaultValueCornerAddress,
    defaultValueNumberAddress,
    callbackNumberFloors,
    defaultValueNumberFloors,
    callbackDeckAccess,
    defaultValueDeckAccess,
    callbackAccesoCubierta,
    callbackScheduleStart,
    defaultValueScheduleStart,
    callbackScheduleEnd,
    defaultValueScheduleEnd,
    callbackEncargadoInquilino,
    defaultValueShoppingCenter,
    defaultValueSpace,
    callbackShoppingCenter,
    callbackSpace,
    type,
    editingProperty = false
}) => {

    return (
        <div className='register-info-container'>
            <AppbarLogo />
            <RegisterPropertyTemplate
                type={type}
                smallText={`${!editingProperty ? 'Registra' : 'Edita'} tu inmueble`}
                fields={

                    <div className='order-form'>

                        <div className='options-fields' >

                            <div className='fields-title'>
                                <div className='fields-title-icon-container'/>
                                <div className='fields-title-text name-property-label'>Dale un nombre a tu inmueble</div>
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueNickName}
                                    callback={callbackNickName}
                                    icon={LETTERS_ICON_SVG}
                                    placeholder='"Mi casa", "Apartamento mamá"...' />
                            </div>

                            <div className='fields-title'>
                                <div className='fields-title-icon-container'><img src={MAP_ICON_SVG} className='fields-title-icon' alt="map" /></div>
                                <div className='fields-title-text'>Dirección</div>
                            </div>

                            <div className='fields-container'>
                                <LocationForm
                                    callbackSelectedDepartment={callbackSelectedDepartment}
                                    defaultOptionDepartment={defaultOptionDepartment}
                                    callbackSelectedCity={callbackSelectedCity}
                                    defaultOptionCity={defaultOptionCity}
                                    callbackSelectedAddress={callbackSelectedAddress}
                                    defaultOptionAddress={defaultOptionAddress}
                                    callbackAddress={callbackAddress}
                                    callbackCornerAddress={callbackCornerAddress}
                                    callbackNumberAddress={callbackNumberAddress}
                                    defaultValueAddress={defaultValueAddress}
                                    defaultValueCornerAddress={defaultValueCornerAddress}
                                    defaultValueNumberAddress={defaultValueNumberAddress}
                                />
                            </div>

                            <div className='fields-container'>
                                <IconTextarea
                                    defaultValue={defaultValueDescription}
                                    callback={callbackDescription}
                                    placeholder='Indicaciones adicionales'
                                    icon={PARRAFO_ICON_SVG} />
                            </div>

                            <div className='fields-title'>
                                <div className='fields-title-icon-container'><img src={INFO_ICON_SVG} className='fields-title-icon' alt="info" /></div>
                                <div className='fields-title-text'>Información adicional</div>
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValuePhone}
                                    callback={callbackPhone}
                                    type='number'
                                    icon={PHONE_ICON_SVG}
                                    placeholder='Teléfono' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueNumberFloors}
                                    callback={callbackNumberFloors}
                                    type='number'
                                    icon={NUMBERS_ICON_SVG}
                                    placeholder='Número de pisos' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueShoppingCenter}
                                    callback={callbackShoppingCenter}
                                    icon={LETTERS_ICON_SVG}
                                    placeholder='Nombre del barrio ó ubicación del inmueble' />
                            </div>

                            <div className='fields-container'>
                                <IconInput
                                    defaultValue={defaultValueSpace}
                                    callback={callbackSpace}
                                    icon={NUMBERS_ICON_SVG}
                                    placeholder='Nro de apartamento ú otros' />
                            </div>

                            <div className='categoria-container'>

                                <div className='valid-time-text'>
                                    <div>
                                        <img src={CLOCK_ICON_SVG} id='new-service-icon' alt={'POINTING_ICON_SVG'} />
                                    </div>
                                    <div> Horarios permitidos para trabajar: </div>
                                </div>

                            </div>

                            <div className='time-entry-container-client'>

                                <div className='new-time-entry'>

                                    <div className='time-entry-text'>Desde:</div>

                                    <div className='new-entry-dropdown-container'>
                                        <BaseDropdown
                                            defaultSelected={defaultValueScheduleStart}
                                            callback={callbackScheduleStart}
                                            items={SCHEDULE_RANGE.map((state, index) => (
                                                <option
                                                    value={state}
                                                    key={index}
                                                    className='rol-option'>
                                                    {state}
                                                </option>)
                                            )} style='new-entry-dropdown' />
                                    </div>

                                </div>

                                <div className='new-time-entry'>

                                    <div className='time-entry-text'>Hasta:</div>

                                    <div className='new-entry-dropdown-container'>
                                        <BaseDropdown
                                            defaultSelected={defaultValueScheduleEnd}
                                            callback={callbackScheduleEnd}
                                            items={SCHEDULE_RANGE.map((state, index) => (
                                                <option
                                                    value={state}
                                                    key={index}
                                                    className='rol-option'>{state}
                                                </option>)
                                            )} style='new-entry-dropdown' />
                                    </div>

                                </div>

                            </div>

                            <div className='fields-container'>
                                <TwoOptionsButtons
                                    accessDefaultValue={defaultValueDeckAccess}
                                    callback={callbackDeckAccess}
                                    icon={WAREHOUSE_ICON_SVG}
                                    text='¿Tiene acceso a cubierta?' />
                            </div>

                            <div className='fields-container' onClick={callbackAccesoCubierta}>
                                <div className='acceso-cubierta-info'>
                                    <div>¿Qué es acceso a cubierta?</div>
                                    <div className='interrogation'>?</div>
                                </div>
                            </div>

                            <div className='fields-container'>
                                <div className='register-encargado'>Encargado o Inquilino</div>
                            </div>

                            <div className='fields-container' onClick={callbackEncargadoInquilino}>
                                <div className='acceso-cubierta-info'>
                                    <div>Diferencia entre encargado e inquilino</div>
                                    <div className='interrogation'>?</div>
                                </div>
                            </div>

                            {
                                (listPersonal.length > 0) ?
                                    <>
                                        <div className='fields-container'>
                                            <IconDropdown
                                                callback={callbackSelectedManagement}
                                                icon={POINTING_ICON_SVG}
                                                defaultSelected={defaultValueManagement.register_data.name}
                                                valueSelected={JSON.stringify(defaultValueManagement)}
                                                items={
                                                    listPersonal.map((management, index) => (
                                                        <option
                                                            value={JSON.stringify(management)}
                                                            key={index}
                                                            className='rol-option'>{management.register_data.name}
                                                        </option>)
                                                    )
                                                }
                                            />
                                        </div>

                                        <div className='fields-container'>
                                            <div className='register-new-personal' onClick={callbackPushNavigatorNewPersonal}>+ Registrar nuevo encargado o inquilino</div>
                                        </div>
                                    </> :

                                    <div className='fields-container'>
                                        <div className='register-new-personal' onClick={callbackPushNavigatorNewPersonal}>+ Registrar nuevo encargado o inquilino</div>
                                    </div>
                            }


                            <div className='fields-container register-button'>
                                <PrimaryButton
                                    txt={editingProperty ? 'Editar' : 'Registrar'}
                                    callback={callbackPushNavigatorMenu} />
                            </div>

                        </div>

                    </div>
                } />

        </div>
    )
}

export default RegisterPropHouse