import React from "react";
import "./inventory.css";
import { Card, Carousel, Image } from "antd";
import INVENTORY from "./inventory.json";
import { camelCase } from "res/utils";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";

const AdvanceInventory = () => {
  const addPlural = (unit, amount) => {
    if (amount > 1) {
      const vowels = ["a", "e", "i", "o", "u"];
      const lastChar = unit.slice(-1).toLowerCase();
      if (!isNaN(lastChar)) {
        return unit;
      } else if (vowels.includes(lastChar)) {
        return `${unit}s`;
      } else {
        return `${unit}es`;
      }
    }
    return unit;
  };

  return (
    <>
      <div>
        <div className="header">
          <GoBackBtn />
          <h1>Inventario de materiales</h1>
        </div>
        {INVENTORY.map((material, index) => (
          <div className="card" key={index}>
            <div className="cards-amount-grid">
              <Card className="amount-card">
                <p>Actuales: </p>
                <h3>{material.amount_to_be_spend}</h3>
                <p>{addPlural(material.unit, material.amount_to_be_spend)}</p>
              </Card>
              <Card className="amount-card">
                <p>Usados: </p>
                <h3>{material.amount_spend}</h3>
                <p>{addPlural(material.unit, material.amount_spend)}</p>
                {/* <h3>${material.price_by_amount.toLocaleString()}</h3> */}
              </Card>
            </div>
            <div className="content-info">
              <div className="carousel-contain">
                <Carousel className="carousel">
                  {material.presentation.images.map((image, index) => (
                    <div key={index}>
                      <Image
                        src={image}
                        alt={camelCase(material.presentation.name)}
                        className="material-image"
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="info">
                <h2>{camelCase(material.presentation.name)}</h2>
                <h3>{material.presentation.description}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdvanceInventory;
