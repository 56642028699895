import FROWN_ICON_SVG from "../assets/icons/svg/frown.svg";
import { ServicesContainer } from "../components/molecules/container/Container";
import "./psPopups/psPopups.css";

const PopupWarranties = ({ object }) => {
  const {
    warratiesList,
    navigatorService,
    textBig = "garantias",
    textSmall = "Listado de",
    feedbackEmpty = "Actualmente no tienes servicios en garantia",
  } = object;

  return (
    <div className="ps-services-assistants-container">
      <div className="appbar popup-appbar">
        <div className="small-text-container">{textSmall}</div>
        <div className="big-text-container">{textBig}</div>
      </div>

      <div className="assistans-container">
        <div className="helpers-cards-container">
          {warratiesList.length > 0 ? (
            warratiesList.map((obj, index) => (
              <div
                className="SpSpaceBetweenContainers si-container-info baseContainer"
                key={index}
              >
                <div className="infoContainer sc-space-style">
                  <ServicesContainer
                    key={index}
                    object={obj}
                    navigatorService={navigatorService}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="no-assistants-container">
              <img src={FROWN_ICON_SVG} className="no-entries-icon" alt="" />
              <div className="no-entries-title">{feedbackEmpty}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupWarranties;
