import React from "react";
import MessageInfo from "./MessageInfo";
import NewMessageAlert from "./NewMessageAlert";
import MessageReadStatus from "./MessageReadStatus";

const MessageInfoContainer = ({ message, showDetail, isMine }) => {
  return (
    <div className={`botton-info-container${isMine}`}>
      <MessageInfo message={message} openMediaFilePopup={() => {}} />
      <NewMessageAlert message={message} />
      <MessageReadStatus
        message={message}
        showDetail={showDetail}
        isMine={isMine}
      />
    </div>
  );
};

export default MessageInfoContainer;
