const ADDRESS = [
    'Calle',
    'Carrera',
    'Avenida',
    'Avenida Carrera',
    'Avenida Calle',
    'Circular',
    'Circunvalar',
    'Diagonal',
    'Manzana',
    'Transversal',
    'Vía'
]

export default ADDRESS