import { memo, useEffect, useState } from "react";
import Timeline from "react-time-line";
import EntriesList from "./EntriesList";
import TimeLinePhaseItem from "./TimeLineItemPhase";

const PhaseTimeLine = ({
  index,
  entry,
  callback,
  navigateUnexpected,
  user,
  serviceStage,
}) => {
  const [showEntries, setShowEntries] = useState(false);
  const [show, setShow] = useState(false);
  const [isSpMessage, setIsSpMessage] = useState(false);
  const [isClientMessage, setIsClientMessage] = useState(false);
  const entries = entry.entries;
  const callbackSetPhase = () => {
    setShowEntries(!showEntries);

    setTimeout(() => {
      setShow(!show);
    }, 1);
  };

  const searchIfMessage = (arr, user) => {
    switch (user) {
      case "sp":
        const hasSpMessage = arr.some(
          (entry) => entry?.statusMessages?.newSpMessage
        );
        setIsSpMessage(hasSpMessage);
        break;
      case "client":
        const hasClientMessage = arr.some(
          (entry) => entry?.statusMessages?.newClientMessage
        );
        setIsClientMessage(hasClientMessage);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    searchIfMessage(entries, user);
  }, [entries, []]);

  return (
    <div key={index}>
      <Timeline
        items={[
          {
            ts: "",
            text: (
              <TimeLinePhaseItem
                name={entry.name}
                callback={callbackSetPhase}
                id={index}
                showEntries={showEntries}
                user={user}
                isSpMessage={isSpMessage}
                isClientMessage={isClientMessage}
                navigateUnexpected={navigateUnexpected}
                entry={entry}
              />
            ),
          },
        ]}
      />
      {showEntries && (
        <div className={`entries-list-container ${show ? "show-entries" : ""}`}>
          <EntriesList
            entries={entries}
            callback={callback}
            user={user}
            navigateUnexpected={navigateUnexpected}
            serviceStage={serviceStage}
          />
        </div>
      )}
    </div>
  );
};

export default memo(PhaseTimeLine);
