
import PINTURA_ICON_SVG from '../assets/icons/svg/pintura.svg'
import ELECTRICIDAD_ICON_SVG from '../assets/icons/svg/electricidad.svg'
import INSTABASICAS_ICON_SVG from '../assets/icons/svg/instabasicas.svg'
import HUMEDADES_ICON_SVG from '../assets/icons/svg/humedades.svg'
import TECHOS_ICON_SVG from '../assets/icons/svg/techos.svg'
import PLOMERIA_ICON_SVG from '../assets/icons/svg/plomeria.svg'
import CERRAJERIA_ICON_SVG from '../assets/icons/svg/cerrajeria.svg'
import ALTURAS_ICON_SVG from '../assets/icons/svg/alturas.svg'
import OTROS_ICON_SVG from '../assets/icons/svg/otros.svg'
import SOLDADURAS_ICON_SVG from '../assets/icons/svg/soldadura.svg'
import CARPINTERIA_ICON_SVG from '../assets/icons/svg/carpinteria.svg'
import DISINFECTION_ICON_SVG from '../assets/icons/svg/desinfeccion.svg'

import {
    PAINT_CODE,
    ELECTRICITY,
    BASIC_INSTALLATION,
    HUMIDITIES,
    ROOFS,
    PLUMBING,
    LOCLSMITH,
    HEIGHTS,
    WELDING,
    CARPENTRY,
    DISINFECTION,
    OTHERS,
    PAINT_CODE_NAME,
    ELECTRICITY_NAME,
    BASIC_INSTALLATION_NAME,
    HUMIDITIES_NAME,
    ROOFS_NAME,
    PLUMBING_NAME,
    LOCLSMITH_NAME,
    HEIGHTS_NAME,
    WELDING_NAME,
    CARPENTRY_NAME,
    DISINFECTION_NAME,
    OTHERS_NAME,
} from './codes.convert.services'

const LIST_SERVICES = [
    {
        title: PAINT_CODE_NAME,
        text: 'Y acabados',
        id: PAINT_CODE,
        icon: PINTURA_ICON_SVG
    },
    {
        title: ELECTRICITY_NAME,
        text: 'Cableado, puntos y otros',
        id: ELECTRICITY,
        icon: ELECTRICIDAD_ICON_SVG
    },
    {
        title: BASIC_INSTALLATION_NAME,
        text: 'Cuadros, cortineros, repisas',
        id: BASIC_INSTALLATION,
        icon: INSTABASICAS_ICON_SVG
    },
    {
        title: HUMIDITIES_NAME,
        text: 'E impermeabilizaciones',
        id: HUMIDITIES,
        icon: HUMEDADES_ICON_SVG
    },
    {
        title: ROOFS_NAME,
        text: 'Tejas, canales, techos',
        id: ROOFS,
        icon: TECHOS_ICON_SVG
    },
    {
        title: PLUMBING_NAME,
        text: 'Y reparaciones hidráulicas',
        id: PLUMBING,
        icon: PLOMERIA_ICON_SVG
    },
    {
        title: LOCLSMITH_NAME,
        text: 'Chapas y puertas',
        id: LOCLSMITH,
        icon: CERRAJERIA_ICON_SVG
    },
    {
        title: HEIGHTS_NAME,
        text: 'Fachadas, trabajo certificado',
        id: HEIGHTS,
        icon: ALTURAS_ICON_SVG
    },


    {
        title: WELDING_NAME,
        text: 'Puertas, rejas y otros',
        id: WELDING,
        icon: SOLDADURAS_ICON_SVG
    },
    {
        title: CARPENTRY_NAME,
        text: 'Muebles, cocinas y maderas',
        id: CARPENTRY,
        icon: CARPINTERIA_ICON_SVG
    },
    {
        title: DISINFECTION_NAME,
        text: 'Desinfección de espacios',
        id: DISINFECTION,
        icon: DISINFECTION_ICON_SVG
    },
    {
        title: OTHERS_NAME,
        text: 'Personalizado',
        id: OTHERS,
        icon: OTROS_ICON_SVG
    },
]

export default LIST_SERVICES