import { firebaseStorage, firebase } from './handler'

const STATE_CHANGES = 'state_changed'

const put = (bucket, id, extension, file, callbackPaused, callbackRunning, callbackError) => {

    const ref = firebaseStorage.ref(bucket).child(`${id}.${extension}`)
    const task = ref.putString(file, 'base64')

    return new Promise((resolve, reject) => {
        task.on(STATE_CHANGES, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: callbackPaused()
                    break
                case firebase.storage.TaskState.RUNNING: callbackRunning()
                    break
                default: callbackError()
                    break
            }
        }, (error) => {
            reject({ code: 400, message: error.message })
        }, async () => {
            const url = await task.snapshot.ref.getDownloadURL()
            resolve({ code: 200, url: url })
        })
    })
}

export default put