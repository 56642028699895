import { uniqueId } from "lodash";
import { useSelector } from "react-redux";
import { getDay } from "../../../res/utils";
import EntryComponent from "./entryCard/EntryComponent";
const EntriesList = ({
  entries,
  callback,
  navigateUnexpected,
  user,
  serviceStage,
}) => {
  const entry = useSelector((state) => {
    return {
      entry: entries,
    };
  });

  return (
    entries.length > 0 &&
    entries.map((obj, index) => (
      <EntryComponent
        key={uniqueId()}
        entry={entry}
        obj={obj}
        user={user}
        getDay={getDay}
        callback={callback}
        serviceStage={serviceStage}
      />
    ))
  );
};

export default EntriesList;
