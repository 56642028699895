import React, { useState } from "react";
import "./button.css";
import FOWARD_ICON_SVG from "../../icons/foward.svg";
import ADD_ICON_SVG from "../../../assets/icons/svg/clip.svg";
import MORE_ICON_SVG from "../../../assets/icons/svg/add.svg";
import NEXT_ICON_SVG from "../../../assets/icons/svg/arrow-right.svg";
import WHATSAPP_ICON_SVG from "../../../assets/icons/svg/whatsapp.svg";
import { PopupMenuNavbar, PopupSeguimientoMenuNavbar } from "../popup/Popup";
import md5 from "md5";

const BaseButton = ({ text, form, callback, id }) => {
  return (
    <button id={id} className={`baseBtn ${form}`} onClick={callback}>
      {text}
    </button>
  );
};

const PrimaryButton = ({ txt, callback, style }) => {
  return (
    <BaseButton text={txt} callback={callback} form={`primary ${style}`} />
  );
};

const StyledPrimaryButton = ({ txt, callback, style, id }) => {
  return <BaseButton text={txt} callback={callback} form={style} id={id} />;
};

const IconButton = ({ callback, title = "Siguiente" }) => {
  return (
    <BaseButton
      text={
        <div className="iconBtn">
          <span className="iconText">{title}</span>
          <span>
            {
              <img
                alt={"FOWARD_ICON_SVG"}
                src={FOWARD_ICON_SVG}
                className="fowardIcon"
              />
            }
          </span>
        </div>
      }
      callback={callback}
      form="primary"
    />
  );
};

const CategoryRoundedButton = ({
  txt,
  callback,
  icon,
  id,
  isSelected,
  callbackNoSelected,
}) => {
  const [selected, setSelect] = useState(isSelected);

  const callbacackSelectedService = () => callback();

  const callbackNoSelectedService = () => {
    callbackNoSelected();
    setSelect(!selected);
  };

  const handleChangeState = () => {
    if (isSelected) callbacackSelectedService();
    else callbackNoSelectedService();
  };

  return (
    <div
      id={id}
      className="containerCategoryButton"
      onClick={handleChangeState}
    >
      <BaseButton
        text={
          <img
            src={icon}
            className={`categoryIcon ${
              isSelected ? "selectedCategoryIcon" : ""
            }`}
            alt={txt}
          />
        }
        callback={() => {}}
        form={`categoryRounded ${isSelected ? "selectedCategoryRounded" : ""}`}
      />
      <p
        className={`textCategoryButton ${
          isSelected ? "selectedTextCategoryButton" : ""
        }`}
      >
        {txt}
      </p>
    </div>
  );
};

const MenuRoundedButton = ({ icon, callback, txt }) => {
  return (
    <div className="containerMenuButton">
      <div className="menuButton">
        <BaseButton
          text={<img src={icon} className="menuIcon" alt={txt} />}
          callback={callback}
          form="menuRounded"
        />
      </div>
      <p className="textMenuButton">{txt}</p>
    </div>
  );
};

const AddRoundedButton = ({ callback, thumbnail = "" }) => {
  return (
    <BaseButton
      text={
        <img
          src={thumbnail === "" ? ADD_ICON_SVG : thumbnail}
          className={`${thumbnail === "" ? "addIcon" : "addIconSelected"}`}
          alt={"IconSelected"}
        />
      }
      callback={callback}
      form="addRounded"
    />
  );
};

const MoreRoundedButton = ({ moreValues }) => {
  const [selected, setSelect] = useState(false);

  const handlePopup = (e) => {
    setSelect(!selected);
    e.stopPropagation();
  };

  const closePopup = () => {
    setSelect(false);
  };

  return (
    /*

       selected ? 
       <div className='show-menu-portal' onClick={closePopup}>
            <PopupMenuNavbar values={moreValues} show={selected} id='popupMenu' />
            <BaseButton id='popupMenu' text={<img src={ADD_ICON_SVG} className='moreIcon' alt={'ADD_ICON_SVG'} />} callback={handlePopup} form='more-rounded-portal-show' />
        </div> :
        <>
        <PopupMenuNavbar values={moreValues} show={selected} id='popupMenu' />
        <BaseButton id='popupMenu' text={<img src={ADD_ICON_SVG} className='moreIcon' alt={'ADD_ICON_SVG'} />} callback={handlePopup} form='moreRounded' />
        </>
        */
    <></>
  );
};

const NextRoundedButton = ({ show, callback }) => {
  return (
    <React.Fragment>
      <BaseButton
        callback={callback}
        text={
          <img src={NEXT_ICON_SVG} alt={"NEXT_ICON_SVG"} className="moreIcon" />
        }
        form={`moreRounded ${show ? "btnVisible" : "btnHidden"}`}
        alt={"NEXT_ICON_SVG"}
      />
    </React.Fragment>
  );
};

const NavbarButton = ({ callback, selected, txt, id, icon }) => {
  return (
    <div
      onClick={callback}
      className={`containerNavbarButton ${
        selected === id ? "selectedNavbarButton" : ""
      }`}
      id={id}
    >
      <BaseButton
        callback={callback}
        text={<img alt={txt} src={icon} className="navbarIcon" id={id} />}
        form={`navbarRounded ${selected === id ? "selectedNavbarRounded" : ""}`}
        id={id}
      />
      <p className="textNavbarButton" id={id}>
        {txt}
      </p>
    </div>
  );
};

const PopupButton = ({ text, color, callback }) => {
  return (
    <BaseButton callback={callback} text={text} form={`popupBase ${color}`} />
  );
};

const ContainerButton = ({ txt, callback }) => {
  return <BaseButton text={txt} callback={callback} form="containerButton" />;
};

const ProfileButton = ({ type, info, callback }) => {
  return (
    <BaseButton
      text={
        <div className="user-data">
          <div className="data-info">{info}</div>
          <div className="data-type">{type}</div>
        </div>
      }
      callback={callback}
      form="profileButton"
    />
  );
};

const AddSquareButton = ({ callback }) => {
  return (
    <BaseButton
      text={
        <img
          alt={"ADD_ICON_SVG"}
          src={ADD_ICON_SVG}
          className="addSquareIcon"
        />
      }
      callback={callback}
      form="addSquare"
    />
  );
};

const TwoOptionsButtons = ({
  text,
  icon,
  accessDefaultValue = false,
  callback,
}) => {
  const hasAccess = () => callback(true);

  const accessDenied = () => callback(false);

  return (
    <>
      <div className="tob-icon-container">
        <img className="tob-icon" src={icon} alt="icono" />
      </div>
      <div className="tob-text">{text}</div>
      <div className="tob-buttons-container">
        <BaseButton
          text="Si"
          form={`tob-btn tob-btn-left ${
            accessDefaultValue ? "btn-sected" : null
          }`}
          callback={hasAccess}
        />
        <BaseButton
          text="No"
          form={`tob-btn tob-btn-right ${
            !accessDefaultValue ? "btn-sected" : null
          } `}
          callback={accessDenied}
        />
      </div>
    </>
  );
};

const CalendarDayButton = ({
  callback,
  selected,
  numberCalendar,
  monthCalendar,
  id,
}) => {
  return (
    <div
      className={`containerCalendarDayBtn ${
        selected ? "selectedCalendarDayBtn" : ""
      }`}
      id={id}
    >
      <BaseButton
        callback={callback}
        text={
          <>
            <div className="number-calendar-day">{numberCalendar}</div>
            <div className="month-calendar-day">{monthCalendar}</div>
          </>
        }
        form={`calendar-item-btn ${
          selected ? "selected-calendar-item-btn" : ""
        }`}
        id={id}
      />
    </div>
  );
};

const OptionButton = ({ text, id, callback }) => {
  const [selected, setSelected] = useState(false);

  const handleSelected = () => {
    setSelected(!selected);
    callback(id);
  };

  return (
    <div>
      <BaseButton
        callback={handleSelected}
        text={text}
        form={` base-option-button ${
          selected ? "selected-option-button" : "option-button "
        }`}
        id={id}
      />
    </div>
  );
};

const AssistantsButton = ({ callback, number, txt, style, icon }) => {
  return (
    <BaseButton
      form={`assistants-button ${style}`}
      callback={callback}
      text={
        <div className="assistants-button-container">
          <div className="ab-icon-container">
            <img className="ab-icon" src={icon} />
          </div>
          <div className="ab-text">{txt}</div>
          <div className="ab-number">{number === 0 ? "" : `( ${number} )`}</div>
        </div>
      }
    />
  );
};

const NewIconButton = ({ callback, txt, style, icon }) => {
  return (
    <BaseButton
      form={`assistants-button ${style}`}
      callback={callback}
      text={
        <div className="new-primary-button-container">
          <div className="new-icon-button-container">
            <img className="ab-icon" src={icon} />
          </div>
          <div className="ab-text">{txt}</div>
        </div>
      }
    />
  );
};

const WhatsappButton = ({ moreValues, callback }) => {
  return (
    <BaseButton
      id="popupMenu"
      text={
        <img
          src={WHATSAPP_ICON_SVG}
          className="whatsapp-btn-icon"
          alt={"ADD_ICON_SVG"}
        />
      }
      callback={callback}
      form="whatsappButton"
    />
  );
};

const SeguimientoOptionsButton = ({ moreValues }) => {
  const [selected, setSelect] = useState(false);

  const handlePopup = (e) => {
    setSelect(!selected);
    e.stopPropagation();
  };

  const closePopup = () => {
    setSelect(false);
  };

  return selected ? (
    <div className="show-menu-portal" onClick={closePopup}>
      <PopupSeguimientoMenuNavbar
        values={moreValues}
        show={selected}
        id="popupMenu"
      />
      <BaseButton
        id="popupMenu"
        text={
          <img src={MORE_ICON_SVG} className="moreIcon" alt={"MORE_ICON_SVG"} />
        }
        callback={handlePopup}
        form="seguimiento-button-portal-show"
      />
    </div>
  ) : (
    <>
      <PopupMenuNavbar values={moreValues} show={selected} id="popupMenu" />
      <BaseButton
        id="popupMenu"
        text={
          <img src={MORE_ICON_SVG} className="moreIcon" alt={"MORE_ICON_SVG"} />
        }
        callback={handlePopup}
        form="seguimientoButton"
      />
    </>
  );
};

function BtnPayPayU({ serviceId, description, pay, email, completed = false }) {
  const SERVICE_ID = `${serviceId}`;
  const UNIKE_KEY_MERCHANT_ID = "772276";
  const UNIKE_KEY_ACCOUNT_ID = "778978";
  const DESCRIPTION = `${description}`;
  const CODE_SERVICE = `${Date.now()}~${SERVICE_ID}`;
  const PAY_AMMOUNT = `${parseInt(pay)}`;
  const SIGNATURE_HASH = md5(
    `Glg8XSws0HVTnF2vzZCD7j7nR5~${UNIKE_KEY_MERCHANT_ID}~${CODE_SERVICE}~${PAY_AMMOUNT}~COP`
  );
  const EMAIL_CUSTOMER = `${email}`;
  const REDIRECT_PAY = `https://us-central1-justo-pago.cloudfunctions.net/app/services/${SERVICE_ID}/pay`;

  return (
    <form
      method="post"
      action="https://checkout.payulatam.com/ppp-web-gateway-payu"
    >
      <input name="merchantId" type="hidden" value={UNIKE_KEY_MERCHANT_ID} />
      <input name="accountId" type="hidden" value={UNIKE_KEY_ACCOUNT_ID} />
      <input name="description" type="hidden" value={DESCRIPTION} />
      <input name="referenceCode" type="hidden" value={CODE_SERVICE} />
      <input name="amount" type="hidden" value={PAY_AMMOUNT} />
      <input name="tax" type="hidden" value="0" />
      <input name="taxReturnBase" type="hidden" value="0" />
      <input name="currency" type="hidden" value="COP" />
      <input name="signature" type="hidden" value={SIGNATURE_HASH} />
      <input name="buyerEmail" type="hidden" value={EMAIL_CUSTOMER} />
      <input
        name="responseUrl"
        type="hidden"
        value="https://app.justopago.com.co/app"
      />
      <input name="confirmationUrl" type="hidden" value={REDIRECT_PAY} />
      <input
        name="Submit"
        type="submit"
        value={!completed ? "Generar anticipo" : "Completar pago"}
        className={`assistants-button baseBtn payu-button ${
          !completed ? "" : "completed-pay-btn"
        }`}
      />
    </form>
  );
}

export {
  BaseButton,
  PrimaryButton,
  IconButton,
  CategoryRoundedButton,
  MenuRoundedButton,
  AddRoundedButton,
  MoreRoundedButton,
  NavbarButton,
  PopupButton,
  ContainerButton,
  ProfileButton,
  NextRoundedButton,
  AddSquareButton,
  TwoOptionsButtons,
  StyledPrimaryButton,
  CalendarDayButton,
  OptionButton,
  AssistantsButton,
  NewIconButton,
  WhatsappButton,
  SeguimientoOptionsButton,
  BtnPayPayU,
};
