const PRINCIPAL_PATH = "/";
const APP_PATH = "/app";
const AUTH_PATH = "/auth";
const AUTH_PATH_TOKEN = "/login/:token";
const STEPS_PATH = "/steps";

const REGISTER_TYPE = "register-type";

const USER_TYPE_PATH = "/form/user/type";
const SERVICE_PROVIDER_TYPE_PATH = "/form/service-provider/type";
const BASE_USER_TYPE_PATH = "/form/user/";
const USER_FORM_PATH = "/form/user/:id";

const PROPERTY_TYPE_PATH = "/form/property/type";
const BASE_PROPERTY_TYPE_PATH = "/form/property/";
const PROPERTY_FORM_PATH = "/form/property/:id";

const PROPERTY_EDIT_PATH = "/property/edit";

const MENU_PATH = "/menu/home";
const BASE_MENU_PATH = "/menu/";
const MENU_OPTION_PATH = "/menu/:id";

//OPTIONS BY USER_FORM_PATH
const COMPANY = "company";
const PARTICULAR = "particular";
const REAL_STATE = "real-estate";
const INSURANCE = "insurance";
const HORIZONTAL_HOUSE = "horizontal-house";
const HORIZONTAL_PROPERTY = "horizontal-property";
const OTHER_USER = "other-user";

//MANAGEMENTS
const REAL_STATE_PERSONAL = "real-estate-personal";
const INSURANCE_PERSONAL = "insurance-personal";
const COMPANY_PERSONAL = "company-personal";
const HOUSE_PERSONAL = "horizontal-house-personal";
const PROPERTY_PERSONAL = "horizontal-property-personal";
const NEW_PERSONAL = "new-personal";

//OPTIONS BY PROPERTY_FORM_PATH
const HOUSE = "house";
const APARTMENT = "apartment";
const LOCAL = "local";
const WAREHOUSE = "warehouse";
const CONDOMINIUM = "house-condominium";
const OFFICE = "office";
const OTHER_PROPERTY = "other-property";

//OPTIONS BY MENU_PATH
const SERVICES = "services";
const PROFILE = "profile";
const NOTIFICATIONS = "notifications";
const HOME = "home";
const NEW_SERVICE = "new-service";
const SERVICES_DETAILS = "details";
const REQUEST_SENT = "request-sent";
const SCHEDULE_SERVICE = "services-schedule";
const DETAILS = "/details";
const CLIENT_SERVICE_DETAILS = "/details/:id/:stage";

//OPTIONS TYPE OF SERVICES PROVIDER

const BASE_PS_TYPE_PATH = "/form/service-provider/";
const SERVICE_PROVIDER_BASIC_INFO_FORM_PATH =
  "/form/service-provider/:id/basic-info";
const PS_CONTACT_INFO_FORM_PATH = "/form/service-provider/:id/contact-info";
const PS_SPECIALITY_INFO_FORM_PATH =
  "/form/service-provider/:id/speciality-info";

const PS_BASE_MENU_PATH = "/service-provider/menu/";
const PS_MENU_PATH = "/service-provider/menu/home";
const PS_MENU_OPTION_PATH = "/service-provider/menu/:id";

const SUPERVISOR_TYPE_PATH = "supervisor";
const OFFICIAL_TYPE_PATH = "official";
const ASSISTANT_TYPE_PATH = "assistant";
const CARPENTRY_LEADER = "carpentry-leader";
const CARPENTRY_ASSISTANT = "carpentry-assistant";
const CFO = "cfo";
const ADMIN_COORDINATOR = "admin-coordinator";
const ADMIN_ASISTANT = "admin-asistant";
const HUMAN_RESOURCES = "human-resources";
const CSO = "cso";
const PLANEATION_COORDINATOR = "planeation-coordinator";
const PLANEATION_ASISTANT = "planeation-asistant";
const DOCUMENTATION_ASISTANT = "documentation-asistant";
const PLANEATION_AUXILIARY = "planeation-auxiliary";
const POST_SOLD_AUXILIARY = "post-sold-auxiliary";
const DESIGN_COORDINATOR = "design-coordinator";
const ARCHITECT_DESIGN = "architect-design";
const DESIGN_AUXILIARY = "design-auxiliary";
const COMERCIAL_DIRECTOR = "comercial-director";
const COMERCIAL_COORDINATOR = "comercial-coordinator";
const COMERCIAL_ASISTANT = "comercial-asistant";
const COO = "coo";
const OPERATION_COORDINATOR = "operation-coordinator";
const CARPENTRY_COORDINATOR = "carpentry-coordinator";
const RESIDENT = "resident";
const MARKETING_COORDINATOR = "marketing-coordinator";

const PS_BASIC_INFO = "/basic-info";
const PS_CONTACT_INFO = "/contact-info";
const PS_SPECIALITY_INFO = "/speciality-info";

const PS_SERVICES = "services";
const PS_BITACORA = "bitacora";
const PS_NOTIFICATIONS = "notifications";
const PS_PROFILE = "profile";

const PS_MY_SERVICES = "my-services";
const PS_REQUESTS = "requests";
const PS_MY_SERVICES_SEARCH = "my-services-search";

const PS_REQUEST_DETAILS = "request-details";
const PS_NOTIFICATION_DETAILS = "notification-details";

const PS_BASE_CASE = "/service-provider/my-services/";
const PS_CASE = "/service-provider/my-services/:id";

const PS_BASE_COTIZACION = "/service-provider/cotizacion/:id/:state";
const PS_COTIZACION = "/service-provider/cotizacion/";
const PS_COTIZACION_FORM = "/form/item";
const PS_COTIZACION_GENERAL = "/general";
const PS_COTIZACION_EDIT = "/edit";
const PS_CASE_BITACLE_VIEW = "/services/bitacle/:idService";
const PS_CASE_BITACLE_ENTRY_VIEW = "/services/entry/:idService";
const PS_CASE_BITACLE_UNEXPECTED_VIEW = "/services/newUnexpected/:idService";

const CASE_BITACLE_ENTRY_INFO = "/services/info/entry/:idService/:idEntry";
const CHAT_ENTRY = "/services/chat/entry/:idService/:idEntry";

const PS_GOALS = "my-goals";
const PS_DOCUMENTATION = "my-documentation";

const MANAGEMENT_EDIT_PATH = "/management/edit";
export const ALL_SERVICES_ADDED = "/all-services-added";
export const NEW_ADVANCE_DETAIL = "/new-advance-detail/:id";
export const CHECK_QR_CODE = "/check-qr-code";
export const DELIVERY_PROGRESS = "/delivery-progress";
const ROUTE_MAP = "/route-map";
export const ROUTE_TIMELINE = "/route-timeline";
export const ADVANCE_INVENTORY = "/advance-inventory";
export const ORDER_ADDITIONAL_MATERIAL = "/order-additional-material";
export const ORDER_CLEANING_SERVICE = "/order-cleaning-service";
export const ORDER_DEBRIS_REMOVAL = "/order-debris-removal";

export {
  ADMIN_ASISTANT,
  ADMIN_COORDINATOR,
  APARTMENT,
  APP_PATH,
  ARCHITECT_DESIGN,
  ASSISTANT_TYPE_PATH,
  AUTH_PATH,
  AUTH_PATH_TOKEN,
  BASE_MENU_PATH,
  BASE_PROPERTY_TYPE_PATH,
  BASE_PS_TYPE_PATH,
  BASE_USER_TYPE_PATH,
  CARPENTRY_ASSISTANT,
  CARPENTRY_COORDINATOR,
  //NEW ROLES
  CARPENTRY_LEADER,
  CASE_BITACLE_ENTRY_INFO, CFO,
  CHAT_ENTRY,
  CLIENT_SERVICE_DETAILS,
  COMERCIAL_ASISTANT,
  COMERCIAL_COORDINATOR,
  COMERCIAL_DIRECTOR,
  COMPANY,
  COMPANY_PERSONAL,
  CONDOMINIUM,
  COO,
  CSO,
  DESIGN_AUXILIARY,
  DESIGN_COORDINATOR,
  DETAILS,
  DOCUMENTATION_ASISTANT,
  HOME,
  HORIZONTAL_HOUSE,
  HORIZONTAL_PROPERTY,
  HOUSE,
  HOUSE_PERSONAL,
  HUMAN_RESOURCES,
  INSURANCE,
  INSURANCE_PERSONAL,
  LOCAL,
  MANAGEMENT_EDIT_PATH,
  MARKETING_COORDINATOR,
  MENU_OPTION_PATH,
  MENU_PATH,
  NEW_PERSONAL,
  NEW_SERVICE,
  NOTIFICATIONS,
  OFFICE,
  OFFICIAL_TYPE_PATH,
  OPERATION_COORDINATOR,
  OTHER_PROPERTY,
  OTHER_USER,
  PARTICULAR,
  PLANEATION_ASISTANT,
  PLANEATION_AUXILIARY,
  PLANEATION_COORDINATOR,
  POST_SOLD_AUXILIARY,
  PRINCIPAL_PATH,
  PROFILE,
  PROPERTY_EDIT_PATH,
  PROPERTY_FORM_PATH,
  PROPERTY_PERSONAL,
  PROPERTY_TYPE_PATH,
  PS_BASE_CASE,
  PS_BASE_COTIZACION,
  PS_BASE_MENU_PATH,
  PS_BASIC_INFO,
  PS_BITACORA,
  PS_CASE,
  PS_CASE_BITACLE_ENTRY_VIEW,
  PS_CASE_BITACLE_UNEXPECTED_VIEW,
  PS_CASE_BITACLE_VIEW,
  PS_CONTACT_INFO,
  PS_CONTACT_INFO_FORM_PATH,
  PS_COTIZACION,
  PS_COTIZACION_EDIT,
  PS_COTIZACION_FORM,
  PS_COTIZACION_GENERAL,
  PS_DOCUMENTATION,
  PS_GOALS,
  PS_MENU_OPTION_PATH,
  PS_MENU_PATH,
  PS_MY_SERVICES,
  PS_MY_SERVICES_SEARCH,
  PS_NOTIFICATIONS,
  PS_NOTIFICATION_DETAILS,
  PS_PROFILE,
  PS_REQUESTS,
  PS_REQUEST_DETAILS,
  PS_SERVICES,
  PS_SPECIALITY_INFO,
  PS_SPECIALITY_INFO_FORM_PATH,
  REAL_STATE,
  REAL_STATE_PERSONAL,
  REGISTER_TYPE,
  REQUEST_SENT,
  RESIDENT, ROUTE_MAP, SCHEDULE_SERVICE,
  SERVICES,
  SERVICES_DETAILS,
  SERVICE_PROVIDER_BASIC_INFO_FORM_PATH,
  SERVICE_PROVIDER_TYPE_PATH,
  STEPS_PATH,
  SUPERVISOR_TYPE_PATH,
  USER_FORM_PATH,
  USER_TYPE_PATH,
  WAREHOUSE
};

