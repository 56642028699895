import BELL_ICON_SVG from "assets/icons/svg/bell.svg";
import { AdminPopup } from "components/atoms/popup/Popup";
import { isEqual } from "lodash";
import { memo } from "react";
import { NOTIFICATIONS } from "router/routes";
const NotifiationIcon = ({
  showNotifications,
  showNoti,
  userType,
  notificationsUnread,
}) => {
  return (
    <>
      <div className="icons-container noti">
        <img
          src={BELL_ICON_SVG}
          alt=""
          className="notifications-icon-menu"
          id={NOTIFICATIONS}
          onClick={showNotifications}
        />
        <div
          style={{
            display: notificationsUnread > 0 ? "block" : "none",
            // top: 0,
            left: "2%",
          }}
          className={`${notificationsUnread > 0 ? "pulse-new-message" : ""}`}
        ></div>
        <AdminPopup show={showNoti} userType={userType} />
      </div>
    </>
  );
};

export default memo(NotifiationIcon, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
