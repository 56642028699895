import { Button, Form, Input, InputNumber, Slider, Switch, message, notification } from "antd";
import { FloatingPanel } from "antd-mobile";
import { ReactComponent as XIcon } from "assets/icons/svg/x.svg";
import InputFile from "components/InputFile/InputFile";
import useReduxState from "custom-hooks/useReduxState";
import { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { changeNewAdvanceFormOpen } from "reducers/new.advance.detail";
import { categoriesService } from "res/constans";
import { createAdvance } from "services-controller/http.cleinte.controller";
import { uploadMultipleEvidenceResources } from "services-controller/storage";
import "./NewAdvanceForm.css";

export default function NewAdvanceForm({ refreshData = () => { } }) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [, dispatch] = useReduxState({ changeNewAdvanceFormOpen }, "new_advance_detail");
  const selectedActivityForm = useSelector((state) => state.new_advance_detail.selectedActivityForm, shallowEqual);
  const newAdvanceFormIsOpen = useSelector((state) => state.new_advance_detail.newAdvanceFormIsOpen, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const categoryService = useMemo(() => {
    return categoriesService.find(
      (item) => item.key === selectedActivityForm.cat
    )
  }, [selectedActivityForm.cat]);
  const [imagesList, setImagesList] = useState([]);
  const [load, setLoad] = useState(false);
  const floatingPanelRef = useRef(null)
  const advanceValueWatch = Form.useWatch("advance_value", form);
  const [makeComment, setMakeComment] = useState(false)
  const activityValue = Math.round(selectedActivityForm.value)

  const onSubmit = async () => {
    const dataForm = form.getFieldsValue();
    setLoad(true);

    if (dataForm.percent * 100 < selectedActivityForm.completed * 100) {
      messageApi.error(
        "El porcentaje de avance no puede ser menor al avance actual"
      );
      setLoad(false);
      return;
    }

    if (imagesList.length < 1) {
      messageApi.error("Por favor sube al menos una imagen");
      setLoad(false);
      return;
    }

    const data = {
      ...dataForm,
      serviceId: selectedActivityForm.serviceId,
      activityId: selectedActivityForm.activityId,
      percent: (advanceValueWatch / selectedActivityForm.value * 100) / 100,
      advanced_value: advanceValueWatch,
      images: imagesList,
      emisorId: user.id,
      emisor: {
        name: user.register_data.name,
        email: user.register_data.contact.email_associated,
        user_type: user.user_type,
        thumbnail: user.register_data.thumbnail,
      },
      comment: dataForm.comment ?? "",
    };

    const uploadImages = async () => {
      const response = await uploadMultipleEvidenceResources(data.images);
      const images = response.map((item) => item.url);
      return images;
    };

    try {
      const imageUrls = await uploadImages();
      const updatedImagesList = imagesList.map((image, index) => ({
        ...image,
        url: imageUrls[index],
      }));

      const updatedData = {
        ...data,
        images: updatedImagesList,
      };

      setImagesList([]);

      //send advance
      await createAdvance(updatedData);
      setLoad(false);
      dispatch.changeNewAdvanceFormOpen(false)
      notification.open({
        description: "Avance realizado con éxito",
        type: "success",
      });

      await refreshData()
    } catch (error) {
      setLoad(false);
      messageApi.error("Error al subir las imágenes");
      console.error(error);
    }
  };

  const onDeleteImage = (index) => {
    const newImagesList = imagesList.filter((_, i) => i !== index);
    setImagesList(newImagesList);
  };

  const AdvanceComponent = ({ unit }) => {
    const colorPercent = (advanceValueWatch / selectedActivityForm.value * 100) / 100

    switch (unit) {
      case "und":
        return (
          <section className="fixed-cuantity-wrapper">
            <Slider
              styles={{
                track: {
                  ...(colorPercent >= 0.5 && colorPercent < 1 && { backgroundColor: "#1677ff" }),
                  ...(colorPercent >= 1 && { backgroundColor: "#52c41a" }),
                }
              }}
              defaultValue={advanceValueWatch}
              onChangeComplete={(newValue) => {
                form.setFieldsValue({ advance_value: newValue })
              }}
              className="slider-cuantity-selector"
              autoFocus
              max={activityValue}
            />

            <div className="quantity-selector">
              <button
                className="quantity-button"
                onClick={() => {
                  form.setFieldsValue({ advance_value: advanceValueWatch - 1 });
                }}
                disabled={advanceValueWatch <= 0}
                type="button"
              >
                -
              </button>

              <InputNumber
                type="text"
                value={Math.round(advanceValueWatch)}
                className="quantity-input"
                onChange={(value) => {
                  if (!value) {
                    form.setFieldsValue({ advance_value: 0 });
                    return;
                  }

                  form.setFieldsValue({ advance_value: value });
                }}
                onPressEnter={(event) => {
                  event.preventDefault();
                }}
                min={0}
                max={activityValue}
                autoFocus
              />

              <button
                className="quantity-button"
                onClick={() => {
                  form.setFieldsValue({ advance_value: advanceValueWatch + 1 });
                }}
                disabled={advanceValueWatch >= activityValue}
                type="button"
              >
                +
              </button>
            </div>
          </section>
        )

      default:
        return (
          <div className="percentage-container">
            <Slider
              styles={{
                track: {
                  ...(colorPercent >= 0.5 && colorPercent < 1 && { backgroundColor: "#1677ff" }),
                  ...(colorPercent >= 1 && { backgroundColor: "#52c41a" }),
                }
              }}
              defaultValue={advanceValueWatch}
              onChangeComplete={(newValue) => {
                form.setFieldsValue({ advance_value: newValue });
              }}
              autoFocus
              max={activityValue}
            />

            <InputNumber
              min={0}
              max={activityValue}
              value={advanceValueWatch}
              addonAfter={selectedActivityForm.labor_cost?.unit}
              onChange={(value) => {
                form.setFieldsValue({ advance_value: value });
              }}
              size="large"
              autoFocus
            />
          </div>
        )
    }
  }

  useEffect(() => {
    if (!newAdvanceFormIsOpen) {
      floatingPanelRef.current?.setHeight(0)
    }

    if (newAdvanceFormIsOpen) {
      floatingPanelRef.current?.setHeight(window.innerHeight * 0.8)
    }

    const advanceValue = Math.floor((selectedActivityForm?.completed * 100) * selectedActivityForm?.value / 100)
    form.setFieldsValue({ advance_value: advanceValue });

  }, [newAdvanceFormIsOpen])

  return (
    <FloatingPanel
      anchors={[
        newAdvanceFormIsOpen ? 100 : 0,
        window.innerHeight * 0.2,
        window.innerHeight * 0.8,
      ]}
      ref={floatingPanelRef}
      className="floating-panel-new-advance-form"
    >
      <main className="new-advance-wrapper">
        <header className="new-advance-form-header">
          <Button
            className="close-btn-floating-panel"
            onClick={() => {
              dispatch.changeNewAdvanceFormOpen(false)
              form.resetFields()
            }}
            icon={<XIcon />}
            size="large"
            type="primary"
          >
          </Button>

          {selectedActivityForm?.name && (
            <div className="advance-title">
              <h1>{selectedActivityForm.name}</h1>
              {categoryService?.icon && <categoryService.icon />}
            </div>
          )}
        </header>

        <Form form={form} className="update-form" onFinish={onSubmit} clearOnDestroy>
          <Form.Item
            label={"Cantidad de avance - Máximo: " + activityValue + " " + selectedActivityForm.labor_cost?.unit}
            name="advance_value"
            rules={[
              { required: true, message: "Por favor modifique este valor" },
            ]}
            className="percentage-update"
          >
            <AdvanceComponent unit={selectedActivityForm.labor_cost?.unit} />

            {(
              advanceValueWatch >= (activityValue / 2) &&
              advanceValueWatch !== activityValue
            ) && (
                <Button
                  size="large"
                  className="complete-all-btn"
                  onClick={() => {
                    form.setFieldsValue({ advance_value: selectedActivityForm.value });
                  }}
                >
                  Completar toda la actividad
                </Button>
              )}
          </Form.Item>

          <article className="add-comment-wrapper">
            <header>
              <span>¿Quisieras agregar un comentario?</span>

              <Switch
                defaultChecked={makeComment}
                onChange={(checked) => {
                  setMakeComment(checked)
                }}
                rootClassName="switch-comment"
              />
            </header>

            {makeComment && (
              <Form.Item name="comment">
                <Input.TextArea
                  className="optional-comment"
                  placeholder="Describe a detalle el avance que has realizado en la obra, explicate lo mejor posible ..."
                />
              </Form.Item>
            )}
          </article>

          <article className="add-media-wrapper">
            <span>Agrega algunas fotos</span>

            <InputFile
              images={imagesList}
              onAddFiles={setImagesList}
              filesAccept={[".png", ".jpg", ".heic", ".jpeg"]}
              deleteMediaSource={onDeleteImage}
            />
          </article>

          <Button loading={load} htmlType="submit" size="large" type="primary">
            Guardar avance
          </Button>
        </Form>
      </main>

      {contextHolder}
    </FloatingPanel>
  )
}
