import React from "react";
import { camelCase } from "res/utils";

const HeaderMessage = ({ isMine, name }) => {
  return (
    <div className={`top-info-container${isMine}`}>
      <div className="comment-user-info">
        <div className="comment-user-name">{camelCase(name)}</div>
      </div>
    </div>
  );
};

export default HeaderMessage;
