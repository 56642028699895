import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Appbar } from "../../../../components/atoms/appbar/Appbar";
import PsButtonNavbar from "../../../../components/molecules/buttonNavbar/PsButtonNavbar";
import ServiceProviderMenuDesktop from "../../../../components/molecules/menu/ServiceProviderMenu";
import { RequestsInformation } from "../../../../components/organisms/information/Information";
import { PS_SERVICES } from "../../../../router/routes";
import "../psServices.css";

const PsMyRequests = ({
  navigatorDetails,
  handleTakeService,
  handleRejectService,
}) => {
  const servicesRequests = useSelector(
    (state) => state.services_list.servicesList
  );

  return (
    <div className="usertype-main-container appbar-no-background">
      <ServiceProviderMenuDesktop page={PS_SERVICES} />

      <div className="ps-services-list-container">
        <div className="new-service-appbar-container">
          <Appbar smallText="Solicitudes de" bigText="Servicios" />
        </div>

        <div className="my-requests-container">
          <RequestsInformation
            arr={servicesRequests}
            callbackAceptar={handleTakeService}
            callbackRechazar={handleRejectService}
            callbackDetails={navigatorDetails}
          />
        </div>

        <div className="menu-navbar-container">
          <PsButtonNavbar page={PS_SERVICES} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(PsMyRequests);
