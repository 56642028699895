import React from "react";
import { camelCase } from "res/utils";

const EntryTitle = ({ activities, title }) => {
  return (
    <div className="cei-header-title">
      {activities
        ? activities.map((activity, index) => {
            if (index >= 3) return null;
            else
              return (
                <div className="title-entrie" key={index}>
                  <span key={index}>{camelCase(activity.title)} </span>
                </div>
              );
          })
        : title}
    </div>
  );
};

export default EntryTitle;
