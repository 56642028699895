import React from 'react'
import { TwoButtonsPopup, OneButtonsPopup } from '../components/atoms/popup/Popup'


const SuccessRegister = ({ object }) => {

    const {
        callbackMenu,
        callbackRegistrProperty,
    } = object

    return (
        <>
            <TwoButtonsPopup
                text='Registro de Usuario Exitoso'
                btnOneText='Registrar Propiedad'
                btnTwoText='Ir a Menu'
                btnOneColor='btnYellow'
                btnTwoColor='btnYellow'
                callbackOne={callbackRegistrProperty}
                callbackTwo={callbackMenu} />
        </>
    )
}

const ErrorRegister = ({ object }) => {

    const {
        callback,
        message,

    } = object

    return (
        <>
            <OneButtonsPopup
                text={message}
                btnOneText='De acuerdo'
                btnOneColor='btnYellow'
                callbackOne={() => callback(false)} />
        </>
    )
}

export {
    SuccessRegister,
    ErrorRegister
}