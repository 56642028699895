import React from "react";
import ExcelPreview from "../../components/atoms/excelPreview/ExcelPreview";
import PdfPreview from "../../components/atoms/pdfPreview/PdfPreview";
import LazyLoad from "./LazyComponent";
import ImagePreview from "../../components/atoms/imgPreview/ImagePreview";
const ComponentResource = (evidence) => {
  switch (evidence.type) {
    case "pdf":
      return ({ url, name }) => <PdfPreview title={name} pdfUrl={url} />;

    case "excel":
      return ({ url, name }) => <ExcelPreview title={name} excelUrl={url} />;

    case "image":
      return ({ url, name }) => <ImagePreview evidence={evidence} />;

    default:
      return ({ url, name, key }) => null;
  }
};

const BuildSources = ({ key, evidence, scrollPosition }) => {
  return (
    <LazyLoad
      key={key}
      resource={evidence}
      src={evidence.url}
      alt={evidence.name}
      scrollPosition={scrollPosition}
      Component={ComponentResource(evidence)}
    />
  );
};
export default BuildSources;
