import React from "react";
import SwipeableButton from "./SwipeableButton";

export function formatDuration(seconds) {
  const totalMinutes = Math.round(seconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0) {
    return `${hours} hr ${minutes} min`;
  } else {
    return `${minutes} min`;
  }
}

export function formatDistance(meters) {
  if (meters >= 1000) {
    const kilometers = meters / 1000;
    return `${kilometers.toFixed(2)} km`;
  } else {
    return `${Math.round(meters)} m`;
  }
}

const HeaderRouteInfo = ({ info, callbackRoute }) => {
  const handleCallbackRoute = () => {
    setTimeout(() => {
      callbackRoute();
    }, 2000);
  };

  return (
    <div className="route-info-container">
      <div>
        <span>
          <h1>{formatDuration(info.duration)}</h1>
          <p>{formatDistance(info.distance)}</p>
        </span>
        <SwipeableButton onSwipeSuccess={handleCallbackRoute} />
      </div>
    </div>
  );
};

export default HeaderRouteInfo;
