import {
  CheckSquareOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Tooltip } from "antd";
import JP_SUB_PICTURE from "assets/icons/png/jp-sub-picture.png";
import { Button, Modal } from "antd";
import { useState } from "react";
import { camelCase } from "res/utils";
import { pasedTime } from "utils/utils.notifications";

const PsNotificationsContainer = ({
  obj,
  onHandleNotiClick,
  handleRemoveById,
  handleMarkAsRead,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const name = obj?.emisor?.name || obj?.emisor?.register_data?.name;
  let avatar = obj?.emisor?.register_data?.thumbnail;
  avatar = !avatar ? JP_SUB_PICTURE : avatar;

  const nameParts = name?.split(" ");

  const displayName = nameParts?.slice(0, 2).join(" ");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleRemoveById(obj.unique_key, "notifications");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const notificationMessage = (name, service) => {
    return (
      <p>
        <strong style={{ fontWeight: "500" }}>{name}</strong> envío un nuevo
        mensaje en el servicio{" "}
        <strong style={{ fontWeight: "500" }}>{service}</strong>
      </p>
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <span style={{ display: "flex", gap: 6, fontSize: "12px" }}>
          <CheckSquareOutlined />
          Marcar como leído
        </span>
      ),
      onClick: () => {
        handleMarkAsRead(obj.unique_key);
      },
    },
    {
      key: "2",
      label: (
        <span style={{ display: "flex", gap: 6, fontSize: "12px" }}>
          {" "}
          <DeleteOutlined />
          Eliminar
        </span>
      ),
      onClick: () => {
        showModal();
      },
    },
  ];

  return (
    <div className={!obj?.read ? "spaceBetweenPsNotifications" : "noty-empty"}>
      <aside className="notification-container-box">
        <span className="avatar-container">
          <span className={!obj?.read ? "new-message-noti" : ""}></span>
          <Tooltip title={obj?.emisor?.name}>
            <Avatar src={avatar} />
          </Tooltip>
        </span>
        <span
          onClick={() => onHandleNotiClick(obj)}
          className="notification-container-info"
        >
          <p className="header-message-info">
            {notificationMessage(camelCase(displayName), obj?.serviceId)}
          </p>
          <p className="message-info">{obj?.message}</p>
        </span>
        <div className="options-noti">
          <p>{pasedTime(obj?.date)}</p>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
          >
            <EllipsisOutlined className="options-selector" />
          </Dropdown>
        </div>
      </aside>
      <Modal
        title={
          <span style={{ color: "#734662" }}>
            <DeleteOutlined style={{ color: "#FD941B", marginRight: 8 }} />
            Confirmar eliminación
          </span>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Eliminar
          </Button>,
        ]}
      >
        <p>¿Estás seguro de que quieres eliminar esta notificación?</p>
      </Modal>
    </div>
  );
};

export default PsNotificationsContainer;
