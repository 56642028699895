const UnexpectedButton = ({ unexpected, ALERT_ICON_SVG }) => {
  return (
    unexpected && (
      <img
        src={ALERT_ICON_SVG}
        className="unexpected-icon"
        title="Imprevisto"
        alt="imprevisto icon"
        //onClick={() => navigateUnexpected(obj.unexpected_id)}
      />
    )
  );
};

export default UnexpectedButton;
