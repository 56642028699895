import { IDENTIFIER_TYPE_DEFAULT_VALUE } from '../res/identifier.type'

const SET_REGISTER_TYPE_ACTION = 'set_register_type_form'
const SET_USER_TYPE_ACTION = 'set_user_type_form'
const SET_THUMBNAIL_TYPE_ACTION = 'set_thumbnail_type_form'
const SET_NAME_TYPE_ACTION = 'set_name_type_form'
const SET_PHONE_TYPE_ACTION = 'set_phone_type_form'
const SET_EMAIL_ASSOCIATED_TYPE_ACTION = 'set_email_associated_type_form'
const SET_IDENTIFIER_TYPE_ACTION = 'set_identifier_type_form'
const SET_PASS_TYPE_ACTION = 'set_pass_type_form'
const SET_USER_ACTION = 'set_update_user_type_form'
const SET_TYPE_IDENTIFIER_TYPE_ACTION = 'set_type_identifier_type_form'


const initialStateFormUser = {
    register_type: '',
    is_blocked:false,
    user_type: '',
    provider: '001',
    location: {
        country: {
            country_code: '57'
        },
        department: {
            department_code: '76'
        },
        city: {
            city_code: '001'
        }
    },
    register_data: {
        thumbnail: '',
        name: '',
        contact: {
            phone: '',
            email_associated: ''
        },
        secrets: {
            type_identifier: IDENTIFIER_TYPE_DEFAULT_VALUE,
            identifier: '',
            password: ''

        }
    }
}

const reducerFormUser = (state = initialStateFormUser , action) => {

    const { type, payload } = action

    switch (type) {
        case SET_REGISTER_TYPE_ACTION:
            return {
                ...state,
                register_type: payload
            }
        case SET_USER_TYPE_ACTION:
            return {
                ...state,
                user_type: payload
            }
        case SET_THUMBNAIL_TYPE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    thumbnail: payload
                }
            }
        }
        case SET_NAME_TYPE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    name: payload
                }
            }
        }
        case SET_PHONE_TYPE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                        phone: payload
                    }
                },
            }
        }
        case SET_EMAIL_ASSOCIATED_TYPE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                        email_associated: payload
                    }
                },
            }
        }
        case SET_IDENTIFIER_TYPE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                    },
                    secrets: {
                        ...state.register_data.secrets,
                        identifier: payload
                    }
                },
            }
        }
        case SET_TYPE_IDENTIFIER_TYPE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                    },
                    secrets: {
                        ...state.register_data.secrets,
                        type_identifier: payload
                    }
                },
            }
        }
        case SET_PASS_TYPE_ACTION: {
            return {
                ...state,
                register_data: {
                    ...state.register_data,
                    contact: {
                        ...state.register_data.contact,
                    },
                    secrets: {
                        ...state.register_data.secrets,
                        password: payload
                    }
                },
            }
        }
        case SET_USER_ACTION:
            return payload
        default:
            return state
    }
}

const changeRegisterType = (value) => {
    return { type: SET_REGISTER_TYPE_ACTION, payload: value }
}

const changeUserType = (value) => {
    return { type: SET_USER_TYPE_ACTION, payload: value }
}

const changeThumbnail = (value) => {
    return { type: SET_THUMBNAIL_TYPE_ACTION, payload: value }
}

const changeName = (value) => {
    return { type: SET_NAME_TYPE_ACTION, payload: value }
}

const changePhone = (value) => {
    return { type: SET_PHONE_TYPE_ACTION, payload: value }
}

const changeEmailAssociated = (value) => {
    return { type: SET_EMAIL_ASSOCIATED_TYPE_ACTION, payload: value }
}

const changeIdentifier = (value) => {
    return { type: SET_IDENTIFIER_TYPE_ACTION, payload: value }
}

const changeTypeIdentifier = (value) => {
    return { type: SET_TYPE_IDENTIFIER_TYPE_ACTION, payload: value }
}

const changePassword = (value) => {
    return { type: SET_PASS_TYPE_ACTION, payload: value }
}

const changeUser = (value) => {
    return { type: SET_USER_ACTION, payload: value }
}

export {
    initialStateFormUser,
    reducerFormUser,
    changeRegisterType,
    changeUserType,
    changeThumbnail,
    changeName,
    changePhone,
    changeEmailAssociated,
    changeIdentifier,
    changePassword,
    changeUser,
    changeTypeIdentifier
}