import React from 'react'
import {IconTwoButtonsPopup} from '../components/atoms/popup/Popup'


const PopupTwoOptionWithIcon = ({ object }) =>{
    const {
        callbackNegative,
        callbackPositive,
        textPositive,
        textNegative,
        popupText,
        popupIcon
    } = object

    return (
        <IconTwoButtonsPopup
        text={popupText}
        btnOneText={textNegative} 
        btnOneColor='btn-negative'
        callbackOne={callbackNegative} 
        btnTwoText={textPositive} 
        btnTwoColor='btn-positive'
        callbackTwo={callbackPositive}
        icon={popupIcon}
        />
    )
}

export default PopupTwoOptionWithIcon