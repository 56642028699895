import { useState } from "react";
import { withRouter } from "react-router-dom";
import { LoadingPopup } from "../../components/atoms/popup/Popup";
import StateButtonModalHook from "../../modal/StateButtonModalHook.jsx";
import PopupOneButton from "../../popups/PopupOneButton.jsx";
import { ErrorRegister } from "../../popups/SuccessRegister.jsx";
import { REGEX_EMAIL, REGEX_PASS } from "../../res/regex";
import {
  BASE_USER_TYPE_PATH,
  PRINCIPAL_PATH,
  REGISTER_TYPE,
} from "../../router/routes";
import {
  SingOutUser,
  forgotPassword,
  signInWithFacebook,
  signInWithGoogle,
  signWithEmailAndPassword,
} from "../../services-controller/auth";
import AuthView from "./AuthView.jsx";

// Contain at least 8 characters
// Contain at least 1 number
// Contain at least 1 lowercase character (a-z)
// Contain at least 1 uppercase character (A-Z)
// Contain only 0-9a-zA-Z

const AuthViewController = ({ history }) => {
  //state

  const [email, setEmail] = useState("");

  const [pass, setPass] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const [isErrorForm, setIsErrorForm] = useState(false);

  const stateErrorFormCallback = (state) => setIsErrorForm(state);

  const [errorMessage, setErrorMessage] = useState("");

  const [isResetPassword, setIsResetPassword] = useState(false);

  const [resetEmail, setResetEmail] = useState("");

  //general

  const callbackPushRegister = () =>
    history.push(`${BASE_USER_TYPE_PATH}${REGISTER_TYPE}`);

  const callbackPushLoading = () => history.push(PRINCIPAL_PATH);

  const callbackSiginWithFacebook = async () => {
    setIsLoader(true);
    const result = await signInWithFacebook();
    resultSigninEmailAndPass(true, result);
  };

  const callbackPushPassForward = async () => {
    setIsResetPassword(false);
    setIsLoader(true);
    try {
      await forgotPassword(resetEmail);
      showMessageErrorInPopUp(
        "Hemos enviado un mensaje a tu correo, por favor revísalo"
      );
    } catch (err) {
      console.log(err);
      showMessageErrorInPopUp(
        "No se ha podido recuperar la contraseña, por favor verifica tus datos e intenta nuevamente."
      );
    }
    setIsLoader(false);
  };

  // start -- signin with google

  const callbackSiginWithGoogle = async () => {
    setIsLoader(true);
    const result = await signInWithGoogle();
    resultSigninEmailAndPass(true, result);
  };

  // end -- signin with google

  // start -- signin email and pass

  const statusCode = (code) => {
    switch (code) {
      case 200:
        callbackPushLoading();
        break;
      case 400:
        showMessageErrorInPopUp("El correo ó contraseña no son válidos");
        break;
      default:
        showMessageErrorInPopUp("Ocurrio un problema, intenta nuevamente");
        break;
    }
    setIsLoader(false);
  };

  const approvedEmailAndPass = async (email, pass) => {
    const result = await signWithEmailAndPassword(email, pass);
    return result;
  };

  const showMessageErrorInPopUp = (message) => {
    setErrorMessage(message);
    stateErrorFormCallback(true);
    SingOutUser();
  };

  const resultSigninEmailAndPass = (approvedCredential, resul) => {
    if (!approvedCredential)
      showMessageErrorInPopUp("El correo ó contraseña no son válidos");
    else statusCode(resul.code);
    setIsLoader(false);
  };

  const callbackSiginWithEmailAndPass = async () => {
    setIsLoader(true);
    const emailValid = REGEX_EMAIL.test(email);
    const passValid = REGEX_PASS.test(pass);
    const approvedCredential = emailValid && passValid;
    const resul = await approvedEmailAndPass(email, pass);
    resultSigninEmailAndPass(approvedCredential, resul);
  };

  const callbackOnChangeEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
  };

  const callbackOnChangeResetEmail = (event) => {
    const value = event.target.value;
    setResetEmail(value);
  };

  const callbackOnChangePass = (event) => {
    const value = event.target.value;
    setPass(value);
  };

  // end -- signin email and pass

  const callbackResetPasswordPopup = () => {
    setIsResetPassword(true);
  };

  return (
    <>
      <AuthView
        defaultValueEmail={email}
        defaultValuePass={pass}
        callbackPushRegister={callbackPushRegister}
        callbackSiginWithFacebook={callbackSiginWithFacebook}
        callbackSiginWithGoogle={callbackSiginWithGoogle}
        callbackSiginWithEmailAndPass={callbackSiginWithEmailAndPass}
        callbackOnChangeEmail={callbackOnChangeEmail}
        callbackOnChangePass={callbackOnChangePass}
        callbackResetPasswordPopup={callbackResetPasswordPopup}
      />
      {/* error */}
      {isErrorForm ? (
        <StateButtonModalHook
          component={ErrorRegister}
          hook={[isErrorForm, setIsErrorForm]}
          object={{
            message: errorMessage,
            callback: stateErrorFormCallback,
          }}
        />
      ) : null}
      {isResetPassword && (
        <StateButtonModalHook
          component={PopupOneButton}
          hook={[isResetPassword, setIsResetPassword]}
          object={{
            callback: callbackPushPassForward,
            callbackOnChangeResetEmail: callbackOnChangeResetEmail,
            defaultValueEmail: resetEmail,
          }}
        />
      )}
      {/* loading */}
      <LoadingPopup state={isLoader} />
    </>
  );
};

export default withRouter(AuthViewController);
