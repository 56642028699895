import React from 'react'
import './newServices.css'
import { Appbar,AppbarLogo } from '../../components/atoms/appbar/Appbar'
import { PrimaryButton } from '../../components/atoms/button/Button'
import POINTING_ICON_SVG from '../../assets/icons/svg/finger-point.svg'
import CALENDAR_ICON_SVG from '../../assets/icons/svg/calendar.svg'
import BOOK_ICON_SVG from '../../assets/icons/svg/book.svg'
import ALERT_ICON_SVG from '../../assets/icons/svg/alert-triangle.svg'
import FROWN_ICON_SVG from '../../assets/icons/svg/frown.svg'
import { AviableTimeCard } from '../../components/atoms/card/Card'
import StyledCalendar from '../../components/calendar/StyledCalendar.jsx'
import { PRIORITY_MEDIUM, PRIORITY_HIGH, PRIORITY_PRIORITY } from '../../res/priority.type'
import { MEDIUM_CODE, HIGH_CODE, PRIORITY_CODE } from '../../router/codes.type.properties'
import CurrencyFormat from 'react-currency-format'

const NewServices = ({
    callbackGoNext,
    callbackSelectTypePriority,
    defaultValuePriority,
    providersSpecificTime,
    callbackSelectedTimeStart,
    defaultValueTimeStart,
    callbackSelectedDate,
    defalutValueDate,
    visitValue,
    sendAviable,
    disableDays
}) => {

    return (
        <div className='new-service-container'>

            <AppbarLogo />

            <div className='desktop-container'>

                <div className='new-service-appbar-container'>
                    <Appbar smallText='Solicitud de servicio' bigText='Día y hora' />
                </div>

                <div className='container-general'>

                    <div className='schedule-urgency-container'>

                        <div className='urgency-icon-container'>
                            <img src={POINTING_ICON_SVG} className='schedule-icons' alt={'POINTING_ICON_SVG'} />
                        </div>

                        <div className='urgency-title'>Urgencia</div>

                        <div className='urgency-types-container'>

                            <div
                                className={`urgency-types ${defaultValuePriority === MEDIUM_CODE ? 'urgency-type-selected' : ''}`}
                                id={MEDIUM_CODE}
                                onClick={callbackSelectTypePriority}>
                                {PRIORITY_MEDIUM}
                            </div>

                            <div
                                className={`urgency-types ${defaultValuePriority === HIGH_CODE ? 'urgency-type-selected' : ''}`}
                                id={HIGH_CODE}
                                onClick={callbackSelectTypePriority}>
                                {PRIORITY_HIGH}
                            </div>

                            <div
                                className={`urgency-types ${defaultValuePriority === PRIORITY_CODE ? 'urgency-type-selected' : ''}`}
                                id={PRIORITY_CODE}
                                onClick={callbackSelectTypePriority}>
                                {PRIORITY_PRIORITY}
                            </div>

                        </div>

                    </div>

                    {
                        defaultValuePriority === 2 ?
                            <div className='urgency-note-container'>
                                <div className='schedule-icon-container'>
                                    <img src={ALERT_ICON_SVG} className='urgency-note-icon' alt={'ALERT_ICON_SVG'} />
                                </div>
                                <div className='urgency-note'>La urgencia prioritaria tiene un costo por visita de <span> ${<CurrencyFormat value={visitValue} thousandSeparator={true} displayType={'text'} />}</span></div>
                            </div> :
                            null
                    }


                    <div className='schedule-calendar-container'>

                        <div className='schedule-info'>
                            <div className='schedule-icon-container'>
                                <img src={CALENDAR_ICON_SVG} className='schedule-icons' alt={'CALENDAR_ICON_SVG'} />
                            </div>
                            <div className='schedule-text'>Elegir día y hora</div>
                        </div>

                        <div className='schedule-calendar'>
                            <StyledCalendar
                                defalutValueDate={defalutValueDate}
                                callbackSelectedDate={callbackSelectedDate}
                                disableDays={disableDays} />
                        </div>

                    </div>

                    <div className='schedule-time-container'>

                        <div className='schedule-info'>
                            <div className='schedule-icon-container'>
                                <img src={BOOK_ICON_SVG} className='schedule-icons' alt={'BOOK_ICON_SVG'} />
                            </div>
                            <div className='schedule-text'>Horarios Disponibles</div>
                        </div>



                        {
                            providersSpecificTime.length !== 0 ?
                                <div className='schedule-time'>
                                    {providersSpecificTime.map((obj, index) =>
                                        <AviableTimeCard
                                            key={index}
                                            id={index}
                                            start={obj.inicio}
                                            end={obj.final}
                                            callback={() => callbackSelectedTimeStart(obj)}
                                            selected={defaultValueTimeStart} />
                                    )}
                                </div> :
                                <div className='no-assistants-container'>
                                    <img src={FROWN_ICON_SVG} className='no-entries-icon' />
                                    <div className='no-properties-title'>No hay horarios disponibles para la fecha seleccionado, por favor selecciona otra fecha </div>
                                </div>
                        }



                    </div>

                    {
                        sendAviable ?
                            <div className='next-step-container'>
                                <PrimaryButton txt='Enviar' callback={callbackGoNext} />
                            </div>
                            :
                            null
                    }


                </div>
            </div>
        </div>
    )
}

export default NewServices