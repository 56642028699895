const IDENTIFIER_TYPE_DEFAULT_VALUE = "Tipo de documento"

const IDENTIFIER_TYPE = [
    'CC',
    'CE',
    'PA',
    'RC',
    'TI',
    'NIT'
]

export {
    IDENTIFIER_TYPE,
    IDENTIFIER_TYPE_DEFAULT_VALUE
}