import EmojiPicker, {
  EmojiStyle,
  SkinTonePickerLocation,
} from "emoji-picker-react";
import "./emojipicker.css";
import { useState, useEffect } from "react";

const EmojiChat = ({ emojiHidden, setMessageText, messageText }) => {
  const [emoji, setEmoji] = useState("");

  const onClick = (EmojiClickData) => {
    setEmoji(EmojiClickData.emoji);
  };

  const handleClick = () => {
    const newMessageText = messageText + emoji;
    setMessageText(newMessageText);
  };

  useEffect(() => {
    if (emoji !== "") {
      handleClick();
    }
  }, [emoji]);

  return (
    <>
      {emojiHidden && (
        <div className="emoji-container">
          <EmojiPicker
            onEmojiClick={onClick}
            autoFocusSearch={true}
            emojiStyle={EmojiStyle.NATIVE}
            searchDisabled={true}
            skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
            height={200}
            width={"100%"}
            emojiVersion="1.6"
            lazyLoadEmojis={true}
            previewConfig={{
              defaultCaption: "Pick one!",
              defaultEmoji: "1f92a", // 🤪
              showPreview: false,
            }}
          />
        </div>
      )}
    </>
  );
};

export { EmojiChat };
