import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ServiceProviderProfile from "./ServiceProviderProfile.jsx";
import FLAG_ICON_SVG from "../../../assets/icons/svg/flag.svg";
import LOG_OUT_ICON_SVG from "../../../assets/icons/svg/log-out.svg";
import DOCUMENT_ICON_SVG from "../../../assets/icons/svg/file.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import { signOut } from "../../../services-firebase/auth";
import {
  PRINCIPAL_PATH,
  PS_GOALS,
  PS_DOCUMENTATION,
  PS_BASE_MENU_PATH,
} from "../../../router/routes";
import StateButtonModalHook from "../../../modal/StateButtonModalHook.jsx";
import PsAssistantsListController from "../../services-provider/ps-assistants/PsAssistantsListController.jsx";
import { convertTypeRegisterToNames } from "../../../res/convert.codes";
import {
  getAnalyticsServiceProvider,
  updateServiceProviderProfile,
} from "../../../services-controller/http.cleinte.controller";
import { getValueByKey } from "../../../persistent-managment/managment.persistent";
import { UID_AUTH } from "../../../persistent-managment/key.persistent";
import { uploadImageThumbnail } from "../../../services-controller/storage";
import uuidv5 from "uuid/v5";
import { changeRegisterDataThumbnail,changeUpdateFormServiceProvider } from "../../../reducers/form.service.provider";
import { ErrorRegister } from "../../../popups/SuccessRegister.jsx";
import { PHOTO_VALIDATION_ERROR_MESSAGE } from "../../../res/errors";
import { existUser } from "../../../services-controller/auth";
import {serviceProviderById} from '../../../services-controller/http.cleinte.controller'
import { LoadingPopup } from '../../../components/atoms/popup/Popup'
import { useDispatch, useSelector } from "react-redux";

const ServiceProviderProfileController = ({ history }) => {

  const dispatch = useDispatch()
  const form_service_provider = useSelector(state => state.form_service_provider);

  const {
    label,
    score,
    user_type,
    register_data: { name, thumbnail },
  } = form_service_provider;

  const [showSelectedHelpers, setShowSelectedHelpers] = useState(false);
  const [analytics, setAnalytics] = useState({
    accepted: 0,
    in_course: 0,
    finsihed: 0,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorForm, setIsErrorForm] = useState(false);

  const id = getValueByKey(UID_AUTH);

  const fetchAnalytics = async () => {
    const res = await getAnalyticsServiceProvider(id);
    setAnalytics(res);
    if(name===''){
      const user = await serviceProviderById(id)
      if(user){
        dispatch(changeUpdateFormServiceProvider(user.user))
      }
    }
  };

  useEffect(() => {
      if (!existUser()) {
          history.push(PRINCIPAL_PATH)
      } else {
          fetchAnalytics()
      }

      return () => {

      }
  }, [])

  const callbackLogOut = async () => {
    history.push(PRINCIPAL_PATH);
    await signOut();
  };

  const callbackDocumentation = () => {
    history.push(`${PS_BASE_MENU_PATH}${PS_DOCUMENTATION}`);
  };

  const callbackGoals = () => {
    history.push(`${PS_BASE_MENU_PATH}${PS_GOALS}`);
  };

  const callbackShowHelpers = () => {
    setShowSelectedHelpers(true);
  };

  const appbarButtonsContent = [
    {
      type: "Servicios aceptados ",
      info: analytics.accepted,
    },
    {
      type: "Servicios en curso",
      info: analytics.in_course,
    },
    {
      type: "Servicios concluidos",
      info: analytics.finsihed,
    },
  ];

  const menuButtonsContent = [
    {
      icon: FLAG_ICON_SVG,
      txt: "Metas",
      callback: callbackGoals,
    },
    {
      icon: DOCUMENT_ICON_SVG,
      txt: "Documentación",
      callback: callbackDocumentation,
    },
    {
      icon: USER_ICON_SVG,
      txt: "Ayudantes",
      callback: callbackShowHelpers,
    },
    {
      icon: LOG_OUT_ICON_SVG,
      txt: "Cerrar sesion",
      callback: callbackLogOut,
    },
  ];

  const callbackSelectImage = async (file) => {
    const uid = uuidv5(new Date().toLocaleString(), uuidv5.URL);
    const res = await uploadImageThumbnail(uid, file);
    const { code } = res;
    if (code === 200) {
      const { url } = res;

      const object = {
        ...form_service_provider,
        register_data: {
          ...form_service_provider.register_data,
          thumbnail: url,
        },
      };

      await updateServiceProviderProfile(id, object);

      dispatch(changeRegisterDataThumbnail(url));
    }
  };

  const callbackSelectImageError = (_) => {
    showModalError(PHOTO_VALIDATION_ERROR_MESSAGE);
  }

  const showModalError = (message) => {
    setErrorMessage(message);
    stateErrorFormCallback(true);
  };

  const stateErrorFormCallback = (state) => setIsErrorForm(state);

  return (
    <>
      <ServiceProviderProfile
        callbackSelectImage={callbackSelectImage}
        callbackSelectImageError={callbackSelectImageError}
        thumbnail={thumbnail}
        name={name !== "" ? name : "Usuario"}
        score={score}
        userType={
          name !== ""
            ? `${convertTypeRegisterToNames(user_type)} - ${label}`
            : ""
        }
        appbarButtonsContent={appbarButtonsContent}
        menuButtonsContent={menuButtonsContent}
      />

      {showSelectedHelpers ? (
        <StateButtonModalHook
          component={PsAssistantsListController}
          hook={[showSelectedHelpers, setShowSelectedHelpers]}
          object={{
            id: null,
          }}
        />
      ) : null}

      {/* error */}
      {isErrorForm ? (
        <StateButtonModalHook
          component={ErrorRegister}
          hook={[isErrorForm, setIsErrorForm]}
          object={{
            message: errorMessage,
            callback: stateErrorFormCallback,
          }}
        />
      ) : null}
      <LoadingPopup state={name === ''} />
    </>
  );
};

export default withRouter(ServiceProviderProfileController);
