import CAR_ICON_SVG from '../assets/icons/svg/car.svg'
import BIKE_ICON_SVG from '../assets/icons/svg/bike.svg'
import MOTORBIKE_ICON_SVG from '../assets/icons/svg/motorbike.svg'

const TRANSPORT = [
    {
        icon: CAR_ICON_SVG,
        id: 'car',
        text: 'Carro'
    },
    {
        icon: MOTORBIKE_ICON_SVG,
        id: 'blikle',
        text: 'Moto'
    },
    {
        icon: BIKE_ICON_SVG,
        id: 'bike',
        text: 'Bicicleta'
    }
]

export {
    TRANSPORT
}

