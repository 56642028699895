import React from 'react'
import LoadingScreen from '../loading/LoadingScreen.jsx'

const AppViewController = () => {

    return <LoadingScreen />

}

export default AppViewController
