import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import ICON_CHECK from "../../../assets/icons/svg/check.svg";
import EYE_CLOSE from "../../../assets/icons/svg/eye-off-outline.svg";
import EYE_SVG from "../../../assets/icons/svg/eye-outline.svg";
import SEARCH_ICON_SVG from "../../../assets/icons/svg/search.svg";
import "./input.css";
const BaseInput = ({
  style,
  callback,
  placeholder,
  type = "text",
  defaultValue = "",
  disable,
}) => {
  return (
    <input
      disabled={disable}
      placeholder={placeholder}
      defaultValue={defaultValue}
      className={`baseInput ${style}`}
      type={type}
      onChange={callback}
    />
  );
};

const IconInput = ({
  placeholder,
  icon,
  callback,
  type = "text",
  defaultValue = "",
  colorStyle,
  validInput = "",
  disable,
  value,
}) => {
  const [inputType, setInputType] = useState(type);

  const toggleInputType = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <div
      className={`containerIconInput ${colorStyle} ${
        validInput
          ? "inputBorderGreen"
          : validInput === ""
          ? ""
          : "inputBorderRed"
      } `}
    >
      <div className="containerIcon">
        <img src={icon} className="icon" alt={placeholder} />
      </div>
      <BaseInput
        disable={disable}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className="iconInput"
        type={inputType}
        callback={callback}
        value={value}
      />
      {type !== "text" && type !== "number" && (
        <div className="containerIcon" onClick={toggleInputType}>
          <img
            src={inputType === "password" ? EYE_CLOSE : EYE_SVG}
            alt=""
            className="icon"
          />
        </div>
      )}
    </div>
  );
};

export default IconInput;

const SearchIconInput = ({
  placeholder,
  icon,
  callback,
  type = "text",
  searchCallback,
  defaultValue,
}) => {
  return (
    <div className="containerIconInput">
      <div className="containerIcon">
        <img src={icon} className="icon" alt={placeholder} />
      </div>
      <BaseInput
        placeholder={placeholder}
        className="iconInput"
        type={type}
        callback={callback}
        defaultValue={defaultValue}
      />
      <div className="search-icon-container" onClick={searchCallback}>
        <img
          alt=""
          src={SEARCH_ICON_SVG}
          className="search-icon"
          onClick={searchCallback}
        />
      </div>
    </div>
  );
};

const CheckboxInput = ({
  index,
  callbackSelectTask,
  title,
  isSelected,
  isAvailable,
  shouldReset,
  itemSelect,
  canBeEdit,
}) => {
  useEffect(() => {
    setSelected(itemSelect);
  }, [itemSelect]);

  const [selectedBox, setSelected] = useState(itemSelect);

  const selectItem = (index) => {
    if (isAvailable && canBeEdit) {
      setSelected(!selectedBox);
      callbackSelectTask(index, !itemSelect);
    }
  };

  useEffect(() => {
    if (shouldReset) {
      setSelected(false);
    }
  }, [shouldReset, itemSelect]);

  return (
    <div className={`checkbox-item  ${!isAvailable ? "disabled" : ""}`}>
      <label className="checkbox-label">
        <input
          className={`checkbox-box `}
          type="checkbox"
          id={index}
          onClick={() => {
            selectItem(index);
          }}
          disabled={!isAvailable}
        />
        <span
          className={`checkmark ${selectedBox ? "active-check" : ""}`}
        ></span>
        <div className={` ${selectedBox ? "select-input" : ""} `}>{title}</div>
      </label>
      {isSelected ? (
        <div className="check-icon-container">
          <img src={ICON_CHECK} alt="" />
        </div>
      ) : null}
    </div>
  );
};

const CurrencyFormatInput = ({
  callback,
  defaultValue,
  colorStyle,
  icon,
  disable,
  placeholder,
}) => {
  return (
    <div className={`containerIconInput currency-format-input ${colorStyle}`}>
      <div className="containerIcon">
        <img src={icon} className="icon" alt="" />
      </div>
      <CurrencyFormat
        value={defaultValue}
        thousandSeparator={true}
        onValueChange={(values) => {
          callback(values);
        }}
        isAllowed={(values) => !disable}
        hintText={placeholder}
      />
    </div>
  );
};

const BaseCurrencyFormatInput = ({
  colorStyle,
  defaultValue,
  callback,
  placeholder,
}) => {
  return (
    <div className={`base-currency-format-input ${colorStyle}`}>
      <CurrencyFormat
        value={defaultValue}
        thousandSeparator={true}
        onValueChange={(values) => {
          callback(values);
        }}
        hintText={placeholder}
      />
    </div>
  );
};

const CheckboxInputSubActivity = ({
  index,
  indexParent,
  callbackSelectTask,
  title,
  isSelected,
  isAvailable,
  shouldReset,
  isCanSelected,
  setShowAlertMessage,
  setMessageAlert,
  handleInputSpaceChange,
  valueComplete,
}) => {
  const [selectedBox, setSelected] = useState(valueComplete);
  const [animate, setAnimate] = useState(false);

  let indexActivity = index;

  const selectItem = (index) => {
    if (isAvailable && isCanSelected) {
      let valueComplete = !selectedBox;
      setSelected(valueComplete);
      handleInputSpaceChange(indexActivity, valueComplete);
      callbackSelectTask(indexParent, index);
    } else if (!isCanSelected || !isAvailable) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 1000);
      setMessageAlert(
        "No puedes seleccionar esta actividad porque aún hay una o más actividades por completar o no pertenece a la fase actual."
      );
      setShowAlertMessage(true);
    }
  };

  useEffect(() => {
    if (shouldReset) {
      setSelected(false);
    }
  }, [shouldReset]);

  return (
    <div className={`checkbox-item  ${!isAvailable ? "disabled" : ""}`}>
      <label className="checkbox-label">
        <input
          className={`checkbox-box `}
          type="checkbox"
          id={index}
          onClick={() => {
            selectItem(index);
          }}
          disabled={!isAvailable}
        />
        <span className={`checkmark ${selectedBox ? "active-check" : ""}`}>
          {isSelected && !selectedBox ? (
            <div className="check-icon-container">
              <img src={ICON_CHECK} alt="" />
            </div>
          ) : null}
        </span>
        <div
          className={` ${selectedBox ? "select-input" : ""} ${
            animate ? "animate" : ""
          }`}
        >
          {title}
        </div>
      </label>
    </div>
  );
};

const CheckboxInputActivity = ({
  index,
  callbackSelectTask,
  title,
  isOptional,
  isSelected,
  isAvailable,
  shouldReset,
  isCanSelected,
  setShowAlertMessage,
  setMessageAlert,
}) => {
  const [selectedBox, setSelected] = useState(false);
  const [animate, setAnimate] = useState(false);

  const selectItem = (index) => {
    if (isAvailable && isCanSelected) {
      setSelected(!selectedBox);
      callbackSelectTask(index);
    } else if (!isCanSelected || !isAvailable) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 1000);
      setMessageAlert(
        "No puedes seleccionar esta actividad porque aún hay una o más actividades por completar o no pertenece a la fase actual."
      );
      setShowAlertMessage(true);
    }
  };

  useEffect(() => {
    if (shouldReset) {
      setSelected(false);
    }
  }, [shouldReset]);

  return (
    <div className={`checkbox-item  ${!isAvailable ? "disabled" : ""}`}>
      <label className="checkbox-label">
        <input
          className={`checkbox-box `}
          type="checkbox"
          id={index}
          onClick={() => {
            selectItem(index);
          }}
          disabled={!isAvailable}
        />
        <span className={`checkmark ${selectedBox ? "active-check" : ""}`}>
          {isSelected && !selectedBox ? (
            <div className="check-icon-container">
              <img src={ICON_CHECK} alt="" />
            </div>
          ) : null}
        </span>
        <div
          className={`input-check ${selectedBox ? "select-input" : ""} ${
            animate ? "animate" : ""
          }`}
        >
          {title}
          {isOptional ? (
            <div className="optional-container">
              <span className="line-separator">-</span>
              <span className="optional">Opcional</span>
            </div>
          ) : null}
        </div>
      </label>
    </div>
  );
};

const CheckboxInputSpace = ({
  index,
  indexParent,
  indexSpace,
  callbackSelectTask,
  title,
  info,
  isSelected,
  isAvailable,
  shouldReset,
  isCanSelected,
  setShowAlertMessage,
  handleInputSpace,
  valueSpace,
  setMessageAlert,
}) => {
  const [selectedBox, setSelected] = useState(valueSpace);
  const [animate, setAnimate] = useState(false);

  const selectItem = (index) => {
    if (isAvailable && !isSelected) {
      let value = !selectedBox;
      setSelected(value);
      handleInputSpace(indexSpace, value);
      if (
        indexParent !== undefined &&
        indexSpace !== undefined &&
        index !== undefined
      ) {
        callbackSelectTask(indexParent, index, indexSpace);
      } else if (!isCanSelected || !isAvailable) {
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
        }, 1000);
        setMessageAlert(
          "No Puedes Seleccionar Esta Actividad Porque No Esta Disponible"
        );
        setShowAlertMessage(true);
      }
    }
  };

  useEffect(() => {
    if (shouldReset) {
      setSelected(false);
    }
  }, [shouldReset]);

  return (
    <div className={`checkbox-item  ${!isAvailable ? "disabled" : ""}`}>
      <label className="checkbox-label">
        <input
          className={`checkbox-box `}
          type="checkbox"
          id={index}
          onClick={() => {
            selectItem(index);
          }}
          disabled={!isAvailable}
        />
        <span className={`checkmark ${selectedBox ? "active-check" : ""}`}>
          {isSelected && !selectedBox && (
            <div className="check-icon-container">
              <img src={ICON_CHECK} alt="" />
            </div>
          )}
        </span>
        <div
          className={`input-space ${selectedBox ? "select-input" : ""} ${
            animate ? "animate" : ""
          }`}
        >
          <span>{title}</span>
          <span>{info}</span>
        </div>
      </label>
    </div>
  );
};

const CheckboxInputNumbers = ({
  title,
  maxValue,
  initialValue,
  activityGroupIndex,
  activityIndex,
  spaceIndex,
  handleInput,
  handleSpace,
}) => {
  const [isChecked, setChecked] = useState(false);
  const isAvailable = initialValue < maxValue;

  const handleCheckboxChange = () => {
    if (isAvailable) {
      setChecked(!isChecked);
      if (!isChecked) {
        handleInput(
          activityGroupIndex,
          activityIndex,
          spaceIndex,
          maxValue - initialValue
        );
        handleSpace(spaceIndex, maxValue);
      } else {
        handleInput(activityGroupIndex, activityIndex, spaceIndex, 0);
        handleSpace(spaceIndex, initialValue);
      }
    }
  };

  useEffect(() => {}, [isChecked]);

  return (
    <div className={`checkbox-item ${!isAvailable ? "disabled" : ""}`}>
      <label className="checkbox-label">
        <input
          className="checkbox-box"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          disabled={!isAvailable}
        />
        <span className={`checkmark ${isChecked ? "active-check" : ""}`}>
          {isChecked && (
            <div className="check-icon-container">
              <img src={ICON_CHECK} alt="" />
            </div>
          )}
        </span>
        <div className={`input-space ${isChecked ? "select-input" : ""}`}>
          <span>{title}</span>
          <span>{`Disponible: ${maxValue - initialValue}`}</span>
        </div>
      </label>
    </div>
  );
};
export {
  BaseCurrencyFormatInput,
  BaseInput,
  CheckboxInput,
  CheckboxInputActivity,
  CheckboxInputNumbers,
  CheckboxInputSpace,
  CheckboxInputSubActivity,
  CurrencyFormatInput,
  IconInput,
  SearchIconInput,
};
