import useReduxState, {
  useDispatcherFunction,
} from "custom-hooks/useReduxState";
import useResourceUploader from "custom-hooks/useResourceUploader";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  chatRecordingActions,
  updateTextMessage,
  updateResources,
  closeEmojiPicker,
} from "reducers/chat.controller.reducer";
import { newDateWithTime } from "res/utils";
import { createNewMessage } from "services-controller/http.cleinte.controller";
import { uploadAudioResource } from "services-controller/storage";

const SendMessageButton = () => {
  const { idEntry, idService } = useParams();

  const updateMessageText = useDispatcherFunction(updateTextMessage);
  const clearResources = useDispatcherFunction(updateResources);
  const closeEmoji = useDispatcherFunction(closeEmojiPicker);

  const [, recordingActions] = useReduxState(
    chatRecordingActions,
    "chatController"
  );

  const { cleanRecordedBlob } = recordingActions;

  const state = useSelector((state) => {
    return {
      auth: state.auth,
      state_entry: state.state_entry.state,
      chatController: state.chatController,
    };
  });

  const chatControlState = state?.chatController;
  const { resources, messageText, isRecording, recordedBlob } =
    chatControlState;

  const user = state.auth.user;
  const userType = state.auth.userType;
  const object = state?.state_entry;
  const [, processResources] = useResourceUploader();

  const sendMessage = async () => {
    const messageInput = document.getElementById("messageInput");
    messageInput.style.height = "auto";

    let messageType = "";
    let audioResourceUploaded = null;
    let resourcesUploaded = [];

    if (
      !messageText &&
      (!recordedBlob || !recordedBlob?.blob) &&
      resources.length === 0
    ) {
      return;
    } else if (resources.length > 0) {
      resourcesUploaded = await processResources(resources, idService);
      messageType = "imageFiles";
    } else if (recordedBlob?.blob) {
      audioResourceUploaded = await uploadAudioResource(recordedBlob?.blob);
      messageType = "audio";
    } else if (messageText !== "") {
      messageType = "text";
    }

    let obj = {
      date: newDateWithTime(),
      is_valid: false,
      state: 1,
      emisor: user,
      name: user.name,
      text: messageText,
      thubmnail: user.register_data?.thumbnail,
      admin_read: false,
      admin_read_date: "",
      client_read: userType === "client" ? true : false,
      client_read_date: userType ? newDateWithTime() : "",
      type: messageType,
      audioResource: audioResourceUploaded,
      resources: resourcesUploaded,
      sp_read: userType === "sp" ? true : false,
      sp_read_date: userType ? newDateWithTime() : "",
      phase: {
        name: object.phase.name,
        code: object.phase.code,
      },
    };

    updateMessageText("");
    cleanRecordedBlob();
    clearResources([]);
    closeEmoji();

    const user_type = userType;
    const userInfo = {
      name: user.register_data.name,
      id: user.id,
    };

    await createNewMessage(idService, idEntry, user_type, obj, userInfo);
  };

  return (
    !isRecording && (
      <button
        className="send-message-button"
        onClick={() => {
          sendMessage();
        }}
      >
        Enviar Mensaje
      </button>
    )
  );
};

export default SendMessageButton;
