import React from "react";
import ResourcesAdd from "./ResourcesAdd";
import RecordingButtons from "./RecordingButtons";
import EmojiPicker from "./EmojiPicker";

const MultimediaActions = () => {
  return (
    <div className="multimedia-buttons">
      <ResourcesAdd />
      <RecordingButtons />
      <EmojiPicker />
    </div>
  );
};

export default MultimediaActions;
