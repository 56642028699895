import { produce } from "immer";
import { forEach } from "lodash";
import { isBossOf } from "../../../res/roles";

const OPERATION_PHASE = "Operaciones";
const DESIGN_PHASE = "Diseño";
const PLANEATION_PHASE = "Planeación";

function addBusinessDays(startDate, daysToAdd) {
  let businessDays = 0;
  let tempDate = new Date(startDate.getTime());

  while (businessDays < daysToAdd) {
    tempDate.setDate(tempDate.getDate() + 1);

    if (tempDate.getDay() !== 0 && tempDate.getDay() !== 6) {
      businessDays++;
    }
  }
  return tempDate;
}

const calculateHabilDaysForActivitys = (activitys) => {
  let days = 0;
  activitys.forEach((activity) => {
    days += activity.progress.days;
  });
  return days;
};

const setupLimitsForActivitys = (activitys) => {
  let Days = 0;
  forEach(activitys, (activity) => {
    if (activity.progress.days > 0) {
      Days += activity.progress.days;
      activity.progress.start_date = addBusinessDays(new Date(), Days);
    }
  });
  return activitys;
};

const servicePhases = {
  phases: [
    {
      title: "Design",
      id: 0,
      position: 0,
      order: true,
      activities: [
        {
          position: 0,
          title: "Agendamiento primera cita",
          isRequired: true,
          isVisible: true,
          roles: [260, 261, 250],
          id: "DE-0",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 2,
          },
          isCompleted: false,
          type: "activity",
          description_type: "date",
          attach_type: null,
        },
        {
          position: 1,
          title: "Primer reuni\u00f3n",
          isRequired: true,
          isVisible: true,
          roles: [260, 261, 250],
          id: "DE-1",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 5,
          },
          type: "activity",
          isCompleted: false,
          description_type: "text",
          attach_type: "pdf",
        },
        {
          position: 2,
          title: "Fecha entrega de inmueble",
          isRequired: true,
          isVisible: true,
          roles: [260, 261, 250],
          id: "DE-2",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          isCompleted: false,
          type: "activity",
          description_type: "boolean, date",
          attach_type: null,
        },
        {
          position: 3,
          title: "Segunda reuni\u00f3n",
          isRequired: true,
          isVisible: true,
          roles: [250, 260, 261],
          id: "DE-3",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 7,
          },
          isCompleted: false,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
        },
        {
          position: 6,
          title: "Aprobaciones adicionales",
          isRequired: false,
          isVisible: true,
          roles: [250, 260, 261],
          id: "DE-6",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          isCompleted: false,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
        },
        {
          position: 7,
          title: "Envio de documentos",
          isRequired: true,
          isVisible: true,
          roles: [250, 260, 261],
          id: "DE-4",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 2,
          },
          type: "activity",
          isCompleted: false,
          description_type: "text",
          attach_type: "pdf",
        },
        {
          position: 8,
          title: "Cobro 35%",
          isRequired: true,
          isVisible: true,
          roles: [240, 241, 242],
          id: "DE-5",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          isCompleted: false,
          type: "payment",
          description_type: "text",
          attach_type: "pdf",
        },
        {
          position: 9,
          title: "Otro si contrato",
          isRequired: false,
          isVisible: true,
          roles: [250, 260, 261],
          id: "DE-7",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 1,
          },
          isCompleted: false,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
        },
      ],
      progress_status: {
        start_date: null,
        end_date: null,
        progress: 0,
      },
      completation_status: {
        completation_date: null,
        "completation:_time": null,
        completation_status: false,
        completation_notes: null,
      },
    },
    {
      title: "Planeation",
      id: 1,
      position: 1,
      order: true,
      activities: [
        {
          position: 0,
          title: "Entrega de llaves",
          isVisible: true,
          roles: [250, 251, 252],
          id: "PL-0",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 2,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "date, boolean",
          attach_type: null,
          isCompleted: false,
        },
        {
          position: 1,
          title: "Levantamiento",
          isVisible: true,
          roles: [250, 251, 252],
          id: "PL-1",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 3,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          position: 2,
          title: "Planimetra",
          isVisible: true,
          roles: [250, 251, 252],
          id: "PL-2",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          position: 3,
          title: "Compra Enchape",
          isVisible: true,
          roles: [250, 251, 252],
          id: "PL-3",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 1,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "photo, pdf",
          isCompleted: false,
        },
        {
          position: 4,
          title: "Acta de Enchapes Cliente",
          isVisible: true,
          roles: [250, 251, 252],
          id: "PL-4",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          position: 5,
          title: "Reuni\u00f3n auditoria",
          isVisible: true,
          roles: [250, 251, 252],
          id: "PL-5",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 1,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          position: 6,
          title: "Selecci\u00f3n contratista",
          dontOrder: true,
          isVisible: true,
          roles: [250, 251, 252],
          id: "PL-6",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 2,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          position: 7,
          title: "Env\u00edo cronograma cliente",
          isVisible: true,
          roles: [250, 251, 252, 253],
          id: "PL-7",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 3,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          title: "Documentos contratista",
          isVisible: true,
          dontOrder: true,
          roles: [250, 251, 252, 253],
          id: "PL-8",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          title: "Contrato contratista",
          isVisible: true,
          dontOrder: true,
          roles: [250, 251, 252, 253],
          id: "PL-9",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          position: 10,
          title: "Realizacion del pedido inicial",
          isVisible: false,
          roles: [250, 251, 252],
          id: "PL-10",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 2,
          },
          is_completed: false,
          isRequired: true,
          type: "activity",
          description_type: "text",
          attach_type: "pdf",
          isCompleted: false,
        },
        {
          position: 11,
          title: "Otros",
          isVisible: true,
          roles: [250, 251, 252, 253],
          id: "PL-11",
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 0,
          },
          is_completed: false,
          isRequired: false,
          type: "activity",
          description_type: "text",
          attach_type: "pdf, photo, video",
          isCompleted: false,
        },
      ],
      progress_status: {
        start_date: null,
        end_date: null,
        progress: 0,
      },
      completation_status: {
        completation_date: null,
        "completation:_time": null,
        completation_status: false,
        completation_notes: null,
      },
    },
    {
      title: "Operation",
      id: 2,
      position: 2,
      activities: [
        {
          group_title: "Obra Blanca",
          group_id: "OB",
          order: false,
          group_position: 0,
          isRequired: true,
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 21,
          },
          activities: [
            {
              title: "LLegada de primer pedido material",
              group: "preeliminares",
              isVisible: false,
              roles: [250, 251, 252],
              id: "OP-0",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "planeation_activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Subida material al inmueble",
              group: "preeliminares",
              isVisible: false,
              roles: [250, 251, 252],
              id: "OP-1",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Entrega materiales a maestro",
              group: "preeliminares",
              isVisible: false,
              roles: [250, 251, 252],
              id: "OP-2",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Demolicion de elementos",
              group: "demoliciones",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-3",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Ranuras",
              isVisible: true,
              group: "varios",
              roles: [220, 221, 222, 210],
              id: "OP-5",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Rellenos",
              group: "rellenos",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-6",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Estucos",
              group: "estucos",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-7",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Enchape paredes",
              isVisible: true,
              group: "enchapes_paredes",
              roles: [220, 221, 222, 210],
              id: "OP-8",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Primer interventoria",
              group: "varios",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-9",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Toma medidas divisiones",
              group: "divisiones",
              isVisible: false,
              roles: [220, 221, 222, 210],
              id: "OP-10",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Sistema liviano",
              group: "sistema_liviano",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-11",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "1er Capa de Pintura",
              group: "pinturas",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-12",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Mortero nivelacion",
              group: "morteros",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-13",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Llegada Segundo pedido de obra",
              group: "preeliminares",
              isVisible: false,
              roles: [250, 251, 252],
              id: "OP-14",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "planeation_activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Enchape Piso",
              group: "enchapes_pisos",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-15",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Guardaescobas",
              group: "enchapes_guardaescobas",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-16",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Fragua",
              group: "morteros",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-17",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Enchape Oficios",
              group: "enchapes_oficios",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-18",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Enchape Salpicadero",
              group: "enchapes_salpicadero",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-19",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Iluminacion",
              group: "iluminacion",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-20",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Cobro 35%",
              group: "varios",
              isVisible: true,
              roles: [240, 241, 242],
              id: "OP-21",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "payment",
              description_type: "text",
              attach_type: "pdf",
              isCompleted: false,
            },
            {
              title: "Escombros",
              group: "limpieza",
              isVisible: true,
              roles: [250, 251, 252],
              id: "OP-22",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Materiales Sobrantes",
              group: "varios",
              isVisible: true,
              roles: [250, 251, 252],
              id: "OP-23",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "2da capa de pintura",
              group: "pinturas",
              isVisible: true,
              roles: [250, 251, 252],
              id: "OP-54",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
          ],
        },
        {
          group_title: "Carpinteria",
          order: true,
          group_id: "CAR",
          group_position: 1,
          isRequired: true,
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 14,
          },
          activities: [
            {
              title: "Segunda interventoria",
              group: "varios",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-25",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Toma Medidas Carpinteria",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-26",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "pdf",
              isCompleted: false,
            },
            {
              title: "Modelo Carpinteria",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-27",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "pdf",
              isCompleted: false,
            },
            {
              title: "Presentacion Carpinteria a Cliente",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-28",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "pdf",
              isCompleted: false,
            },
            {
              title: "Documentos Carpinteria",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-29",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "pdf",
              isCompleted: false,
            },
            {
              title: "Asignaci\u00f3n Carpintero",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-30",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: null,
              isCompleted: false,
            },
            {
              title: "Despiece Carpinteria",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-31",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Compra de Materiales Carpinteria",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-32",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "pdf, photo",
              isCompleted: false,
            },
            {
              title: "Llegada de Materiales Carpinteria al Taller",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-33",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Cobro 15% al Cliente",
              group: "varios",
              isVisible: true,
              roles: [240, 241, 242],
              id: "OP-34",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "payment",
              description_type: "text",
              attach_type: "pdf",
              isCompleted: false,
            },
            {
              title: "Ensamble Madera",
              isVisible: true,
              group: "carpinteria",
              roles: [220, 221, 230, 231],
              id: "OP-35",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Llegada de Materiales Carpinteria a Obra",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-36",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Toma medidas Meson",
              group: "mesones",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-37",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion Carpinteria",
              group: "carpinteria",
              isVisible: true,
              roles: [220, 221, 230, 231],
              id: "OP-38",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
          ],
        },
        {
          group_title: "Detallado",
          group_id: "DET",
          order: false,
          group_position: 2,
          isRequired: true,
          completation_status: {
            completetion_date: null,
            completetion_time: null,
            completetion_status: false,
            completetion_notes: null,
          },
          progress: {
            start_date: null,
            end_date: null,
            progress: 0,
            progress_notes: [],
            days: 9,
          },
          activities: [
            {
              title: "3ra Capa de Pintura",
              group: "pinturas",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-24",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Tercera interventoria",
              group: "varios",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-39",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Detallado de Acabados",
              group: "detallado",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-40",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion de Mesones",
              group: "mesones",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-41",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion de Divisones",
              group: "divisiones",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-42",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion de Griferia",
              group: "griferia",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-43",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion de Sanitarios",
              group: "aparatos_sanitarios",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-44",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion de Accesorios",
              group: "detallado",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-45",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion de Espejos",
              group: "detallado",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-46",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Instalacion de Gas",
              group: "gas_domestico",
              isVisible: true,
              roles: [250, 251, 252],
              id: "OP-47",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Eliminaci\u00f3n de Escombros",
              group: "limpieza",
              isVisible: true,
              roles: [250, 251, 252],
              id: "OP-48",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Materiales Sobrantes",
              group: "varios",
              isVisible: true,
              roles: [250, 251, 252],
              id: "OP-49",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Cuarta interventoria",
              group: "varios",
              isVisible: true,
              roles: [220, 221, 222, 210],
              id: "OP-50",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Limpieza Final",
              group: "limpieza",
              isVisible: true,
              roles: [220, 221, 250, 251, 252, 222],
              id: "OP-51",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
            {
              title: "Videos y Fotos resultado final",
              group: "varios",
              isVisible: true,
              roles: [280],
              id: "OP-52",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "video, photo",
              isCompleted: false,
            },
            {
              title: "Entrega al Cliente",
              group: "varios",
              isVisible: true,
              roles: [220, 221, 250, 251],
              id: "OP-53",
              completation_status: {
                completetion_date: null,
                completetion_time: null,
                completetion_status: false,
                completetion_notes: null,
              },
              progress: {
                start_date: null,
                end_date: null,
                progress: 0,
                progress_notes: [],
              },
              isRequired: true,
              type: "activity",
              description_type: "text",
              attach_type: "photo",
              isCompleted: false,
            },
          ],
        },
      ],
      progress_status: {
        start_date: null,
        end_date: null,
        progress: 0,
      },
      completation_status: {
        completation_date: null,
        "completation:_time": null,
        completation_status: false,
        completation_notes: null,
      },
    },
  ],
  globalState: {
    progress: {
      start_date: null,
      end_date_expected: null,
      progress: 0,
      progress_notes: [],
    },
    state: {
      isNewPhase: false,
      currentPhase: null,
      currentPhaseIndex: null,
      lastActivity: null,
      lastActivityIndex: null,
    },
  },
  completetion_status: {
    completetion_date: null,
    completetion_time: null,
    completetion_status: false,
    completetion_notes: null,
  },
};

const initDesignePhase = (servicePhases) => {
  const initDesignPhase = produce(servicePhases, (draft) => {
    const habilDays = calculateHabilDaysForActivitys(
      draft.phases[0].activities
    );
    const startDate = addBusinessDays(new Date(), 1);

    draft.globalState.state.isNewPhase = true;
    draft.phases[0].progress_status.startDate = startDate;
    draft.phases[0].progress_status.endDate = addBusinessDays(
      startDate,
      habilDays
    );
    draft.globalState.state.currentPhase = draft.phases[0].name;
    draft.globalState.state.currentPhaseIndex = 0;
    const newActities = setupLimitsForActivitys(draft.phases[0].activities);
    draft.phases[0].activities = newActities;
  });
  return initDesignPhase;
};

const orderEntryForPhases = (entriesList) => {
  let objectsOrder = [
    { name: DESIGN_PHASE, entries: [] },
    { name: PLANEATION_PHASE, entries: [] },
    { name: OPERATION_PHASE, entries: [] },
  ];
  entriesList.forEach((entry) => {
    entry.entrys.forEach((entrie) => {
      let code = entrie?.phase?.code;
      // eslint-disable-next-line no-empty
      // if (code === -1 || code === undefined) {
      // } else {
      //   objectsOrder[code].entries.push({
      //     ...entrie,
      //     date: entry.date,
      //   });
      // }

      objectsOrder[code]?.entries.push({
        ...entrie,
        date: entry.date,
      });
    });
  });

  return objectsOrder;
};

const redefineEntriess = (entriesList) => {
  let objectsOrder = [];
  entriesList.forEach((entry) => {
    entry.entrys.forEach((entrie) => {
      const newEntry = { ...entrie, date: entry.date };
      if (newEntry.date !== undefined && newEntry.date !== null) {
        objectsOrder.push(newEntry);
      }
    });
  });
  return objectsOrder;
};

const redefineEntries = (entriesList) => {
  let objectsOrder = [];
  entriesList.forEach((entry) => {
    entry.entrys.forEach((entrie) => {
      const newEntry = { ...entrie, date: entry.date };
      if (newEntry.date !== undefined && newEntry.date !== null) {
        objectsOrder.push(newEntry);
      }
    });
  });
  return objectsOrder;
};

const getColorForPhase = (phaseName) => {
  const namePhases = [
    {
      name: DESIGN_PHASE,
      color: " #F8BBD0",
      textColor: "#0D47A1",
    },
    { name: PLANEATION_PHASE, color: "#D1C4E9 ", textColor: "#6B8E23" },
    { name: OPERATION_PHASE, color: "#CFD8DC", textColor: "#FFA000" },
  ];
  for (let i = 0; i < namePhases.length; i++) {
    if (namePhases[i].name === phaseName) {
      return [namePhases[i].color, namePhases[i].textColor];
    }
  }
};

const settingOrderCheck = (activitiesGroup, isCurrentPhase) => {
  let activitiesAvailable = [];

  for (let activity of activitiesGroup.activities) {
    if (activity.isAvailable) {
      activitiesAvailable.push(activity);
    }
  }

  let activities = activitiesAvailable;
  let incompleteActivity = false;
  for (let i = 0; i < activities.length; i++) {
    if (activitiesGroup.order) {
      if (!activities[i].isCompleted && !incompleteActivity && isCurrentPhase) {
        activities[i].isCanSelected = true;
        incompleteActivity = true;
      } else {
        activities[i].isCanSelected =
          (activities[i].isCompleted && isCurrentPhase) ||
          activities[i].dontOrder;
      }
    } else {
      activities[i].isCanSelected = isCurrentPhase;
    }

    let index = activitiesGroup.activities.indexOf(activities[i]);
    activitiesGroup.activities[index] = activities[i];
  }

  return activitiesGroup;
};

const settingActivitiesAvailable = (phases, currentPhase, role) => {
  if (role !== "admin") {
    for (let phase of phases) {
      let isCurrentPhase = currentPhase === phase.position;

      for (let activitiesGroup of phase.activities) {
        if (activitiesGroup.activities?.length > 0) {
          for (let activity of activitiesGroup.activities) {
            if (activity.spaces?.length > 0) {
              for (let space of activity.spaces) {
                if (space.roles.includes(role)) {
                  space.isAvailable = true;
                  space.isCanSelected = isCurrentPhase;
                }
              }
            } else if (activity.roles.includes(role)) {
              activity.isAvailable = true;
            }
          }

          activitiesGroup = settingOrderCheck(activitiesGroup, isCurrentPhase);
        } else {
          if (activitiesGroup.roles.includes(role)) {
            activitiesGroup.isAvailable = true;
          }
        }
      }
      phase = settingOrderCheck(phase, isCurrentPhase);
    }
  } else {
    for (let phase of phases) {
      let isCurrentPhase = currentPhase === phase.position;
      phase.isAvailable = true;
      for (let activitiesGroup of phase.activities) {
        if (activitiesGroup.activities?.length > 0) {
          for (let activity of activitiesGroup.activities) {
            if (activity.spaces?.length > 0) {
              for (let space of activity.spaces) {
                space.isAvailable = true;
                space.isCanSelected = isCurrentPhase;
              }
            } else {
              activity.isAvailable = true;
              activity.isCanSelected = isCurrentPhase;
            }
          }
        } else {
          activitiesGroup.isAvailable = true;
          activitiesGroup.isCanSelected = isCurrentPhase;
        }
      }
    }
  }
  return phases;
};

const processPhases = async (servicePhases, role) => {
  let phases = servicePhases.phases;
  let currentPhase = servicePhases.globalState.state.currentPhaseIndex;
  let availablePhases = settingActivitiesAvailable(phases, currentPhase, role);

  return availablePhases;
};

const getActivitiesForRole = (roleCode, phases) => {
  const activities = phases.activities;
  for (let j = 0; j < activities.length; j++) {
    const activity = activities[j];
    if (activity.roles.includes(roleCode)) {
      activity.isAvailable = true;
    } else if (roleCode === 0) {
      activity.isAvailable = true;
    } else {
      activity.isAvailable = false;
    }
  }
  return phases;
};

const phaseIsAvailableForFinish = (phase, role) => {
  if (role === "admin") {
    return true;
  } else {
    if (isBossOf(role, phase)) {
      return true;
    } else {
      return false;
    }
  }
};

const canBeSelectedFinishPhase = (phase, currentPhase) => {
  if (currentPhase === phase.position) {
    if (phase.position !== 2) {
      let incompleteActivities = phase.activities.filter(
        (activity) =>
          activity.isCompleted === false && activity.isRequired === true
      );
      if (incompleteActivities.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      let incompleteActivities = false;
      for (let activityGroup of phase.activities) {
        for (let activity of activityGroup.activities) {
          if (activity.isCompleted === false && activity.isRequired === true) {
            incompleteActivities = true;
            break;
          }
        }
      }

      return !incompleteActivities;
    }
  } else {
    return false;
  }
};

const hiddenEntry = (activities, dispatchinHiddenEntry) => {
  for (let i = 0; i < activities.length; i++) {
    const activity = activities[i];
    if (!activity.isVisible) {
      dispatchinHiddenEntry(false);
      return;
    } else {
      dispatchinHiddenEntry(true);
    }
  }
};

const getPercentPhases = (globalState) => {
  let desing = globalState?.Design;
  let desingPercent = (desing?.progress * 100) / desing?.percent;

  let planeation = globalState?.Planeation;
  let planeationPercent = (planeation?.progress * 100) / planeation?.percent;

  let operation = globalState?.Operation;
  let operationPercent = (operation?.progress * 100) / operation?.percent;

  if (desing === undefined) {
    desing = 0;
  }
  if (planeation === undefined) {
    planeation = 0;
  }
  if (operation === undefined) {
    operation = 0;
  }

  //greather than 99.9
  if (operationPercent >= 100) {
    operationPercent = 110;
  }

  let total = desingPercent + planeationPercent + operationPercent;

  return [
    [
      {
        name: "diseño",
        progress: desingPercent,
        color: "pink",
      },
      {
        name: "planeación",
        progress: planeationPercent,
        color: "#D1C4E9",
      },
      {
        name: "operación",
        progress: operationPercent,
        color: "#03C1E1",
      },
    ],

    total / 3,
  ];
};

export {
  canBeSelectedFinishPhase,
  getActivitiesForRole,
  getColorForPhase,
  getPercentPhases,
  hiddenEntry,
  initDesignePhase,
  orderEntryForPhases,
  phaseIsAvailableForFinish,
  processPhases,
  redefineEntries,
  redefineEntriess,
  servicePhases,
};
