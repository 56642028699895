import React from 'react'
import { SelectPropertyPopup } from '../components/atoms/popup/Popup'

const PopupSelectProperty = ({ object }) => {

    const {
        callbackSearch,
        query = '',
        propertyArr,
        callbackNewProperty,
        callbackPropertySelected
    } = object

    return (
        <SelectPropertyPopup
            callbackSearch={callbackSearch}
            query={query}
            arr={propertyArr}
            callbackNewProperty={callbackNewProperty}
            callbackPropertySelected={callbackPropertySelected} />
    )
}

export {
    PopupSelectProperty
}