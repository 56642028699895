import { useEffect } from "react";

export const PushNotification = ({
  title,
  options,
  requestPermission,
  setPermission,
}) => {
  useEffect(() => {
    if (typeof Notification === "undefined") {
      console.error(
        "Las notificaciones no son compatibles con este navegador."
      );
      return;
    }

    if (requestPermission && Notification.permission !== "granted") {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            setPermission(true);
            if (title !== null && options !== null) {
              new Notification(title, options);
            }
          } else {
            console.error("Permiso denegado.");
            setPermission(false);
          }
        })
        .catch((error) => {
          console.error("Error al solicitar permiso:", error);
        });
    } else if (Notification.permission === "granted") {
      setPermission(true);

      if (title !== null && options !== null) {
        new Notification(title, options);
      }
    }
  }, [requestPermission, title, options, setPermission]);

  return null;
};
