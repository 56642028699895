import React from 'react'
import { IconPopup } from '../../components/atoms/popup/Popup'
import CHECK_ICON_SVG from '../../assets/icons/svg/check-circle.svg'


const PopupSuccessEvent = ({ object }) => {
    const {
        message,
        btnText,
        callback,

    } = object
    return (
        <IconPopup
            icon={CHECK_ICON_SVG}
            text={message}
            btnColor='btnPurple'
            btnText={btnText}
            callback={callback} />
    )
}


export {
    PopupSuccessEvent
}