import React from 'react'

export default function CheckIcon({ width, height, color}) {
	return (

		<svg width={width} height={height} fill={color} version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
			<path d="m122.57 404.14c-20.477-20.457-20.477-53.777 0-74.234 9.9062-9.9219 23.102-15.383 37.117-15.383s27.211 5.4609 37.117 15.383l74.148 74.129 243.06-166.83c8.7148-6.0195 19.004-9.2227 29.68-9.2227 17.309 0 33.477 8.5039 43.297 22.785 7.9609 11.566 10.922 25.531 8.3633 39.324-2.5547 13.789-10.344 25.762-21.926 33.688l-279.02 191.5c-8.7656 6.0195-19.039 9.2227-29.699 9.2227-14.035 0-27.211-5.4609-37.137-15.383zm105-87.5c9.9062 9.9219 23.102 15.383 37.137 15.383 10.656 0 20.93-3.2031 29.699-9.2227l279.02-191.52c11.566-7.9453 19.355-19.898 21.926-33.688 2.5547-13.789-0.40234-27.754-8.3477-39.324-9.8008-14.262-25.988-22.785-43.312-22.785-10.656 0-20.965 3.2031-29.68 9.2227l-243.06 166.83-74.145-74.148c-9.9062-9.9219-23.102-15.379-37.117-15.379s-27.211 5.4609-37.117 15.383c-20.477 20.457-20.477 53.777 0 74.234z" />
		</svg>


	)
}

