//register types
const SERVICE_PROVIDER = "service-provider";
const CUSTOMER = "customer";
const CARPENTRY_LEADER = "carpentry-leader";
const CARPENTRY_ASSISTANT = "carpentry-assistant";
const CFO = "cfo";
const ADMIN_COORDINATOR = "admin-coordinator";
const ADMIN_ASISTANT = "admin-asistant";
const HUMAN_RESOURCES = "human-resources";
const CSO = "cso";
const PLANEATION_COORDINATOR = "planeation-coordinator";
const PLANEATION_ASISTANT = "planeation-asistant";
const DOCUMENTATION_ASISTANT = "documentation-asistant";
const PLANEATION_AUXILIARY = "planeation-auxiliary";
const POST_SOLD_AUXILIARY = "post-sold-auxiliary";
const DESIGN_COORDINATOR = "design-coordinator";
const ARCHITECT_DESIGN = "architect-design";
const DESIGN_AUXILIARY = "design-auxiliary";
const COMERCIAL_DIRECTOR = "comercial-director";
const COMERCIAL_COORDINATOR = "comercial-coordinator";
const COMERCIAL_ASISTANT = "comercial-asistant";
const COO = "coo";
const OPERATION_COORDINATOR = "operation-coordinator";
const CARPENTRY_COORDINATOR = "carpentry-coordinator";
const RESIDENT = "resident";

//customer types general
const COMPANY_TYPE = "Empresa";
const REAL_STATE_TYPE = "Inmobiliaria";
const INSURANCE_TYPE = "Aseguradora";

//customer type
const PARTICULAR_TYPE = "Particular";
const HORIZONTAL_HOUSE_TYPE = "Admon. Casa horizontal";
const HORIZONTAL_HOUSE_TYPE_SIMPLE = "Casa Horizontal";
const HORIZONTAL_PROPERTY_TYPE = "Admon. Propiedad horizontal";
const HORIZONTAL_PROPERTY_TYPE_SIMPLE = "Propiedad Horizontal";
const OTHER_USER_TYPE = "Nuevo Usuario";
const NO_LABEL = "Loading";

const SUPERVISOR_TYPE = "Supervisor";
const OFICIAL_TYPE = "Oficial";
const HELPER_TYPE = "Ayudante";
const CARPENTRY_COORDINATOR_TYPE = "Coordinadora de Carpinteria";
export {
  ADMIN_ASISTANT,
  ADMIN_COORDINATOR,
  ARCHITECT_DESIGN,
  CARPENTRY_ASSISTANT,
  CARPENTRY_COORDINATOR,
  CARPENTRY_COORDINATOR_TYPE,
  CARPENTRY_LEADER,
  CFO,
  COMERCIAL_ASISTANT,
  COMERCIAL_COORDINATOR,
  COMERCIAL_DIRECTOR,
  COMPANY_TYPE,
  COO,
  CSO,
  CUSTOMER,
  DESIGN_AUXILIARY,
  DESIGN_COORDINATOR,
  DOCUMENTATION_ASISTANT,
  HELPER_TYPE,
  HORIZONTAL_HOUSE_TYPE,
  HORIZONTAL_HOUSE_TYPE_SIMPLE,
  HORIZONTAL_PROPERTY_TYPE,
  HORIZONTAL_PROPERTY_TYPE_SIMPLE,
  HUMAN_RESOURCES,
  INSURANCE_TYPE,
  NO_LABEL,
  OFICIAL_TYPE,
  OPERATION_COORDINATOR,
  OTHER_USER_TYPE,
  PARTICULAR_TYPE,
  PLANEATION_ASISTANT,
  PLANEATION_AUXILIARY,
  PLANEATION_COORDINATOR,
  POST_SOLD_AUXILIARY,
  REAL_STATE_TYPE,
  RESIDENT,
  SERVICE_PROVIDER,
  SUPERVISOR_TYPE,
};
