import { getObjectByKey } from "../persistent-managment/managment.persistent";

//reducer for save auth data
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";

const user = getObjectByKey("user");

const initialState = {
  user: user ? user : null,
  userType: null,
};
const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload,
        userType: payload?.register_type === 200 ? 'sp' : 'client'
      };

    case LOGOUT:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};

const login = (user) => {
  return { type: LOGIN, payload: user };
};

const logout = () => {
  return { type: LOGOUT };
};

export { authReducer, initialState, login, logout };
